//-------------------------------------------------------------------> Import From React
import React, { useEffect } from "react";

//--------------------------------------------------------------------> MUI Imports
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

import { CssBaseline } from "@mui/material";

// *import components
import Header from "./Header.js";
import Sidebar from "./SideBar.js";

// -->Other Imports
import colors from "app/style/Colors";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

export default function Layout({ children }) {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      {/* Header---> */}

      <Header />

      {/* Side Bar */}

      <Sidebar />

      {/* main Component--> */}
      <Box
        component="main"
        sx={{ minHeight: "1000px", flexGrow: 1, bgcolor: colors.lightGray }}
      >
        <DrawerHeader />

        {/* Children will render here--> */}
        {children}
      </Box>
    </Box>
  );
}
