import { useState } from "react";

function useProvideAuth() {
  const [user, setUser] = useState(null);
  const [InfoAdmin, setInfoAdmin] = useState(null);

  const signIn = (data) => {
    localStorage.setItem("jwt", data.token);
    setInfoAdmin(data);
    setUser(data.token);
  };

  const signOut = () => {
    localStorage.clear();
    setUser(null);
  };

  const verifyToken = () => {
    const token = localStorage.getItem("jwt");
    setUser(token);
  };

  return {
    user,
    signIn,
    signOut,
    verifyToken,
    InfoAdmin,
  };
}

export default useProvideAuth;
