import React from 'react'
import { Box, Typography } from '@mui/material'
import { HomeTwoTone } from '@mui/icons-material'

import colors from 'app/style/Colors'

function HeaderAppBar({ children, subChild }) {
  return (
    <Box sx={{ boxShadow: 1, color: "white", bgcolor: colors.primary, mb: 2, py: 5, px: 2 }}>
      <Box sx={{ mb: 2, display: "flex", alignItems: "center", fontSize: "14px", opacity: 0.5 }}>
        <HomeTwoTone sx={{ mr: 1 }} /> &gt; {subChild}
      </Box>
      <Typography variant='h5' component="div" fontWeight="bold">
        {children}
      </Typography>
    </Box>
  )
}

export default HeaderAppBar