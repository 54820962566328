import ColorsServices from "app/apis/Colors/ColorsServices";
import SizesServices from "app/apis/Sizes/SizesServices";
import ProductCategoryServices from "app/apis/ProductCategories/ProductCategoryServices";

export const getColors = async () => {
  try {
    let result = await ColorsServices.getAllColors();
    if (result.responseCode === 200) {
      return result.data;
    } else {
      // console.log("error occur", result);
    }
  } catch (e) {
    console.log("error", e);
  } finally {
  }
};

export const getSizes = async () => {
  try {
    let result = await SizesServices.getAllSizes();
    if (result.responseCode === 200) {
      return result.data;
    } else {
      // console.log("error occur", result);
    }
  } catch (e) {
    console.log("error", e);
  } finally {
  }
};

export const getMainCategory = async () => {
  try {
    let result = await ProductCategoryServices.getAllCategory();
    if (result.responseCode === 200) {
      return result.data;
    } else {
      // console.log("error occur", result);
    }
  } catch (e) {
    console.log("error", e);
  } finally {
  }
};
