import { get, post, deleted } from "../index.js";
import { UserRoutes } from "./User.Routes.js";

export const UserServices = {
  createUser: async (data) => {
    let result = await post(UserRoutes.createUser, data);
    if (result.status === 200) return result.data;
    else throw result;
  },
  getAllUser: async () => {
    let result = await get(UserRoutes.viewAllUser);
    if (result.status === 200) return result.data;
    else return result;
  },
  updateUser: async (id, data) => {
    const result = await post(UserRoutes.updateUser + id, data);
    if (result.status === 200) return result.data;
    else throw result;
  },
  deleteUser: async (id) => {
    const result = await deleted(UserRoutes.deletUser + id);
    if (result.status === 200) return result.data;
    else throw result;
  },
};
