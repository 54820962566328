import {
  Box,
  Button,
  CardMedia,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import colors from "app/style/Colors";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { CreateProductServices } from "app/apis/CreateProduct/CreateProductServices";
import { ErrorToaster, SuccessToaster } from "app/components/UI/Toaster";
import { useNavigate, useParams } from "react-router-dom";
import { baseUrl } from "../../../../../axios";

function EditStep3(props) {
  const [filterDataColorAndSize, setfilterDataColorAndSize] = useState([]);
  const [SelectColor, setSelectColor] = useState();
  const [FilterSize, setFilterSize] = useState([]);
  const [allSizes, setAllSizes] = useState([]);
  const [Loading, setLoading] = useState(false);
  // props data ------->
  const {
    data,
    handleStep,
    allSize,
    allColor,
    customisePictureData,
    MainCategory,
  } = props;
  console.log("🚀 data,:", data);

  const { id } = useParams();
  const navigate = useNavigate();
  const baseURL = baseUrl;

  // Data to be filter for Display
  const filterData = () => {
    let variations = [];
    data.productdetails.forEach((element) => {
      const index = variations.findIndex(
        (e) => e.color_id === element.color.id
      );
      if (index === -1) {
        let fit = element.size.map((item) => ({
          fittype_id: item.id,
          fittype_name: item.name,
        }));
        // [
        //   {
        //     fittype_id: element.size.id,
        //     fittype_name: element.size.name,
        //   },
        // ];
        let obj = {
          fittype: fit,
          color_id: element.color.id,
          color_name: element.color.name,
          color_code: element.color.code,
        };
        variations.push(obj);
      } else {
        const updateFit = [...variations[index].fittype];
        const fitTypeIndex = updateFit.findIndex(
          (e) => e.fittype_id === element.size.id
        );
        if (fitTypeIndex === -1) {
          let obj = {
            fittype_id: element.size.id,
            fittype_name: element.size.name,
          };
          updateFit.push(obj);
        }
        variations[index].fittype = updateFit;
      }
      setfilterDataColorAndSize(variations);
    });
  };

  //  Update Submit Data
  const SubmitData = async () => {
    let FinalData;
    const filterProductDetails = setLoading(true);
    try {
      // Set ProductDetails Data  ================>
      const productdetails = data?.productdetails.map((data) => {
        // Filtering Image Data
        const ImageFilterData = data.image.map((e) => {
          const FilterImage = e.image.split(
            "http://api-designtool.baitalkhairkitchen.com"
          );
          console.log("FilterImage=>", FilterImage);
          return {
            image: FilterImage[1],
          };
        });

        return {
          productdetailsfittypes: data.size.map((item) => ({
            fittype_id: item.id,
            fittype_name: item.name,
          })),
          color_id: data.color.id,
          qty: data.qty,
          productdetailsimages: ImageFilterData,
        };
      });

      // Final Data =============>
      if (data.type === "NotCustomizable") {
        FinalData = {
          bannerimage: data.bannerimage,
          name: data.name,
          category_id: data.category.id,
          sku: data.sku,
          description: data.description,
          type: data.type,
          price: data.price,
          productdetails: productdetails,
        };
      } else {
        // For CustomiseImages=============>
        const filterCustomiseImageData = customisePictureData?.map((e) => {
          let imageSplit = e.image.split(
            "http://api-designtool.baitalkhairkitchen.com"
          );

          if (imageSplit[1]) {
            return {
              height: e.height,
              image: imageSplit[1],
              left: e.left,
              name: e.name,
              top: e.top,
              width: e.width,
            };
          } else {
            return {
              height: e.height,
              image: imageSplit[0],
              left: e.left,
              name: e.name,
              top: e.top,
              width: e.width,
            };
          }
        });

        FinalData = {
          bannerimage: data.bannerimage,
          name: data.name,
          category_id: data.category.id,
          sku: data.sku,
          description: data.description,
          type: data.type,
          price: data.price,
          productdetails: productdetails,
          productcustomiseimages: filterCustomiseImageData,
        };
      }

      const result = await CreateProductServices.updateProductById(
        id,
        FinalData
      );
      if (result.responseCode === 200) {
        SuccessToaster("product Updated Successfully");
      } else {
        ErrorToaster("Oops Error Occur");
      }
    } catch (e) {
      ErrorToaster(e);
    } finally {
      navigate("/Productmanagement/viewallproduct");
      setLoading(false);
    }
  };

  // Handle Steps for Previous Button
  const handlePreviousButton = () => {
    if (data.type === "Customizable") {
      handleStep("prev", 1);
    } else {
      handleStep("prev", 2);
    }
  };

  // SetColor And Show Sizes
  const setColor = (Colors) => {
    setSelectColor(Colors.color_name);
    setFilterSize(Colors.fittype);
  };

  useEffect(() => {
    filterData();
  }, data);
  return (
    <>
      {/* Product Info============================> */}

      <Typography variant="h4" sx={{ fontWeight: "900" }}>
        Product Info
      </Typography>
      <Grid container display="flex" gap="20px">
        {/* Render Images ===================> */}
        <Grid item md="6">
          <Grid
            container
            gap="20px"
            display="flex"
            justifyContent={"center"}
            sx={{
              boxShadow:
                "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;",
              padding: "20px",
            }}
          >
            {data.productdetails.map((imageFile) => {
              return (
                <Grid item sm="3" xs="5" sx={{ cursor: "pointer" }}>
                  <CardMedia
                    component="img"
                    height="100%"
                    image={imageFile?.image[0]?.image}
                    width="100%"
                    sx={{
                      objectFit: "cover",
                      alignSelf: "center",
                      justifySelf: "center",
                      boxShadow:
                        "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;",
                    }}
                    alt="green iguana"
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        {/* Product details=====================> */}
        <Grid item md="5" sm="12">
          <Grid
            container
            // sm="12"
            gap="10px"
            flexDirection={"column"}
            sx={{
              boxShadow:
                "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;",
              padding: "20px",
              //   height: "fit-content ",
              fontWeight: "lighter",
            }}
          >
            <Typography variant="p">SKU:{data.sku}</Typography>
            <Typography variant="h6">
              <b>{data.name}</b>
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }} gap="20px">
              <Typography variant="p">
                <b>{data.price}$</b>
              </Typography>
            </Box>

            <Typography variant="p" sx={{ fontWeight: "100" }}>
              {data.description}
            </Typography>
            <Box>
              <Box display="flex">
                <Typography sx={{ fontWeight: "900", mb: "10px" }}>
                  Avaialable Colors
                </Typography>
              </Box>
              {/* =========================Color Data =================================> */}
              {SelectColor && (
                <Box display="flex" sx={{ marginBottom: "10px" }}>
                  <Typography>
                    <span style={{ fontWeight: "800" }}>Color</span> :{" "}
                    {SelectColor}
                  </Typography>
                </Box>
              )}
              <Box display="flex">
                {filterDataColorAndSize.length !== 0 &&
                  filterDataColorAndSize?.map((color) => (
                    <Box
                      sx={{
                        border: "1px solid Gray",
                        width: "content-fit",
                        padding: "10px",
                      }}
                    >
                      <Box
                        backgroundColor={color.color_code}
                        style={{
                          height: "20px",
                          width: "20px",
                          borderRadius: "50%",
                          border: "1px solid black",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setColor(color);
                        }}
                      ></Box>
                    </Box>
                  ))}
              </Box>
            </Box>
            <Box>
              <Box display="flex">
                <Typography sx={{ fontWeight: "900", mb: "10px" }}>
                  Available Sizes
                </Typography>
              </Box>
              {/* Sizes==============> */}
              {FilterSize.length !== 0 && (
                <Box display="flex">
                  {FilterSize.map((size) => (
                    <Box
                      sx={{
                        border: "1px solid Gray",
                        width: "content-fit",
                        padding: "10px",
                      }}
                    >
                      <Box
                        style={{
                          height: "20px",
                          width: "20px",
                          borderRadius: "50%",
                          textAlign: "center",
                        }}
                      >
                        {size.fittype_name}
                      </Box>
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
            <Box>
              <Typography
                variant="p"
                display={"flex"}
                alignItems="center"
                gap="10px"
              >
                <LocalShippingIcon /> Shipping will be calculated at the end{" "}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        {/* Buttons */}
        <Grid item xs="12">
          <Box display="flex" gap="20px" justifyContent={"center"}>
            <Button
              // variant="outlined"
              sx={{
                background: colors.darkRed,
                color: "white",
                border: "none",
                ":hover": { color: "black" },
              }}
              onClick={() => {
                handlePreviousButton();
              }}
            >
              prev
            </Button>

            <LoadingButton
              loading={Loading}
              sx={{
                background: colors.darkRed,
                color: "white",
                border: "none",
                ":hover": { color: "black" },
              }}
              // variant="outlined"
              onClick={() => {
                SubmitData();
              }}
            >
              Update your data
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </>
    // <h1>sjdbj</h1>
  );
}

export default EditStep3;
