import {
  Box,
  Button,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import AppBar from "app/components/AppBar/AppBar";

import Layout from "app/layout/Layout";
import colors from "app/style/Colors";
import React, { useEffect, useState } from "react";
import EditStep1 from "./Steps/EditStep1";
import EditStep2 from "./Steps/EditStep2";
import EditStep3 from "./Steps/EditStep3";
import { ErrorToaster } from "app/components/UI/Toaster";
import {
  getColors,
  getMainCategory,
  getSizes,
} from "./Steps/CallingApiForItems";

function EditProduct() {
  let [activeStep, setActiveStep] = useState(0);
  const [data, setData] = useState();
  const [otherDta, setOtherDta] = useState();
  const [customisePicture, setCustomisePicture] = useState([]);

  // calling External Api's

  const [MainCategory, setMainCategory] = useState("");
  console.log("🚀 MainCategory:", MainCategory);
  const [SizeChange, setSizeChange] = useState("");
  const [ColorChange, setColorChange] = useState("");
  const [productData, setproductData] = useState([]);
  const [loading, setloading] = useState(false);

  const HandleLoading = () => {
    setloading(!loading);
  };

  let getcolordata = async () => {
    let colorData = await getColors();
    setColorChange(colorData);
  };
  // Get MainCategory Data
  let getMainCategoryData = async () => {
    let MainCategoryy = await getMainCategory();
    setMainCategory(MainCategoryy);
  };
  // Get Size Data
  let getSizeData = async () => {
    let SizeData = await getSizes();
    setSizeChange(SizeData);
  };

  // Handle CustomisePictures Data
  const setCustomisePicturesData = (data) => {
    try {
      const currentIndex = customisePicture.findIndex(
        (item) => item.name === data.name
      );

      const newCustomisePicture = [...customisePicture];
      if (currentIndex === -1) {
        newCustomisePicture.push(data);
      } else {
        newCustomisePicture.splice(currentIndex, 1);
        newCustomisePicture.splice(currentIndex, 0, data);
      }
      setCustomisePicture(newCustomisePicture);
    } catch (error) {
      ErrorToaster(error);
    }
  };

  const handleActiveStep = (action, value) => {
    if (action === "next") {
      if (activeStep < 2) {
        setActiveStep(activeStep + value);
      }
    }
    if (action === "prev") {
      if (activeStep > 0) {
        setActiveStep(activeStep - value);
      }
    }
  };

  const HandleSubmit = (data) => {
    setData(data);
  };

  const HandleOtherData = (data) => {
    setOtherDta(data);
  };

  // get product by ID=============>

  useEffect(() => {
    getcolordata();
    getMainCategoryData();
    getSizeData();
  }, [URL]);

  return (
    <Layout>
      <AppBar subChild={"Products"}>Edit Product</AppBar>
      <Box
        justifyContent="center"
        display={"flex"}
        flexDirection="column"
        alignItems={"center"}
      >
        <Grid
          container
          sx={{
            padding: "20px",
            boxShadow:
              "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
            width: "95%",
          }}
        >
          <Stepper sx={{ width: "100%" }} activeStep={activeStep}>
            <Step>
              <StepLabel>Edit Product</StepLabel>
            </Step>
            <Step>
              <StepLabel optional>Add Images for customise product</StepLabel>
            </Step>
            <Step>
              <StepLabel optional>Product Info</StepLabel>
            </Step>
          </Stepper>

          {/* Stepper Main */}

          <Box
            display={"flex"}
            justifyContent="center"
            width={"100%"}
            marginTop={"50px"}
            flexDirection="column"
            alignItems={"center"}
          >
            <Box marginBottom={"50px"} width="100%">
              {/* Active Step ======================1 */}
              {activeStep === 0 && (
                <EditStep1
                  handleStep={handleActiveStep}
                  setData={HandleSubmit}
                  setCustomisePictureData={setCustomisePicture}
                  SizeChange={SizeChange}
                  MainCategory={MainCategory}
                  ColorChange={ColorChange}
                  defaultdata={data}
                  SizesAndColorData={HandleOtherData}
                  otherData={otherDta}
                  HandleLoading={HandleLoading}
                />
              )}
              {activeStep === 1 && (
                <EditStep2
                  data={data}
                  handleStep={handleActiveStep}
                  setCustomisePicturesData={setCustomisePicturesData}
                  customisePictureData={customisePicture}
                />
              )}
              {activeStep === 2 && (
                <EditStep3
                  data={data}
                  allColor={ColorChange}
                  allSize={SizeChange}
                  MainCategory={MainCategory}
                  customisePictureData={customisePicture}
                  handleStep={handleActiveStep}
                />
              )}
            </Box>
          </Box>
        </Grid>
      </Box>
    </Layout>
  );
}

export default EditProduct;
