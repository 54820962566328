import { Box, Toolbar, Typography } from '@mui/material'
import MuiAppBar from "@mui/material/AppBar";
import colors from 'app/style/Colors'
import React from 'react'
import Dropdown from "app/components/DropDown/Dropdown";
import useAuth from "app/hooks/useAuth";
import styled from '@emotion/styled';


const drawerWidth = 290;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  width: `calc(100% - 73px)`,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));


function Appbar() {
  // Sign Out
  const { signOut, InfoAdmin } = useAuth();
  return (
    <AppBar
      position="fixed"
      open={true}
      sx={{ bgcolor: colors.darkRed, color: "white" }}
    >
      <Toolbar>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography>{"Admin"}</Typography>
          <Dropdown onClick={() => signOut()} />

        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default Appbar