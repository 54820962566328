import {
  Box,
  Button,
  CardMedia,
  Chip,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { Fragment, useRef, useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import colors from "app/style/Colors";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import AddIcon from "@mui/icons-material/Add";
import Layout from "app/layout/Layout";
import AppBar from "app/components/AppBar/AppBar";
import Images from "app/assets/images";
import { useEffect } from "react";
import { ErrorToaster, SuccessToaster } from "app/components/UI/Toaster";
import { ToolManagementServices } from "app/apis/ToolManagement/ToolManagement.Services";
import UploadImageServices from "app/apis/UploadImage/UploadImage";
import ColorsServices from "app/apis/Colors/ColorsServices";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { Error } from "app/components/UI/Error";
import { UniqueId } from "app/components/UniqueId/UniqueId";
import { baseUrl } from "../../../axios";
import { data } from "app/components/DashboardBox/DashboardBox";

function ManageCustomisation(props) {
  const [MainCategory, setMainCategory] = useState("");
  console.log("🚀 MainCategory", MainCategory);
  const [SubCategory, setSubCategory] = useState("");
  console.log("🚀 SubCategory", SubCategory);
  // Image upload Hevi
  const [HeviImage, setHeviImage] = useState("");
  const [MonogramImage, setMonogramImage] = useState("");
  const [Sizes, setSizes] = useState([]);
  const [selectedSizes, setselectedSizes] = useState("");
  const [selectAphaCategory, setSelectAphaCategory] = useState("");
  console.log("🚀 selectAphaCategory", selectAphaCategory);
  const [MainCategoryData, setMainCategoryData] = useState([]);
  const [SubCategoryData, setSubCategoryData] = useState([]);
  const [Disability, setDisability] = useState(true);
  const [Colors, setColors] = useState([]);
  console.log("🚀 Colors", Colors);
  const [Loading, setLoading] = useState(false);
  // Hevi Image and color
  const [ImageAndColorHevi, setImageAndColorHevi] = useState([]);
  console.log("🚀 ImageAndColorHevi", ImageAndColorHevi);
  // MonogGram Image and color
  const [ImageAndColorMonoGram, setImageAndColorMonoGram] = useState([]);
  console.log("🚀 ImageAndColorMonoGram", ImageAndColorMonoGram);
  //  color Change
  const [selectedColorValue, setSelectColor] = useState("");
  const [SKU, setSKU] = useState("");
  console.log(
    "🚀 ~ file: CreateDesign.js:73 ~ ManageCustomisation ~ SKU:",
    SKU
  );
  const [price, setPrice] = useState("");
  console.log(
    "🚀 ~ file: CreateDesign.js:75 ~ ManageCustomisation ~ price:",
    price
  );
  const [title, setTitle] = useState("");
  console.log(
    "🚀 ~ file: CreateDesign.js:77 ~ ManageCustomisation ~ title:",
    title
  );
  const [size, setSize] = useState("");
  console.log(
    "🚀 ~ file: CreateDesign.js:79 ~ ManageCustomisation ~ size:",
    size
  );

  // const color_id = parseInt(
  //   ImageAndColorMonoGram.map(
  //     (data) => Colors.find((color) => color.name === data.color)?.id
  //   )
  // );
  // console.log("🚀 color_id", color_id);

  const navigate = useNavigate();

  const ImageRef = useRef();

  const baseURL = baseUrl;

  const style = {
    m: 0,
    ".MuiInputLabel-root": { background: "#f2f2f2" },
  };

  const [nameCategories, setNameCategories] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  // Main Category Change
  const categoryChange = (event) => {
    setSelectColor("");
    const IdOfCategory = MainCategoryData.find((e) => {
      if (e.name === event.target.value) {
        return e;
      }
    });
    setMainCategory(event.target.value);
    getSubCategoryData(IdOfCategory.id);
    setImageAndColorHevi([]);
    setImageAndColorMonoGram([]);
    setSubCategoryData([]);
    setSubCategory("");
  };

  // Sub Category Change
  const subcategorychange = (event) => {
    setSubCategory(event.target.value);
  };

  // Submit Data ------------->
  const Submitdata = async (data) => {
    if (ImageAndColorHevi.length !== 0 || ImageAndColorMonoGram.length !== 0) {
      let DataArray = [];
      const category_id = MainCategoryData.find((items) => {
        if (items.name === MainCategory) {
          return items.id;
        }
      });
      const subcategory_id = SubCategoryData.find((items) => {
        if (items.name === SubCategory) {
          return items.id;
        }
      });

      if (MainCategory === "HEVI Design") {
        ImageAndColorHevi.forEach((e) => {
          const colorId = Colors.find((color) => color.name === e.color)?.id;
          let obj = {
            sku: e.sku,
            image: e.image,
            title: e.title,
            color_id: colorId,
            price: e.price,
            size: e.size,
          };
          DataArray.push(obj);
        });
        // DataArray = [
        //   {
        //     sku: data?.sku,
        //     title: data?.title,
        //     size: data?.size,
        //     price: data?.price,
        //     // customisedetails: ColorAndImageFinalData,
        //   },
        // ];
      } else {
        // const color_id = ImageAndColorMonoGram.find((Data) => {
        //   return {
        //     color_id: Colors.find((color) => {
        //       if (Data.color === color.name) {
        //         return color.id;
        //       }
        //     }),
        //   };
        // });
        // const ColorAndImageFinalData = colorAndImage.map((e) => {
        //   return {
        //   };
        // });
        // const color_id = parseInt(
        //   ImageAndColorMonoGram.map(
        //     (data) => Colors.find((color) => color.name === data.color)?.id
        //   )
        // );
        ImageAndColorMonoGram.forEach((e) => {
          const colorId = Colors.find((color) => color.name === e?.color)?.id;
          let obj = {
            sku: e.sku,
            title: e.title,
            image: e.image,
            color_id: colorId,
            price: e.price,
          };
          DataArray.push(obj);
        });
        console.log(DataArray);
        // DataArray = ImageAndColorMonoGram.map((item) => ({
        //   sku: item.sku,
        //   title: item.title,
        //   image: item.image,
        //   color_id: color_id,
        //   price: item.price,
        // }));
      }
      const FinalData = {
        category_id: category_id.id,
        subcategory_id: subcategory_id.id,
        size: selectedSizes === "" ? "-" : selectedSizes,
        customise_cat_id: selectAphaCategory.id ?? "-",
        customisedetails: DataArray,
      };
      console.log("FinalData=>", FinalData);
      // Call Api ----------->
      try {
        setLoading(true);
        const result = await ToolManagementServices.createToolProduct(
          FinalData
        );
        if (result.responseCode === 200) {
          SuccessToaster("Product Created Successfully");
        } else {
          ErrorToaster("Oops something went wrong!");
        }
      } catch (e) {
        ErrorToaster(e);
      } finally {
        setLoading(false);
        navigate("/Toolmanagement/viewall");
      }
    } else {
      ErrorToaster("Kindly Enter Design");
    }
  };

  // Get Main Category Data
  const getMainCategoryData = async () => {
    try {
      const result = await ToolManagementServices.getMainCategory();
      if (result.responseCode === 200) {
        const FilteredMainCategory = result.data.filter((e) => {
          if (e.name !== "Custom Design") {
            return e;
          }
        });
        setMainCategoryData(FilteredMainCategory);
      } else {
        ErrorToaster("Oops an error occur");
      }
    } catch (e) {
      ErrorToaster(e);
    } finally {
    }
  };

  // Get Sub Category Data
  const getSubCategoryData = async (id) => {
    try {
      const result = await ToolManagementServices.getSubCategory(id);
      if (result.responseCode === 200) {
        setSubCategoryData(result.data);
      } else {
        ErrorToaster("Oops an error occur");
      }
    } catch (e) {
      ErrorToaster(e);
    } finally {
      setDisability(false);
    }
  };

  // Handle Hevi Image
  const handleUploadImage = async (event, type) => {
    SuccessToaster("Image is loading please wait");
    try {
      const formData = new FormData();
      formData.append("image", event.target.files[0]);
      const result = await UploadImageServices.uploadImage(formData);
      if (result.responseCode === 200) {
        if (type === "hevi") {
          setHeviImage(result.data);
        }
        if (type === "monogram") {
          setMonogramImage(result.data);
        }
      }
    } catch (e) {
      ErrorToaster(e);
    } finally {
      ClearRef();
    }
  };

  // Get All colors
  const getAllColors = async () => {
    try {
      const result = await ColorsServices.getAllColors();
      if (result.responseCode === 200) {
        setColors(result.data);
      }
    } catch (e) {
      ErrorToaster(e);
    }
  };

  // Get All sizes Monograms
  const getAllSizes = async () => {
    try {
      const result = await ToolManagementServices.getAllDesignSizes();
      if (result.responseCode === 200) {
        setSizes(result.data);
      } else {
        ErrorToaster("Oops an error occur");
      }
    } catch (e) {
      ErrorToaster(e);
    } finally {
    }
  };

  // HandleSize Change
  const handleSizeChange = (event) => {
    setselectedSizes(event.target.value);
  };

  // Hevi Design Managing Customisation
  const handleColorValueChange = (event) => {
    setSelectColor(event.target.value);
  };

  // Add Data Hevi Design
  const AddData = (Type) => {
    if (Type === "HeviDesign") {
      if (HeviImage && selectedColorValue) {
        const ID = UniqueId();
        let Array = [...ImageAndColorHevi];
        const obj = {
          id: ID,
          sku: SKU,
          price: price,
          title: title,
          size: size,
          color: selectedColorValue,
          image: HeviImage,
        };
        Array.push(obj);
        setImageAndColorHevi(Array);
        SuccessToaster("Item Added");
        setSelectColor("");
        setHeviImage("");
        setSKU("");
        setPrice("");
        setTitle("");
        setSize("");
      } else {
        ErrorToaster("please select image and color first");
      }
    } else {
      if (MonogramImage && selectedColorValue) {
        const ID = UniqueId();
        let Array = [...ImageAndColorMonoGram];
        const obj = {
          id: ID,
          sku: SKU,
          price: price,
          title: title,
          color: selectedColorValue,
          image: MonogramImage,
        };
        Array.push(obj);
        setImageAndColorMonoGram(Array);
        SuccessToaster("Item Added");
        setSelectColor("");
        setMonogramImage("");
        setSKU("");
        setPrice("");
        setTitle("");
      } else {
        ErrorToaster("please select image and color first");
      }
    }
  };

  // Delete Product from Queue Hevi Design
  const DeleteItemHevi = (Data) => {
    const NewData = ImageAndColorHevi.filter((e) => {
      if (Data.id !== e.id) {
        return e;
      }
    });
    setImageAndColorHevi(NewData);
  };

  const DeleteItemMonogram = (Data) => {
    const NewData = ImageAndColorMonoGram.filter((e) => {
      if (Data.id !== e.id) {
        return e;
      }
    });
    setImageAndColorMonoGram(NewData);
  };

  // Clear Image Inner Value
  const ClearRef = () => {
    ImageRef.current.value = null;
  };

  //Get All Name Categories
  const getAllName = async () => {
    try {
      const { data, responseCode } = await ToolManagementServices.getNames();
      if (responseCode === 200) {
        setNameCategories(data);
        console.log(data);
      }
    } catch (error) {
      console.log("error=>", error);
    }
  };

  useEffect(() => {
    getMainCategoryData();
    getAllColors();
    getAllSizes();
    getAllName();
  }, [URL]);

  return (
    <Layout>
      <Fragment>
        <AppBar subChild="Create Design">Create Design</AppBar>

        <Box sx={{ p: 4, pt: 5 }}>
          {" "}
          {/* boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px" */}
          <Typography
            variant="h4"
            fullWidth
            fontWeight={"900"}
            display="flex"
            alignItems={"start"}
          >
            Customise Product Design
          </Typography>
          <Grid
            mt="10px"
            container
            flexDirection={"column"}
            width="100%"
            alignItems="center"
            p="20px"
          >
            <Grid
              component="form"
              onSubmit={handleSubmit(Submitdata)}
              container
              marginTop="10px"
              width={"100%"}
              justifyContent={"center"}
            >
              <Grid item md={12}>
                <Grid container spacing={2} justifyContent={"center"}>
                  {/* Main Category */}
                  <Grid item xs="5">
                    <FormControl fullWidth sx={style}>
                      <InputLabel id="demo-simple-select-helper-label">
                        Main category
                      </InputLabel>
                      <Select
                        fullWidth
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={MainCategory}
                        label="Age"
                        onChange={categoryChange}
                      >
                        {MainCategoryData.map((category, index) => (
                          <MenuItem key={index} value={category.name}>
                            {category.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {/* <FormHelperText>With label + helper text</FormHelperText> */}
                    </FormControl>
                  </Grid>

                  {/* sub Category */}
                  <Grid item xs="5">
                    <FormControl fullWidth sx={style}>
                      <InputLabel id="demo-simple-select-helper-label">
                        Sub Category
                      </InputLabel>
                      <Select
                        fullWidth
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={SubCategory}
                        disabled={Disability}
                        label="Sub Category"
                        onChange={subcategorychange}
                      >
                        {SubCategoryData.map((SubCategory, index) => {
                          return (
                            <MenuItem key={index} value={SubCategory.name}>
                              {SubCategory.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* Tool Item Size */}
                  {MainCategory === "Monograms" && SubCategory && (
                    <Grid item xs="5">
                      <FormControl fullWidth>
                        <InputLabel id="demo-select-small">
                          Select Size
                        </InputLabel>
                        <Select
                          labelId="demo-select-small"
                          id="demo-select-small"
                          value={selectedSizes}
                          label="Select Size"
                          onChange={handleSizeChange}
                        >
                          {Sizes.map((e) => (
                            <MenuItem value={e.name}>{e.name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  {/* Category Item */}
                  {MainCategory === "Monograms" && SubCategory && (
                    <Grid item xs="5">
                      <FormControl fullWidth>
                        <InputLabel id="demo-select-small">
                          Select Category
                        </InputLabel>
                        <Select
                          labelId="demo-select-small"
                          id="demo-select-small"
                          value={selectAphaCategory}
                          label="Select Category"
                          onChange={(e) =>
                            setSelectAphaCategory(e.target.value)
                          }
                        >
                          {nameCategories.map((e, i) => (
                            <MenuItem key={i} value={e}>
                              {e.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
              </Grid>

              {/* Optional Fields ------------------------------------------------------------------------> */}

              <Grid item xs="12">
                <Grid container justifyContent={"center"}>
                  <Grid item xs="12">
                    <Grid container justifyContent={"center"}>
                      {MainCategory === "Monograms" && SubCategory && (
                        <Grid item md={12}>
                          <Grid container spacing={2} mt={"20px"}>
                            <Grid item xs="12">
                              <Typography
                                variant="h5"
                                sx={{ fontWeight: "900" }}
                              >
                                Enter Monogram Design:
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container justifyContent={"center"} mt={"30px"}>
                            {/* MonoGram Designnnnnn-----------------> */}
                            <Grid item xs="10">
                              <Grid container spacing={2}>
                                <Grid item md={12}>
                                  <Grid
                                    container
                                    columnSpacing={2}
                                    justifyContent={"center"}
                                  >
                                    {/* Sku Number */}
                                    <Grid item xs="4">
                                      <TextField
                                        fullWidth
                                        type="text"
                                        label="SKU number"
                                        placeholder="Enter SKU number"
                                        value={SKU}
                                        error={errors?.skuMonogram?.message}
                                        helperText={
                                          errors?.skuMonogram?.message
                                        }
                                        {...register("skuMonogram", {
                                          required:
                                            ImageAndColorMonoGram.length === 0
                                              ? "kindly enter Sku number"
                                              : false,
                                          onChange: (e) =>
                                            setSKU(e.target.value),
                                        })}
                                      />
                                    </Grid>

                                    {/* Enter Price */}
                                    <Grid item xs="4">
                                      <TextField
                                        fullWidth
                                        type="text"
                                        label="Price"
                                        placeholder="Enter price"
                                        value={price}
                                        error={errors?.priceMonoGram?.message}
                                        helperText={
                                          errors?.priceMonoGram?.message
                                        }
                                        {...register("priceMonoGram", {
                                          required:
                                            ImageAndColorMonoGram.length === 0
                                              ? "kindly enter price"
                                              : false,
                                          onChange: (e) =>
                                            setPrice(e.target.value),
                                        })}
                                      />
                                    </Grid>

                                    {/* Enter Title */}
                                    <Grid item xs="4">
                                      <TextField
                                        fullWidth
                                        type="text"
                                        label="Title"
                                        placeholder="Enter title"
                                        value={title}
                                        error={errors?.MonoGramTitle?.message}
                                        helperText={
                                          errors?.MonoGramTitle?.message
                                        }
                                        {...register("MonoGramTitle", {
                                          required:
                                            ImageAndColorMonoGram.length === 0
                                              ? "kindly enter title"
                                              : false,
                                          onChange: (e) =>
                                            setTitle(e.target.value),
                                        })}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>

                                <Grid item md={12}>
                                  <Grid container columnSpacing={2}>
                                    {/* Image Upload */}
                                    <Grid item xs="4">
                                      <Box
                                        display={"flex"}
                                        flexDirection="column"
                                        alignItems={"center"}
                                        justifyContent={"center"}
                                      >
                                        <CardMedia
                                          component="img"
                                          height="100px"
                                          sx={{
                                            objectFit: "contain",
                                          }}
                                          image={
                                            MonogramImage
                                              ? baseURL + MonogramImage
                                              : Images.UploadBGRemove
                                          }
                                          alt="Paella dish"
                                        />
                                        {/* Image Upload Button */}
                                        <Box>
                                          <span
                                            class="btn btn-primary btn-file"
                                            style={{
                                              position: "relative",
                                              overflow: "hidden",
                                              background: "#b71431",
                                              color: "white",
                                              height: "50px",
                                              width: "fit-content",
                                              padding: "15px",
                                              borderRadius: "5px",
                                              cursor: "pointer",
                                              display: "flex",
                                              alignItems: "center",
                                              gap: "10px",
                                            }}
                                          >
                                            <CloudUploadIcon />
                                            Upload Image
                                            <input
                                              ref={ImageRef}
                                              name="image"
                                              // error={errors?.imageMonogram?.message}
                                              // {...register("imageMonogram", {
                                              //   required: "Image is required",
                                              // })}
                                              style={{
                                                position: " absolute",
                                                top: "0",
                                                right: "0",
                                                minWidth: "100%",
                                                mineight: "100%",
                                                fontSize: " 100px",
                                                align: "right",
                                                filter: "alpha(opacity=0)",
                                                opacity: "0",
                                                outline: "none",
                                                background: "white",
                                                cursor: "inherit",
                                                display: "block",
                                              }}
                                              type="file"
                                              onChange={(e) => {
                                                handleUploadImage(
                                                  e,
                                                  "monogram"
                                                );
                                              }}
                                            ></input>
                                          </span>
                                          {/* {errors?.imageMonogram?.message && (
                                        <Error
                                          message={
                                            errors?.imageMonogram?.message
                                          }
                                        />
                                      )} */}
                                        </Box>
                                      </Box>
                                    </Grid>

                                    {/* Color Data */}
                                    <Grid item xs="4">
                                      <FormControl
                                        fullWidth
                                        sx={{
                                          ".MuiSelect-select ": {
                                            display: "flex",
                                            gap: "10px",
                                          },
                                        }}
                                      >
                                        <InputLabel id="demo-simple-select-helper-label">
                                          Colors
                                        </InputLabel>
                                        <Select
                                          sx={{ display: "flex" }}
                                          labelId="demo-simple-select-helper-label"
                                          id="demo-simple-select-helper"
                                          value={selectedColorValue}
                                          label="Colors"
                                          onChange={handleColorValueChange}
                                        >
                                          {Colors.map((colors) => (
                                            <MenuItem
                                              value={colors.name}
                                              sx={{
                                                display: "flex",
                                                gap: "10px",
                                                flexDirection: "c",
                                              }}
                                            >
                                              <Box
                                                sx={{
                                                  height: "20px",
                                                  width: "20px",
                                                  borderRadius: "50%",
                                                  background: colors.code,
                                                }}
                                              ></Box>
                                              {colors.name}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                        {/* <FormHelperText>
                                    With label + helper text
                                  </FormHelperText> */}
                                      </FormControl>
                                    </Grid>

                                    <Grid item xs="2">
                                      <Button
                                        sx={{ border: "1px solid green" }}
                                        onClick={() => {
                                          AddData("Monogram");
                                        }}
                                      >
                                        Add
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </Grid>

                                {/* Table That shows Items in Queue  MonoGram*/}
                                {ImageAndColorMonoGram.length !== 0 && (
                                  <Grid item xs="12">
                                    <TableContainer component={Paper}>
                                      <Table
                                        size="small"
                                        aria-label="a dense table"
                                      >
                                        <TableHead>
                                          <TableRow>
                                            <TableCell align="center">
                                              Image
                                            </TableCell>
                                            <TableCell align="center">
                                              Title
                                            </TableCell>
                                            <TableCell align="right">
                                              SKU
                                            </TableCell>
                                            <TableCell align="right">
                                              Color
                                            </TableCell>
                                            <TableCell align="right">
                                              Action
                                            </TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {ImageAndColorMonoGram.map(
                                            (Data, index) => (
                                              <TableRow
                                                key={index}
                                                sx={{
                                                  "&:last-child td, &:last-child th":
                                                    { border: 0 },
                                                }}
                                              >
                                                <TableCell
                                                  component="th"
                                                  scope="row"
                                                >
                                                  <CardMedia
                                                    component="img"
                                                    height="50px"
                                                    sx={{
                                                      objectFit: "contain",
                                                    }}
                                                    image={baseURL + Data.image}
                                                    alt="Paella dish"
                                                  />
                                                </TableCell>
                                                <TableCell align="center">
                                                  {Data.title}
                                                </TableCell>
                                                <TableCell align="right">
                                                  {Data.sku}
                                                </TableCell>
                                                <TableCell align="right">
                                                  {Data.color}
                                                </TableCell>
                                                <TableCell align="right">
                                                  <Box
                                                    sx={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                      DeleteItemMonogram(Data);
                                                    }}
                                                  >
                                                    <DeleteIcon
                                                      sx={{ color: "red" }}
                                                    />
                                                  </Box>
                                                </TableCell>
                                              </TableRow>
                                            )
                                          )}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  </Grid>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}

                      {/* Hevi Design --------------> */}
                      {MainCategory === "HEVI Design" && (
                        <Box>
                          <Grid item xs="12" mt="20px">
                            <Typography variant="h5" sx={{ fontWeight: "900" }}>
                              Enter Design:
                            </Typography>
                          </Grid>

                          <Grid item xs="12">
                            <Grid
                              container
                              justifyContent={"center"}
                              gap="20px"
                              mt="30px"
                              alignItems={"center"}
                            >
                              {/* Sku Number */}
                              <Grid item xs="5">
                                <TextField
                                  placeholder="Enter SKU number"
                                  name="Sku Number"
                                  label="SKU Number"
                                  variant="outlined"
                                  value={SKU}
                                  error={errors?.sku?.message}
                                  helperText={errors?.sku?.message}
                                  fullWidth
                                  {...register("sku", {
                                    required:
                                      ImageAndColorHevi.length === 0
                                        ? "kindly enter sku number"
                                        : false,
                                    onChange: (e) => setSKU(e.target.value),
                                  })}
                                />
                              </Grid>
                              {/* Price  */}
                              <Grid item xs="5">
                                <TextField
                                  label="Price"
                                  placeholder="Price"
                                  name="price"
                                  value={price}
                                  type={"number"}
                                  variant="outlined"
                                  error={errors?.price?.message}
                                  helperText={errors?.price?.message}
                                  fullWidth
                                  {...register("price", {
                                    required:
                                      ImageAndColorHevi.length === 0
                                        ? "kindly enter price"
                                        : false,
                                    onChange: (e) => setPrice(e.target.value),
                                  })}
                                />
                              </Grid>
                              {/* title */}
                              <Grid item xs="5">
                                <TextField
                                  label="Title"
                                  placeholder="Title"
                                  name="title"
                                  value={title}
                                  error={errors?.title?.message}
                                  helperText={errors?.title?.message}
                                  variant="outlined"
                                  fullWidth
                                  {...register("title", {
                                    required:
                                      ImageAndColorHevi.length === 0
                                        ? "kindly enter title"
                                        : false,
                                    onChange: (e) => setTitle(e.target.value),
                                  })}
                                />
                              </Grid>
                              {/* Size  */}
                              <Grid item xs="5">
                                <TextField
                                  placeholder="Size"
                                  name="size"
                                  value={size}
                                  label="Size"
                                  variant="outlined"
                                  error={errors?.size?.message}
                                  helperText={errors?.size?.message}
                                  fullWidth
                                  {...register("size", {
                                    required:
                                      ImageAndColorHevi.length === 0
                                        ? "kindly enter size"
                                        : false,
                                    onChange: (e) => setSize(e.target.value),
                                  })}
                                />
                              </Grid>

                              {/* Image Upload Section */}
                              <Grid
                                item
                                xs="2"
                                display="flex"
                                justifyContent={"center"}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    gap: "20px",
                                  }}
                                >
                                  <CardMedia
                                    component={"img"}
                                    sx={{
                                      height: "100px",
                                      objectFit: "contain",
                                      display: "flex",
                                      justifyContent: "start",
                                    }}
                                    image={
                                      HeviImage
                                        ? "https://api-designtool.baitalkhairkitchen.com" +
                                          HeviImage
                                        : Images.UploadBGRemove
                                    }
                                    alt="Paella dish"
                                  />
                                  {/* Upload Image Button Span */}
                                  <Box>
                                    <span
                                      class="btn btn-primary btn-file"
                                      name="image"
                                      style={{
                                        position: "relative",
                                        overflow: "hidden",
                                        background: "#b71431",
                                        color: "white",
                                        height: "50px",
                                        width: "fit-content",
                                        padding: "15px",
                                        borderRadius: "5px",
                                        cursor: "pointer",
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "10px",
                                      }}
                                    >
                                      <CloudUploadIcon /> Upload Image
                                      <input
                                        name="image"
                                        ref={ImageRef}
                                        // error={errors?.imageHevi?.message}
                                        // {...register("imageHevi", {
                                        //   required: "image is required",
                                        // })}
                                        style={{
                                          position: " absolute",
                                          top: "0",
                                          right: "0",
                                          minWidth: "100%",
                                          mineight: "100%",
                                          fontSize: " 100px",
                                          align: "right",
                                          filter: "alpha(opacity=0)",
                                          opacity: "0",
                                          outline: "none",
                                          background: "white",
                                          cursor: "inherit",
                                          display: "block",
                                        }}
                                        type="file"
                                        onChange={(e) => {
                                          handleUploadImage(e, "hevi");
                                        }}
                                      ></input>
                                    </span>
                                    {/* {errors?.imageHevi?.message && (
                                      <Error
                                        message={errors?.imageHevi?.message}
                                      />
                                    )} */}
                                  </Box>
                                </Box>
                              </Grid>

                              {/* Color Data  */}
                              <Grid item xs="2">
                                <FormControl
                                  fullWidth
                                  sx={{
                                    ".MuiSelect-select ": {
                                      display: "flex",
                                      gap: "10px",
                                    },
                                  }}
                                >
                                  <InputLabel id="demo-simple-select-helper-label">
                                    Colors
                                  </InputLabel>
                                  <Select
                                    sx={{ display: "flex" }}
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={selectedColorValue}
                                    label="Colors"
                                    onChange={handleColorValueChange}
                                  >
                                    {Colors.map((colors) => (
                                      <MenuItem
                                        value={colors.name}
                                        sx={{
                                          display: "flex",
                                          gap: "10px",
                                          flexDirection: "c",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            height: "20px",
                                            width: "20px",
                                            borderRadius: "50%",
                                            background: colors.code,
                                          }}
                                        ></Box>
                                        {colors.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  {/* <FormHelperText>
                                    With label + helper text
                                  </FormHelperText> */}
                                </FormControl>
                              </Grid>

                              {/* Add Data */}
                              <Grid
                                item
                                xs="1"
                                onClick={() => {
                                  AddData("HeviDesign");
                                }}
                              >
                                <Button sx={{ border: "2px solid green" }}>
                                  Add
                                </Button>
                              </Grid>

                              {/* Table That shows Items in Queue */}
                              {ImageAndColorHevi.length !== 0 && (
                                <Grid item xs="12">
                                  <TableContainer component={Paper}>
                                    <Table
                                      size="small"
                                      aria-label="a dense table"
                                    >
                                      <TableHead>
                                        <TableRow>
                                          <TableCell align="center">
                                            Image
                                          </TableCell>
                                          <TableCell align="center">
                                            Title
                                          </TableCell>
                                          <TableCell align="right">
                                            SKU
                                          </TableCell>
                                          <TableCell align="right">
                                            Color
                                          </TableCell>
                                          <TableCell align="right">
                                            Action
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {ImageAndColorHevi.map((Data) => (
                                          <TableRow
                                            // key={row.name}
                                            sx={{
                                              "&:last-child td, &:last-child th":
                                                { border: 0 },
                                            }}
                                          >
                                            <TableCell
                                              component="th"
                                              scope="row"
                                            >
                                              <CardMedia
                                                component="img"
                                                height="50px"
                                                sx={{
                                                  objectFit: "contain",
                                                }}
                                                image={
                                                  "https://api-designtool.baitalkhairkitchen.com" +
                                                  Data.image
                                                }
                                                alt="Paella dish"
                                              />
                                            </TableCell>
                                            <TableCell align="center">
                                              {Data.title}
                                            </TableCell>
                                            <TableCell align="right">
                                              {Data.sku}
                                            </TableCell>
                                            <TableCell align="right">
                                              {Data.color}
                                            </TableCell>
                                            <TableCell align="right">
                                              <Box
                                                sx={{ cursor: "pointer" }}
                                                onClick={() => {
                                                  DeleteItemHevi(Data);
                                                }}
                                              >
                                                <DeleteIcon
                                                  sx={{ color: "red" }}
                                                />
                                              </Box>
                                            </TableCell>
                                          </TableRow>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* Submit Button ----------------------------------------------------------------------------> */}
              {Disability === false && (
                <Grid item xs="12" mt="50px">
                  <Grid container justifyContent={"center"}>
                    <LoadingButton
                      loading={Loading}
                      type="submit"
                      sx={{
                        width: "300px",
                        background: colors.darkRed,
                        color: "white",
                        ":hover": {
                          background: "white",
                          color: colors.darkRed,
                        },
                      }}
                    >
                      Submit data
                    </LoadingButton>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Box>
      </Fragment>
    </Layout>
  );
}

export default ManageCustomisation;
