import { Box, Button, CardMedia, Grid, Typography } from "@mui/material";
import { CreateProductServices } from "app/apis/CreateProduct/CreateProductServices";
import { Loading } from "app/assets/images";
import { ErrorToaster } from "app/components/UI/Toaster";
import AppBar from "app/components/AppBar/AppBar";
import Layout from "app/layout/Layout";
import React, { useEffect } from "react";
import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";

function ViewQuoatationDesign() {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [Productdata, setProductData] = useState({});
  console.log("🚀 Productdata:", Productdata);
  const [designDetail, setDesignDetail] = useState();
  console.log("🚀 designDetail:", designDetail);
  const [bgColor, setBgColor] = useState("");
  const [showDownloadButton, setShowDownloadButton] = useState(false);

  const [mainImage, setMainImage] = useState("");

  const { state } = location;
  console.log("🚀state:", state);

  const getProductById = async () => {
    setLoading(true);
    try {
      const result = await CreateProductServices.getProducDetailtById(
        state.data.product_id
      );
      if (result.responseCode === 200) {
        setProductData(result.data);
        console.log("🚀result.data:", result.data);
        setMainImage(result.data[0].product_customises_images[0]);
        result.data.forEach((e) => {
          const colorCode = e.product_details.find(
            (item) => item.color_name === state.data.color
          )?.color_code;
          setBgColor(colorCode);
        });
      } else {
        console.log("result", result);
      }
    } catch (e) {
      ErrorToaster(e);
    } finally {
      setLoading(false);
    }
  };

  // const handleDownload = () => {
  // Implement logic to download the image
  // You can use the mainImage.image URL for downloading
  //   const downloadLink = document.createElement("a");
  //   downloadLink.href = designDetail.image;
  //   downloadLink.download = "downloaded_image.jpg";
  //   document.body.appendChild(downloadLink);
  //   downloadLink.click();
  //   document.body.removeChild(downloadLink);
  // };

  // const handleDownload = () => {
  //   // Create a Blob from the image URL
  //   fetch(designDetail.image)
  //     .then((response) => response.blob())
  //     .then((blob) => {
  //       // Create a temporary URL for the Blob
  //       const url = URL.createObjectURL(blob);

  //       // Create a temporary link for downloading
  //       const downloadLink = document.createElement("a");
  //       downloadLink.href = url;
  //       downloadLink.download = "downloaded_image.jpg";

  //       // Append the link to the document and trigger a click event
  //       document.body.appendChild(downloadLink);
  //       downloadLink.click();

  //       // Remove the link and revoke the temporary URL
  //       document.body.removeChild(downloadLink);
  //       URL.revokeObjectURL(url);
  //     })
  //     .catch((error) => {
  //       console.error("Error downloading image:", error);
  //     });
  // };

  // const handleDownload = () => {
  //   const link = document.createElement("a");
  //   link.href = designDetail.image;
  //   link.download = "downloaded_image.jpg";

  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  const handleDownload = () => {
    const url = designDetail.image;
    let link = document.createElement("a");
    link.href = url;
    link.download = "test";
    document.body.appendChild(link);
    link.click();
    // fetch(designDetail.image)
    //   .then((response) => response.blob())
    //   .then((blob) => {
    //     const url = window.URL.createObjectURL(new Blob([blob]));
    //     const link = document.createElement("a");
    //     link.href = url;
    //     link.setAttribute("download", "downloaded_image.jpg");
    //     document.body.appendChild(link);
    //     link.click();
    //     // document.body.removeChild(link);
    //   })
    //   .catch((error) => {
    //     console.error("Error downloading image:", error);
    //   });
  };

  const HandleMainImage = (image) => {
    setMainImage(image);
  };

  //   Hanlde Design Info
  const HandleDesignInfo = (design) => {
    setDesignDetail(design);
    setShowDownloadButton(true);
  };

  useEffect(() => {
    getProductById();
  }, [URL]);

  return (
    <Layout>
      <AppBar subChild="view customise order">Customise Order</AppBar>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "30vh",
          }}
        >
          <Loading />
        </Box>
      ) : (
        <Grid container padding={"20px"} justifyContent="space-between">
          <Grid item xs="5">
            <Typography variant="h4" sx={{ fontWeight: "600" }}>
              Product Detail:
            </Typography>

            <Box
              sx={{
                borderRadius: "10px",
                background: "#f4f4f4",
                color: "#2d323ef2",
                border: "1px solid #2d323ef2",
                width: "100%",
                // boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                padding: "15px",
                mt: "20px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              {/* <Typography variant="h5">
                Product SKU: {state.data.product_sku}
              </Typography> */}

              <Typography variant="h7">
                Product Name: {state.data.product_name}
              </Typography>
              <Typography variant="h7">
                Selected Color: {state.data.color}
              </Typography>
              <Typography variant="h7">
                Selected Size: {state.data.size}
              </Typography>
            </Box>

            {state.data.message && (
              <Typography variant="h6" sx={{ color: "red", mt: "20px" }}>
                User Custom Design Note:
              </Typography>
            )}
            {state.data.message && (
              <Box
                sx={{
                  borderRadius: "10px",
                  background: "#f4f4f4",
                  color: "#2d323ef2",
                  border: "1px solid #2d323ef2",
                  width: "100%",
                  // boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  padding: "15px",
                  mt: "20px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Typography sx={{}}>{state.data.message}</Typography>
              </Box>
            )}

            {designDetail && (
              <Typography variant="h4" sx={{ fontWeight: "600", mt: "20px" }}>
                Design Detail:
              </Typography>
            )}

            {designDetail && (
              <Box
                sx={{
                  borderRadius: "10px",
                  background: "#2d323ef2",
                  color: "white",
                  border: "1px solid white",
                  width: "100%",
                  //   boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  padding: "15px",
                  mt: "20px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Typography variant="h5">
                  {designDetail?.sku
                    ? "Design Sku: " + designDetail?.sku
                    : "The Following design is a Custom design"}
                </Typography>

                <Typography variant="h7">
                  Category Name: {designDetail?.category_name}
                </Typography>
                <Typography variant="h7">
                  SubCategory Name: {designDetail?.subcategory_name}
                </Typography>

                {designDetail?.size && (
                  <Typography variant="h7">
                    Size: {designDetail?.size}
                  </Typography>
                )}
                {designDetail?.value && (
                  <Typography variant="h7">
                    Value: {designDetail?.value}
                  </Typography>
                )}
                <Typography variant="h7">
                  Side: {designDetail?.side === "front" && "1"}
                </Typography>
                {designDetail?.color && (
                  <Typography variant="h7">
                    Color: {designDetail?.color}
                  </Typography>
                )}
                {designDetail?.price && (
                  <Typography variant="h7">
                    Price: {designDetail?.price}
                  </Typography>
                )}
              </Box>
            )}
            {showDownloadButton && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleDownload}
              >
                Download Image
              </Button>
            )}
          </Grid>

          {Productdata.length !== 0 && (
            <Grid item xs="5">
              <Grid container spacing={3}>
                <Grid item xs="12">
                  <Box
                    sx={{
                      position: "relative",
                      width: "fit-content",
                      height: "fit-content",
                    }}
                  >
                    <CardMedia
                      component={"img"}
                      image={mainImage.image}
                      sx={{
                        objectFit: "contain",
                        width: "400px",
                        height: "500px",
                        backgroundColor: bgColor,
                      }}
                    />

                    <Box
                      sx={{
                        position: "absolute",
                        top: `${mainImage.top}%`,
                        left: `${mainImage.left}%`,
                        height: `${mainImage.height}%`,
                        width: `${mainImage.width}%`,
                        border: "2px solid black",
                        borderStyle: "dotted",
                      }}
                    >
                      <Box
                        sx={{ width: "100%", height: "100%", zIndex: "999" }}
                      >
                        {state.data.design_details.map(
                          (design, index) =>
                            design.side === mainImage.img_name && (
                              <Box
                                key={index}
                                position={"absolute"}
                                sx={{
                                  top: design?.top,
                                  left: design?.left,
                                  width:
                                    design?.width !== ""
                                      ? design.width
                                      : design.value !== ""
                                      ? "fit-content"
                                      : "80px",
                                  height:
                                    design?.height !== ""
                                      ? design.height
                                      : design.value !== ""
                                      ? "fit-content"
                                      : "80px",

                                  padding: design.value !== "" ? "10px" : "0px",
                                }}
                              >
                                {design.image !== "" && (
                                  <Box
                                    onClick={() => {
                                      HandleDesignInfo(design);
                                    }}
                                    sx={{
                                      width: "100%",
                                      height: "100%",
                                      transition: "0.2s ease-in-out",
                                      cursor: "pointer",
                                      ":hover": {
                                        boxShadow:
                                          "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                                        border: "1px solid white",
                                      },
                                    }}
                                  >
                                    <CardMedia
                                      component={"img"}
                                      image={design.image}
                                      sx={{
                                        height: "100%",
                                        width: "100%",
                                        objectFit: "contain",
                                      }}
                                    />
                                  </Box>
                                )}

                                {design.value && (
                                  <Box
                                    onClick={() => {
                                      HandleDesignInfo(design);
                                    }}
                                    sx={{
                                      width: "100%",
                                      height: "100%",
                                      transition: "0.2s ease-in-out",
                                      ":hover": {
                                        boxShadow:
                                          "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                                        border: "1px solid white",
                                      },
                                    }}
                                  >
                                    <Typography
                                      variant="h4"
                                      sx={{
                                        cursor: "pointer",
                                        color: design.color,
                                        fontFamily: "Dancing Script, cursive",
                                      }}
                                    >
                                      {" "}
                                      {design.value}
                                    </Typography>
                                  </Box>
                                )}
                              </Box>
                            )

                          /* Text field */
                        )}
                        {/* Image Render */}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs="12">
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      gap: "10px",
                      padding: "10px",
                    }}
                  >
                    {Productdata[0]?.product_customises_images?.map((image) => (
                      <Grid
                        onClick={() => {
                          HandleMainImage(image);
                        }}
                        item
                        xs="2"
                        sx={{
                          cursor: "pointer",
                          boxShadow: " rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                          background: "white",
                          borderRadius: "10px",
                        }}
                      >
                        <CardMedia
                          component={"img"}
                          image={image.image}
                          sx={{
                            objectFit: "contain",
                            width: "100%",
                            height: "100px",
                            backgroundColor: bgColor,
                          }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
    </Layout>
  );
}

export default ViewQuoatationDesign;
