import { Box, CardMedia, Grid, Paper, Typography } from "@mui/material";
import { CreateProductServices } from "app/apis/CreateProduct/CreateProductServices";
import HeaderAppBar from "app/components/AppBar/AppBar";
import { ErrorToaster, SuccessToaster } from "app/components/UI/Toaster";
import Layout from "app/layout/Layout";
import { useEffect, useState } from "react";
import { Fragment } from "react";
import { useParams } from "react-router-dom";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import Images, { Loading } from "app/assets/images";
import colors from "app/style/Colors";

const ViewProductsinfo = () => {
  const [loading, setLoading] = useState(true);

  // Dummy data to check

  const [Data, setdata] = useState();
  console.log("🚀 Data", Data);
  const [CardImage, setCardImage] = useState("");
  const [color, setColor] = useState("");
  const [colorAndSizeData, setColorAndSizeData] = useState([]);
  const [filterSize, setFilterSize] = useState([]);
  // Product detailed Information
  const { id } = useParams();

  // Get Info of Product
  const getInfo = async (id) => {
    setLoading(true);
    try {
      let result = await CreateProductServices.getProductDetailDescription(id);
      if (result.responseCode === 200) {
        setdata(result.data[0]);
        setCardImage(result.data[0].product_customises[0].image);
        filterData();
      } else {
        ErrorToaster(result.message);
      }
    } catch (e) {
      ErrorToaster(e);
    } finally {
      setLoading(false);
    }
  };

  const filterData = () => {
    let variations = [];
    Data?.product_details?.forEach((element) => {
      const index = variations.findIndex(
        (e) => e.color_id === element.color_id
      );
      if (index === -1) {
        let fit = element.product_detail_sizes.map((item) => ({
          fittype_id: item.fittype_id,
          fittype_name: item.fittype_name,
        }));
        let obj = {
          fittype: fit,
          color_id: element.color_id,
          color_name: element.color_name,
          color_code: element.color_code,
        };
        variations.push(obj);
      } else {
        const updateFit = [...variations[index].fittype];
        const fitTypeIndex = updateFit.findIndex(
          (e) => e.fittype_id === element.product_detail_sizes.id
        );
        if (fitTypeIndex === -1) {
          let obj = {
            fittype_id: element.product_detail_sizes.map((item) => item.id),
            fittype_name: element.product_detail_sizes.map((item) => item.name),
          };
          updateFit.push(obj);
        }
        variations[index].fittype = updateFit;
      }
      setColorAndSizeData(variations);
    });
  };

  const handleSizeByColor = (data) => {
    setColor(data.color_name);
    setFilterSize(data.fittype);
  };

  // useEffect(() => {
  //   getInfo(id);
  // }, [URL]);

  useEffect(() => {
    const fetchData = async () => {
      await getInfo(id);
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    if (Data) {
      filterData();
    }
  }, [Data]);

  return (
    <Layout>
      <Fragment>
        <HeaderAppBar subChild="Product detailed info">
          Product detailed info
        </HeaderAppBar>
        {loading ? (
          <Box display="flex" justifyContent={"center"}>
            <Loading />
          </Box>
        ) : (
          <>
            <Box padding=" 20px 30px">
              <Typography variant="h5" fontWeight={"900"}>
                Product Overview
              </Typography>
            </Box>
            {Data?.type === "NotCustomizable" && (
              <Box padding=" 10px 30px">
                <Typography variant="h6" fontWeight={"900"}>
                  <span style={{ color: "red" }}>Note:</span> The following
                  product is {Data?.type}
                </Typography>
              </Box>
            )}
            <Grid container display="flex" gap="20px">
              <Grid item md="6">
                <Grid
                  container
                  gap="20px"
                  display="flex"
                  justifyContent={"center"}
                  sx={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;",
                    padding: "20px",
                  }}
                >
                  {Data?.product_details?.map((imageFile, i) => {
                    return (
                      <Grid
                        key={i}
                        item
                        sm="3"
                        xs="5"
                        sx={{ cursor: "pointer" }}
                      >
                        <CardMedia
                          component="img"
                          height="100%"
                          image={imageFile.product_detail_images[0].image}
                          width="100%"
                          sx={{
                            objectFit: "cover",
                            alignSelf: "center",
                            justifySelf: "center",
                            boxShadow:
                              "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;",
                          }}
                          alt="green iguana"
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
              <Grid item md="5" sm="12">
                <Grid
                  container
                  // sm="12"
                  gap="10px"
                  flexDirection={"column"}
                  sx={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;",
                    padding: "20px",
                    //   height: "fit-content ",
                    fontWeight: "lighter",
                  }}
                >
                  <Typography variant="p">SKU:{Data?.sku}</Typography>
                  <Typography variant="h5" sx={{ color: colors.darkRed }}>
                    {" "}
                    <b>{Data?.product_name}</b>
                  </Typography>
                  <Box
                    sx={{ display: "flex", alignItems: "center" }}
                    gap="20px"
                  >
                    <Typography variant="p">
                      {" "}
                      <b>Price: {Data?.price}$</b>
                    </Typography>
                    {/* <Typography variant="p" marginLeft={"50px"}>
                      {" "}
                      <b>Quantity: {Data?.qty}</b>
                    </Typography> */}
                  </Box>

                  <Typography variant="p" sx={{ fontWeight: "100" }}>
                    {Data?.description}
                  </Typography>
                  <Box>
                    <Box display="flex">
                      <Typography sx={{ fontWeight: "900", mb: "10px" }}>
                        Avaialable Colors
                      </Typography>
                    </Box>

                    <Box display="flex">
                      {colorAndSizeData.map((colors, i) => (
                        <Box
                          key={i}
                          sx={{
                            border: "1px solid Gray",
                            width: "content-fit",
                            padding: "10px",
                            cursor: "pointer",
                          }}
                          onClick={() => handleSizeByColor(colors)}
                        >
                          <Box
                            backgroundColor={colors.color_code}
                            style={{
                              height: "20px",
                              width: "20px",
                              borderRadius: "50%",
                            }}
                          ></Box>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                  <Box>
                    <Box display="flex">
                      <Typography sx={{ fontWeight: "900", mb: "10px" }}>
                        Available Sizes
                      </Typography>
                    </Box>
                    <Box display="flex">
                      {filterSize.map((size, i) => (
                        <Box
                          key={i}
                          sx={{
                            border: "1px solid Gray",
                            width: "content-fit",
                            padding: "10px",
                          }}
                        >
                          <Box
                            style={{
                              height: "20px",
                              width: "fit-content",
                              borderRadius: "50%",
                              textAlign: "center",
                              display: "flex",
                            }}
                          >
                            {size.fittype_name}
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </Box>

                  <Box>
                    <Typography
                      variant="p"
                      display={"flex"}
                      alignItems="center"
                      gap="10px"
                    >
                      <LocalShippingIcon /> Shipping will be calculated at the
                      time of checkout!
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            {Data?.type === "Customizable" && (
              <>
                <Box padding=" 20px 30px">
                  <Typography variant="h5" fontWeight={"900"}>
                    Customise Pictures Overview
                  </Typography>
                </Box>

                <Grid container gap="20px" justifyContent={"start"}>
                  {Data.product_customises_images.map((CustomiseImage, i) => (
                    <Grid key={i} item xs="2">
                      <CardMedia
                        component="img"
                        height="200px"
                        image={CustomiseImage.image}
                        sx={{
                          objectFit: "contain",
                          alignSelf: "center",
                          justifySelf: "center",
                          boxShadow:
                            "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;",
                        }}
                        alt="green iguana"
                      />
                    </Grid>
                  ))}
                </Grid>
              </>
            )}
          </>
        )}
      </Fragment>
    </Layout>
  );
};

export default ViewProductsinfo;
