import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { CardMedia, Grid, Typography } from "@mui/material";
import { useState } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { ErrorToaster } from "../UI/Toaster";
import { baseUrl } from "../../../axios";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CropToolDialog(props) {
  const { open, Data, SubmitData } = props;
  const baseURL = baseUrl;
  // React Crop --------------------------------------->

  let [file, setFile] = useState();

  const [crop, setCrop] = useState({ aspect: 16 / 9 });
  const SubmitCrop = () => {
    if (crop.aspect === 1.7777777777777777) {
      ErrorToaster("kindly select the crop size");
    } else {
      console.log("Data=>", Data);
      const ImageData = Data.image.split(baseURL);

      let CropImageData = {
        name: Data.name,
        image: ImageData[1],
        height: (crop.height / 300) * 100,
        width: (crop.width / 300) * 100,
        left: (crop.x / 300) * 100,
        top: (crop.y / 300) * 100,
      };
      SubmitData(CropImageData);
      setCrop({ aspect: 16 / 9 });
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <Typography
            variant="h4"
            sx={{ textAlign: "center", fontWeight: "900" }}
          >
            Crop Design Size:
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Grid container gap="50px">
              <Grid item xs="12" display={"flex"} justifyContent="center">
                <Typography variant="p" textAlign={"center"}>
                  Select the size in which your design will upload
                </Typography>
              </Grid>

              <Grid item xs="12">
                <Grid container justifyContent={"center"}>
                  <ReactCrop
                    style={{}}
                    src={file}
                    // onImageLoaded={setImage}
                    crop={crop}
                    onChange={setCrop}
                  >
                    <CardMedia
                      component={"img"}
                      image={Data.image}
                      style={{
                        width: "300px",
                        height: "300px",
                        objectFit: "contain",
                      }}
                    />
                  </ReactCrop>
                </Grid>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent={"right"}>
            <Button
              sx={{ color: "green", padding: "10px" }}
              onClick={() => {
                SubmitCrop();
              }}
            >
              Submit
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}
