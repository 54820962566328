import { deleted, get, post } from "app/apis";
import SizesRoutes from './Sizes.Routes';

const SizesServices = {
    createSize: async (data) => {
        let result = await post(SizesRoutes.createSize, data,);
        if (result.status === 200) {
            return result.data;
        } else {
            throw result;
        }
    },
    getAllSizes: async (jwt) => {
        let result = await get(SizesRoutes.viewSize, jwt);
        if (result.status === 200) {
            return result.data;
        } else {
            throw result;
        }
    },
    deleteSizes: async (id, jwt) => {
        let result = await deleted(SizesRoutes.deleteSize + `${id}`, jwt)
        if (result.status === 200) return result.data
        else throw result
    },
    updateSize: async (id, data) => {
        let result = await post(SizesRoutes.updateSize + `${id}`, data)
        if (result.status === 200) return result.data
        else throw result
    },
};

export default SizesServices