import React, { useState } from "react";
import {
  Box,
  Container,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { ErrorToaster, SuccessToaster } from "app/components/UI/Toaster";

import colors from "app/style/Colors";
import useAuth from "app/hooks/useAuth";
import { emailRegex } from "app/utils/Index";

// *import components
import AuthServices from "app/apis/Auth/AuthServices";
import { LoadingBtn } from "app/components/UI/Buttons";
import { Error } from "app/components/UI/Error";
import Images from "app/assets/images";

function SignIn() {
  const { signIn } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = useState(false);

  // *For User SignIn
  const userSignIn = async (userData) => {
    setLoading(true);
    try {
      let obj = {
        email: userData.email,
        password: userData.password,
      };
      const { responseCode, data, status, message } = await AuthServices.login(
        obj
      );

      if (status === true && responseCode === 200) {
        SuccessToaster("Logged in");
        signIn(data);
      }
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container
      maxWidth="xl"
      sx={{ bgcolor: colors.lightGray, minHeight: "100vh", py: 2 }}
    >
      <Box sx={{ textAlign: "center" }}>
        <img src={Images.MainLogo} alt="" width="200px" />
      </Box>

      <Grid
        container
        spacing={0}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid
          item
          md={7}
          sx={{ textAlign: "center", display: { xs: "none", md: "block" } }}
        >
          <Box
            component="img"
            src={Images.LoginImg}
            height="500px"
            sx={{ objectFit: "contain" }}
          />
        </Grid>

        <Grid item xs={12} md={5}>
          <Box
            sx={{
              bgcolor: colors.white,
              p: 4,
              mt: 4,
              borderRadius: "10px",
              boxShadow:
                "rgb(159 162 191 / 18%) 0px 9px 16px, rgb(159 162 191 / 32%) 0px 2px 2px",
            }}
          >
            <Typography
              sx={{
                fontSize: "30px",
                fontWeight: "bold",
                my: 1,
                textAlign: "center",
              }}
            >
              Sign In
            </Typography>
            <Typography sx={{ textAlign: "center" }}>
              Fill in the fields below to sign into your account.
            </Typography>

            <Typography
              component="form"
              sx={{ my: 4 }}
              onSubmit={handleSubmit(userSignIn)}
            >
              <FormControl variant="standard" sx={{ width: "100%", my: 1.3 }}>
                <TextField
                  variant="outlined"
                  type="email"
                  label="Email Address"
                  error={errors?.email?.message && true}
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: emailRegex,
                      message: "Please enter a valid email address",
                    },
                  })}
                />
                {errors?.email?.message && (
                  <Error message={errors?.email?.message} />
                )}
              </FormControl>

              <FormControl variant="standard" sx={{ width: "100%", my: 1.3 }}>
                <TextField
                  variant="outlined"
                  type="password"
                  label="Password"
                  error={errors?.password?.message && true}
                  {...register("password", {
                    required: "Password is required",
                  })}
                />
                {errors?.password?.message && (
                  <Error message={errors?.password?.message} />
                )}
              </FormControl>

              {/* <TextField fullWidth label="Password"
                  type="password"
                  sx={{
                    my: 1.5, ".css-dvixrj-MuiInputBase-root-MuiOutlinedInput-root": {
                      borderRadius: "10px !important"
                    }
                  }} variant="outlined" /> */}

              <LoadingBtn
                fullWidth
                loading={loading}
                type="submit"
                variant="contained"
              >
                Sign In
              </LoadingBtn>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default SignIn;
