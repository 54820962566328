import React, { Fragment, useState } from "react";
import { Box, FormControl, Grid, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import colors from "app/style/Colors";
import useAuth from "app/hooks/useAuth";
// *import components
import { LoadingBtn, PrimaryButton } from "app/components/UI/Buttons";
import AppBar from "app/components/AppBar/AppBar";
import { Error } from "app/components/UI/Error";
import { ErrorToaster, SuccessToaster } from "app/components/UI/Toaster";
import { TextInput } from "app/components/UI/TextField";

import Layout from "app/layout/Layout.js";
import SizesServices from "app/apis/Sizes/SizesServices";
import { useNavigate } from "react-router-dom";




function AddSizes() {
  const navigate =  useNavigate()
  const { user } = useAuth();

  // *For Form Validation
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [loading, setLoading] = useState(false);

  const AddSizes = async (data) => {
    setLoading(true);
    try {
      const { responseCode, message } = await SizesServices.createSize(data);
      if (responseCode === 200) {
        SuccessToaster("Size created successfully!");
        navigate('/Productmanagement/viewallsize')
      }
    }
    catch (error) {
      ErrorToaster(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <Fragment>
        <AppBar subChild="Add Sizes">Add Sizes</AppBar>

        <Grid
          container
          spacing={2}
          sx={{
            mb: 2,
            p: 3,
            m: 3,
            width: "95%",
            display: "flex",
            flexDirection: "column",

            borderRadius: "10px",
            bgcolor: colors.white,
            boxShadow:
              "rgb(159 162 191 / 18%) 0px 9px 16px, rgb(159 162 191 / 32%) 0px 2px 2px",
          }}
        >
          <Typography
            component="form"
            onSubmit={handleSubmit(AddSizes)}
            sx={{
              width: "100%",
              p: 1,
              display: "flex",
              alignItems: "start",
              gap: "10px",
              flexDirection: "column",
            }}
          >
            <Grid item xs={6}>
              <TextField
                width="100%"
                variant="outlined"
                type="text"
                label="Enter Sizes"
                error={errors?.name?.message}
                helperText={errors?.name?.message}
                {...register("name", {
                  required: "Size is Required",
                })}
              />
            </Grid>

            <Grid item xs={12} sx={{ textAlign: "left", my: "auto" }}>
              <LoadingBtn loading={loading} type="submit" variant="contained">
                Add
              </LoadingBtn>
            </Grid>
          </Typography>
        </Grid>
      </Fragment>
    </Layout>
  );
}

export default AddSizes;
