import React, { Fragment, useState, useEffect } from "react";
import {
  Box,
  Grid,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import colors from "app/style/Colors";
import useAuth from "app/hooks/useAuth";
// *import components
import AppBar from "app/components/AppBar/AppBar";
import { Error } from "app/components/UI/Error";
import { ErrorToaster, SuccessToaster } from "app/components/UI/Toaster";
import { TextInput } from "app/components/UI/TextField";

import { LoadingBtn, PrimaryButton } from "app/components/UI/Buttons";
import Layout from "app/layout/Layout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ProductCategoryServices from "app/apis/ProductCategories/ProductCategoryServices.js";

import axios from "axios";
const useStyles = makeStyles({
  image: {
    borderRadius: 2,
    objectFit: "contain",
  },
});

function UpdateCategory() {
  let navigate = useNavigate();
  const { user } = useAuth();

  const classes = useStyles();

  let { state } = useLocation();
  console.log("🚀 state:", state);

  let { id } = useParams();

  // *For Form Validation
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm();

  const [selectedImage, setSelectedImage] = useState(state.image);
  const [uploadedImage, setUploadedImage] = useState(state.image);
  const [selectedCustomize, setSelectedCustomize] = useState(state.type);

  const [loading, setLoading] = useState(false);

  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(URL.createObjectURL(e.target.files[0]));
      setUploadedImage(e.target.files[0]);
    }
  };

  const handleChange = (event) => {
    setSelectedCustomize(event.target.value);
  };

  const UpdateCategory = async (data) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("name", data.categoryName);
    formData.append("type", selectedCustomize);
    try {
      let { responseCode } =
        await ProductCategoryServices.updateProductCategory(id, formData);
      if (responseCode === 200) {
        SuccessToaster("Category updated successfully");
        navigate("/Productmanagement/viewallcat");
      }
    } catch (error) {
      ErrorToaster(error);
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  const defaultData = () => {
    setValue("categoryName", state.name);
    setValue("categoryDescription", state.description);
  };

  useEffect(() => {
    defaultData();
  }, []);

  return (
    <Layout>
      <Fragment>
        <AppBar subChild="Update Categories">Update Category</AppBar>

        <Grid
          container
          spacing={2}
          sx={{
            mb: 2,
            p: 3,
            m: 3,
            width: "95%",
            borderRadius: "10px",
            bgcolor: colors.white,
            boxShadow:
              "rgb(159 162 191 / 18%) 0px 9px 16px, rgb(159 162 191 / 32%) 0px 2px 2px",
          }}
        >
          <Typography
            component="form"
            onSubmit={handleSubmit(UpdateCategory)}
            sx={{
              width: "100%",
              p: 1,
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            {/* <Grid item xs={4}>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                defaultValue={state.name}
                label="Category Name"
                error={errors?.name?.message}
                helperText={errors?.name?.message}
                {...register("name", {
                  required: "Category Name is required",
                })}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Category Description"
                defaultValue={state.description}
                error={errors?.description?.message}
                helperText={errors?.description?.message}
                {...register("description", {
                  required: "Category description is required",
                })}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography>Recommended Size: 500 x 500</Typography>
              <Box
                sx={{
                  border: `0.5px solid ${colors.gray}`,
                  // p: 0.5,
                  mb: 2,
                  display: "flex",
                  width: "fit-content",
                }}
              >
                {selectedImage ? (
                  <img
                    src={selectedImage}
                    alt=""
                    width="auto"
                    height="200px"
                    className={classes.image}
                  />
                ) : (
                  <Box sx={{ height: "auto" }}></Box>
                )}
              </Box>
              <PrimaryButton variant="contained" component="label">
                Update Image
                <input
                  accept="image/x-png,image/gif,image/jpeg"
                  type="file"
                  hidden={true}
                  error={errors?.selectedImage?.message && true}
                  {...register("selectedImage", {
                    required: "Image is required",
                  })}
                  onChange={imageChange}
                  sx={{ display: "none" }}
                />
              </PrimaryButton>
              {errors?.selectedImage?.message && (
                <Error message={errors?.selectedImage?.message} />
              )}
            </Grid>

            <Grid item xs={12} sx={{ textAlign: "right" }}>
              <LoadingBtn loading={loading} type="submit" variant="contained">
                Update
              </LoadingBtn>
            </Grid> */}

            <Grid
              container
              spacing={2}
              // gap="10px"
              sx={{
                mb: 2,
                p: 3,
                m: 3,
                width: "95%",
                borderRadius: "10px",
                bgcolor: colors.white,
                // boxShadow: "rgb(159 162 191 / 18%) 0px 9px 16px, rgb(159 162 191 / 32%) 0px 2px 2px",
                // display: "flex",
                // justifyContent: "center",
              }}
            >
              <Grid item md={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Category Name"
                  // sx={{ width: "400px" }}
                  error={errors?.categoryName?.message}
                  helperText={errors?.categoryName?.message}
                  {...register("categoryName", {
                    required: "Please enter category Name",
                  })}
                />
              </Grid>
              {/* <Grid item md={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Sub-category Name"
                  // sx={{ width: "400px" }}
                  error={errors?.subCategoryName?.message}
                  helperText={errors?.subCategoryName?.message}
                  {...register("subCategoryName")} //, {required: "Please enter sub-category Name",}
                />
              </Grid> */}
              {/* <Grid item md={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Category Description"
                  // sx={{ width: "400px" }}
                  error={errors?.categoryDescription?.message}
                  helperText={errors?.categoryDescription?.message}
                  {...register("categoryDescription", {
                    required: "Please enter category Description",
                  })}
                />
              </Grid> */}
              <Grid item md={6}>
                <FormControl
                  fullWidth
                  error={errors?.categoryCustomization?.message}
                >
                  <InputLabel id="demo-simple-select-label">
                    Select Customization
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedCustomize}
                    label="Select Customization"
                    {...register("categoryCustomization", {
                      required: "Please choose customizable",
                    })}
                    onChange={handleChange}
                  >
                    <MenuItem value={"Customizable"}>{"Customizable"}</MenuItem>
                    <MenuItem value={"NotCustomizable"}>
                      {"NotCustomizable"}
                    </MenuItem>
                  </Select>
                  <FormHelperText>
                    {errors?.categoryCustomization?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
              {/* <Grid item md={6}>
                <Box>
                  <Typography>Recommended Size: 500 x 500</Typography>
                  <Box
                    sx={{
                      border: `0.5px solid ${colors.gray}`,
                      // p: 0.5,
                      mb: 2,
                      display: "flex",
                      width: "fit-content",
                    }}
                  >
                    {selectedImage ? (
                      <img
                        src={selectedImage}
                        alt=""
                        width="auto"
                        height="200px"
                        className={classes.image}
                      />
                    ) : (
                      <Box sx={{ height: "auto" }}></Box>
                    )}
                  </Box>
                  <PrimaryButton variant="contained" component="label">
                    Upload Image
                    <input
                      accept={"image/x-png,image/gif,image/jpeg, image/jpg"}
                      type={"file"}
                      hidden={true}
                      error={errors?.selectedImage?.message}
                      {...register("selectedImage", {
                        required:
                          selectedImage === "" ? "Image is required" : false,
                      })}
                      onChange={imageChange}
                      sx={{ display: "none" }}
                    />
                  </PrimaryButton>
                  {errors?.selectedImage?.message && (
                    <Error message={errors?.selectedImage?.message} />
                  )}
                </Box>
              </Grid> */}
              <Grid item xs={12} sx={{ textAlign: "right" }}>
                <LoadingBtn loading={loading} type="submit" variant="contained">
                  Submit
                </LoadingBtn>
              </Grid>
            </Grid>
          </Typography>
        </Grid>
      </Fragment>
    </Layout>
  );
}

export default UpdateCategory;
