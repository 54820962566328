import { ResetTvOutlined } from "@mui/icons-material";
import { ErrorToaster } from "app/components/UI/Toaster.js";
import { get, post, deleted } from "../index.js";
import { CreateProductRoutes } from "./CreateProduct.Routes";

export const CreateProductServices = {
  createProduct: async (Data) => {
    let result = await post(CreateProductRoutes.createProduct, Data);
    if (result.status === 200) {
      return result.data;
    } else throw result;
  },
  getAllProduct: async (PgNo) => {
    let result = await get(
      CreateProductRoutes.getAllProduct + `?limit=10&page=${PgNo}`
    );
    if (result.status === 200) return result.data;
    else throw result;
  },
  deleteProductById: async (id) => {
    let result = await deleted(CreateProductRoutes.deleteProduct + id);
    if (result.status === 200) {
      return result.data;
    } else {
      throw result;
    }
  },
  getProductDetailDescription: async (id) => {
    let result = await get(CreateProductRoutes.getDetailedProductinfo + id);
    if (result.status === 200) return result.data;
    else throw result;
  },
  updateProductById: async (id, data) => {
    const result = await post(CreateProductRoutes.updateProduct + id, data);
    if (result.status === 200) {
      return result.data;
    } else {
      throw result;
    }
  },
  getProducDetailtById: async (id) => {
    const result = await get(CreateProductRoutes.getProductById + id);
    if (result.status === 200) return result.data;
    else throw result;
  },
};
