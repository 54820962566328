// *Import components
import AddCategory from "app/views/Category/AddCategory";
import ViewCategory from "app/views/Category/ViewCategory";
import ViewProducts from "app/views/Products/ViewProducts";
import AddProducts from "app/views/Products/AddProducts";
import DashboardBox from "app/components/DashboardBox/DashboardBox";
import Inventory from "app/views/Orders/Orders";
import Orders from "app/views/Orders/Orders";
import Customers from "app/views/CustomerManagement/ViewAllCustomer";
import Addcolors from "app/views/ManageColors/Addcolors";
import ViewAllcolors from "app/views/ManageColors/ViewAllcolors";
import AddSizes from "app/views/Sizes/AddSizes";
import ViewAllSizes from "app/views/Sizes/ViewSizes";
import CreateUser from "app/views/CustomerManagement/CreateUser";
import ViewAllCustomer from "app/views/CustomerManagement/ViewAllCustomer";
import UpdateColor from "app/views/ManageColors/EditColor";
import UpdateCategory from "app/views/Category/Updatecategory";
import UpdateSizes from "app/views/Sizes/UpdateSizes";
import ManageCustomisation from "app/views/ToolManagement/CreateDesign";
import ViewProductsinfo from "app/views/Products/ProductInfo";
import ViewAllDesign from "app/views/ToolManagement/ViewAllDesign";
import ViewProductById from "app/views/ToolManagement/ViewProductById";
import ViewToolById from "app/views/ToolManagement/ViewToolById";
import EditUser from "app/views/CustomerManagement/EditCustomer";
import EditDesign from "app/views/ToolManagement/EditDesign";
import EditProduct from "app/views/Products/EditProduct/EditProduct";
import ViewCustomDesign from "app/views/Orders/ViewCustomDesign";
import Quotations from "app/views/Quotations/QuotationsOrder";
import ViewQuoatationDesign from "app/views/Quotations/ViewQuotationDesign";
import ChangePassword from "app/views/ChangePassword/changePassword";
import Name from "app/views/ToolManagement/Name";

export const PrivateRoutes = [
  {
    path: "/",
    component: <DashboardBox />,
  },
  {
    path: "/inventory",
    component: <Inventory />,
  },
  {
    path: "/viewordermanagement",
    component: <Orders />,
  },
  {
    path: "/viewcustomisedesign/:id",
    component: <ViewCustomDesign />,
  },
  {
    path: "/customers",
    component: <Customers />,
  },
  //   Products Management----->
  {
    path: "/Productmanagement/addcat",
    component: <AddCategory />,
  },
  {
    path: "/Productmanagement/viewallcat",
    component: <ViewCategory />,
  },

  {
    path: "/Productmanagement/updatecat/:id",
    component: <UpdateCategory />,
  },

  // Product Management Product Section================>
  {
    path: "/Productmanagement/viewallproduct",
    component: <ViewProducts />,
  },
  {
    path: "/Productmanagement/addproduct",
    component: <AddProducts />,
  },
  {
    path: "/Productmanagement/editproduct/:id",
    component: <EditProduct />,
  },
  {
    path: "/products/view",
    component: <ViewProducts />,
  },
  {
    path: "/products/productInfo/:id",
    component: <ViewProductsinfo />,
  },
  // Color Management--->
  {
    path: "/add-color",
    component: <Addcolors />,
  },
  {
    path: "/view-color",
    component: <ViewAllcolors />,
  },
  {
    path: "/Productmanagement/updateColor/:id",
    component: <UpdateColor />,
  },
  // manage Sizes-->
  {
    path: "/Productmanagement/addsize",
    component: <AddSizes />,
  },
  {
    path: "/Productmanagement/viewallsize",
    component: <ViewAllSizes />,
  },
  {
    path: "/Productmanagement/updatesize/:id",
    component: <UpdateSizes />,
  },
  // Tool Management-->
  {
    path: "/Toolmanagement/managecustomisation",
    component: <ManageCustomisation />,
  },
  {
    path: "/Toolmanagement/viewall",
    component: <ViewAllDesign />,
  },
  {
    path: "/Toolmanagement/viewtoolbyid/:id",
    component: <ViewToolById />,
  },
  {
    path: "/Toolmanagement/editdesign/:id",
    component: <EditDesign />,
  },
  {
    path: "/Toolmanagement/name",
    component: <Name />,
  },

  // Customer Management-->
  {
    path: "/usermanagement/add",
    component: <CreateUser />,
  },
  {
    path: "/usermanagement/viewall",
    component: <ViewAllCustomer />,
  },
  {
    path: "/usermanagement/edit/:id",
    component: <EditUser />,
  },
  {
    path: "/quotedorders",
    component: <Quotations />,
  },
  {
    path: "viewquotationorder/:id",
    component: <ViewQuoatationDesign />,
  },
  {
    path: "/change-password",
    component: <ChangePassword />,
  },
];
