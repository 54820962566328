import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Button,
  CardMedia,
  IconButton,
  Pagination,
  PaginationItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit';

import { DeleteTwoTone } from "@mui/icons-material";
import useAuth from "app/hooks/useAuth";

import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import MoreVertIcon from "@mui/icons-material/MoreVert";

// *import components
import AppBar from "app/components/AppBar/AppBar";
import colors from "app/style/Colors";
import Layout from "app/layout/Layout";
import { Loading } from "app/assets/images";
import { CreateProductServices } from "app/apis/CreateProduct/CreateProductServices";
import { ErrorToaster, SuccessToaster } from "app/components/UI/Toaster";
import { ToolManagementServices } from "app/apis/ToolManagement/ToolManagement.Services";

function ViewToolById() {
  const [data, SetData] = useState([]);
  const [paging, setpaging] = useState(1);

  const navigate = useNavigate();
  const [page, setPage] = React.useState(1);
  const [count, setCount] = useState(0);

  // pagination Handle
  const handleChange = (event, value) => {
    getProduct(value);
    setPage(value);
  };

  const [Hide, setHide] = useState(false);
  const [loading, setLoading] = useState(false);

  // *For Get Product List

  const deleteProduct = async (Id) => {
    try {
      Swal.fire({
        title: `"Are you sure you want to delete"`,
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: `${colors.primary}`,
        cancelButtonColor: `${colors.darkRed}`,
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          <></>
          // try {
          //   const result = await CreateProductServices.deleteProductById(Id);
          //   if (result.responseCode === 200) {
          //     SuccessToaster("Product Deleted Successfully");
          //     getProduct(page);
          //   }
          // } catch (e) {
          //   ErrorToaster(e);
          // } finally {
          // }
        } else {
          <></>;
        }
      });
    } catch (error) {}
  };

  const getProduct = async (PgNo, option) => {
    setLoading(true);
    try {
      let result = await ToolManagementServices.getAllCustomiseProducts();
      if (result.responseCode === 200) {
        // For Count
        // const TotalProduct = Math.ceil(result.count / 10);
        // setCount(TotalProduct);

        SetData(result.data);
      } else {
        ErrorToaster("Oops and error occur");
      }
    } catch (e) {
      ErrorToaster(e);
    } finally {
      setLoading(false);
      window.scrollTo({ top: "0px" });
    }
  };

  useEffect(() => {
    getProduct(page);

    window.scrollTo({ top: "0px" });
  }, [URL]);

  return (
    <Layout>
      <Fragment>
        <AppBar subChild="Manage Customisation">View Tool Products</AppBar>
        {loading ? (
          <Box sx={{ width: 1, textAlign: "center", pt: 5 }}>
            <Loading />
          </Box>
        ) : (
          <Box sx={{ mb: 2, p: 3 }}>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }}>S.No</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Sku number
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Image
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Title
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
Size
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
Colors
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Price
                    </TableCell>

                    <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((item, index) => (
                    <TableRow
                      key={item.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell sx={{ py: 1.5 }}>{index + 1}</TableCell>
                      <TableCell sx={{ py: 1.5 }}>
                        {item.category_name}
                      </TableCell>
                      <TableCell sx={{ py: 1.5 }}>
                        {item.category_name}
                      </TableCell>
                      <TableCell sx={{ py: 1.5 }}>
                        {item.category_name}
                      </TableCell>
                      <TableCell sx={{ py: 1.5 }}>
                      10x30
                      </TableCell>
                      <TableCell sx={{ py: 1.5 }}>
                      <Box sx={{height:"20px",width:"20px",background:"orange",borderRadius:"50%"}}></Box>
                      </TableCell>
                      <TableCell sx={{ py: 1.5 }}>
                        <Box display="flex" gap="10px">
                          <Typography variant="p">
                           100$
                          </Typography>
                        </Box>
                      </TableCell>

                      <TableCell sx={{ py: 0, textAlign: "center" }}>
                        <Tooltip title="Product Info" onClick={()=>{navigate('/Toolmanagement/viewproductbyid/5')}}>
                          <IconButton

                          >
                            <RemoveRedEyeIcon
                              sx={{ fontSize: "20px" }}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Edit Product" onClick={()=>{navigate('/Toolmanagement/viewproductbyid/5')}}>
                          <IconButton

                          >
                            <EditIcon
                              sx={{ fontSize: "20px" }}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete Product">

                          <IconButton onClick={() => deleteProduct(item.id)}>
                            <DeleteTwoTone
                              sx={{ color: colors.darkRed, fontSize: "18px" }}
                            />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {/* For Pagination  */}
            {/* <Box
              display={"flex"}
              justifyContent="center"
              sx={{ padding: "20px" }}
            > */}

            {/* <Stack
                spacing={2}
                sx={{
                  fontSize: "30px",
                  ".MuiPaginationItem-root": {
                    fontSize: "20px",
                    fontWeight: "900",
                  },
                  ".css-txir55-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected":
                    { fontSize: "25px" },
                }}
              >
                <Pagination count={count} page={page} onChange={handleChange} />
              </Stack> */}
            {/* </Box> */}
          </Box>
        )}
      </Fragment>
    </Layout>
  );
}

export default ViewToolById;
