import axios from "axios";

// export const server = "https://phplaravel-1079200-3775334.cloudwaysapps.com/api";
export const baseUrl = "https://api-designtool.baitalkhairkitchen.com";
export const server = "https://api-designtool.baitalkhairkitchen.com/api";

const instance = axios.create({
  baseURL: server,
});

instance.interceptors.request.use((request) => {
  let token = localStorage.getItem("jwt");

  request.headers = {
    Accept: "application/json, text/plain, */*",
    Authorization: `Bearer ${token}`,
  };
  return request;
});

instance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export default instance;
