import React from "react";
import { styled } from "@mui/material/styles";
import {
  Dialog,
  Box,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TableCell,
  TableBody,
  TableRow,
  TableContainer,
  Table,
  TableHead,
  Typography,
  DialogContentText,
  Avatar,
  CardMedia,
} from "@mui/material";
import colors from "app/style/Colors";
import { Paper } from "@mui/material";
import { Fragment } from "react";
import { Loading } from "app/assets/images";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link, useNavigate } from "react-router-dom";

const OrderDialogBox = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function OrderDetailDialog({ open, setOpen, data, info, loading }) {
  console.log("🚀 info", info);
  console.log("🚀 data", data);

  return (
    <Box>
      <OrderDialogBox
        aria-labelledby="customized-dialog-title"
        open={open}
        onClose={setOpen}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Order Detail</DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", color: colors.primary }}
              >
                OrderId
              </Typography>
              <Typography variant="body1" sx={{ color: colors.primary }}>
                {info?.order_no}
              </Typography>
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", color: colors.primary }}
              >
                Order Date
              </Typography>
              <Typography variant="body1" sx={{ color: colors.primary }}>
                {info.order_date}
              </Typography>
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", color: colors.primary }}
              >
                Billing Email
              </Typography>
              <Typography variant="body1" sx={{ color: colors.primary }}>
                {info.billing_email}
              </Typography>
            </Box>
            <Box
              sx={{
                mt: 2,
                py: 1,
                borderTop: `1px solid ${colors.primary}`,
                borderBottom: `1px solid ${colors.primary}`,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {loading ? (
                  <Box sx={{ width: 1, textAlign: "center" }}>
                    <Loading />
                  </Box>
                ) : (
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ textAlign: "center" }}>
                            Image
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>Sku</TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Type
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Title
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Color
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Size
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Quantity
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Price
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              <CardMedia
                                image={item.product_images[0].image}
                                alt="Remy Sharp"
                                sx={{
                                  width: "30px",
                                  height: "30px",
                                  objectFit: "cover",
                                }}
                              />
                            </TableCell>
                            <TableCell>{item.product_sku}</TableCell>
                            <TableCell>{item.product_type}</TableCell>
                            <TableCell>{item.product_name}</TableCell>
                            <TableCell>{item.color}</TableCell>
                            <TableCell>{item.size}</TableCell>

                            <TableCell>{item.quantity}</TableCell>
                            <TableCell>{item.price}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Box>
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", color: colors.primary }}
              >
                Total Quantity
              </Typography>
              <Typography variant="body1" sx={{ color: colors.primary }}>
                {info.total_quantity}
              </Typography>
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", color: colors.primary }}
              >
                Total Amount
              </Typography>
              <Typography variant="body1" sx={{ color: colors.primary }}>
                {info.total_amount}
              </Typography>
            </Box>
            {data[0]?.extradetail?.length !== 0 && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "20px 0px",
                }}
              >
                <Button
                  component={Link}
                  to={`/viewcustomisedesign/${data[0]?.id}`}
                  state={{ data: data[0] }}
                  sx={{
                    background: "#2D323E",
                    color: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "2px solid #2D323E",
                    gap: "10px",
                    ":hover": {
                      color: colors.darkRed,
                      border: "2px solid #b71431",
                    },
                  }}
                >
                  <VisibilityIcon sx={{ color: colors.darkRed }} /> View User
                  Custom Design
                </Button>
              </Box>
            )}
          </DialogContentText>
        </DialogContent>
      </OrderDialogBox>
    </Box>
  );
}

export default OrderDetailDialog;
