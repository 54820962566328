import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import colors from "app/style/Colors";
import {
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import OrdersServices from "app/apis/Orders/Orders.Services";
import { ErrorToaster, SuccessToaster } from "../UI/Toaster";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditOrderStatus(props) {
  const { open, close, getOrders } = props;
  const [Status, setStatus] = useState(open?.Data?.status);
  const [Loading, setLoading] = useState(false);

  //   const [open, setOpen] = React.useState(false);

  const changeStatus = async () => {
    if (Status) {
      setLoading(true);
      try {
        const form = new FormData();
        form.append("status", Status);
        const result = await OrdersServices.changeOrderStatus(
          open?.Data?.id,
          form
        );
        if (result.responseCode === 200) {
          SuccessToaster("Status Updated");
          getOrders();
        }
      } catch (e) {
        ErrorToaster(e);
      } finally {
        setLoading(false);
        close();
      }
    } else {
      ErrorToaster("Please Select the status first");
    }
  };

  return (
    <div>
      <Dialog
        open={open.status}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => close()}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          sx={{ background: colors.darkRed, color: "white", fontSize: "20px" }}
        >
          {"Edit Order Status"}
        </DialogTitle>
        <DialogContent sx={{ mt: "20px", width: "500px" }}>
          <DialogContentText id="alert-dialog-slide-description">
            <FormControl fullWidth>
              <FormLabel id="demo-radio-buttons-group-label">
                <Typography
                  variant="h5"
                  color="black"
                  mb="5px"
                  fontWeight={"900"}
                >
                  Status
                </Typography>
                <Divider />
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                onChange={(event) => {
                  setStatus(event.target.value);
                }}
              >
                <FormControlLabel
                  value="Pending"
                  control={<Radio />}
                  label="Pending"
                />
                <FormControlLabel
                  value="Shipped"
                  control={<Radio />}
                  label="Shipped"
                />
                <FormControlLabel
                  value="Delivered"
                  control={<Radio />}
                  label="Delivered"
                />
              </RadioGroup>
            </FormControl>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={Loading}
            sx={{
              background: "#2d323e",
              color: "white",
              border: "1px solid #2d323e",
              ":hover": {
                background: "white",
                color: "#2d323e",
                border: "1px solid #2d323e",
              },
            }}
            onClick={() => {
              // close();
              changeStatus();
            }}
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
