import { Box, Button, CardMedia, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import CropToolDialog from "app/components/DialogBox/CropToolDialog";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import colors from "app/style/Colors";
import Images from "app/assets/images";
import UploadImageServices from "app/apis/UploadImage/UploadImage";
import { ErrorToaster, SuccessToaster } from "app/components/UI/Toaster";
import { imageURL } from "app/utils/Index";
import { useEffect } from "react";
import { baseUrl } from "../../../../axios";

function StepADDpictures(props) {
  let dummyImage = Images.UploadBGRemove;
  const [frontImage, setfrontImage] = useState(dummyImage);
  const [backImage, setbackImage] = useState(dummyImage);
  const [leftImage, setLeftImage] = useState(dummyImage);
  const [rightImage, setrightImage] = useState(dummyImage);
  const [additionalSide1, setAdditionalSide1] = useState(dummyImage);
  const [additionalSide2, setAdditionSide2] = useState(dummyImage);
  const [CropImage, setCropImage] = useState({});
  const [frontImageCrop, setFrontImageCrop] = useState({});
  const [backImageCrop, setBackImageCrop] = useState({});
  const [leftImageCrop, setLeftImageCrop] = useState({});
  const [rightImageCrop, setRightImageCrop] = useState({});
  const [additionalImageCrop, setadditionalImageCrop] = useState({});
  const [additionalImage2Crop, setadditional2ImageCrop] = useState({});
  const baseURL = baseUrl;

  // --------------------------> Crop Tool Management -------------------------------->

  const { handleStep, data, setCustomisePicturesData, customisePictureData } =
    props;

  const [openCropTool, setopenCropTool] = useState(false);

  // Handling Default Data
  const handlingDefaultData = () => {
    customisePictureData?.map((e) => {
      if (e.name === "front") {
        setfrontImage(baseURL + e.image);
      }
      if (e.name === "left") {
        setLeftImage(baseURL + e.image);
      }
      if (e.name === "right") {
        setbackImage(baseURL + e.image);
      }
      if (e.name === "back") {
        setbackImage(baseURL + e.image);
      }
      if (e.name === "additionalSide1") {
        setAdditionalSide1(baseURL + e.image);
      }
      if (e.name === "additionalSide2") {
        setAdditionSide2(baseURL + e.image);
      }
    });
  };
  // =====================Upload Image
  const UploadImage = async (ImageData, Side) => {
    let img = new Image();
    img.src = window.URL.createObjectURL(ImageData.target.files[0]);

    img.onload = async () => {
      if (img.width === 400 && img.height === 500) {
        let formData = new FormData();
        formData.append("image", ImageData.target.files[0]);
        try {
          SuccessToaster("Image is uploading please wait!");

          const result = await UploadImageServices.uploadImage(formData);
          const ImageUrl = imageURL(result.data);
          if (result.responseCode === 200) {
            if (Side === "front") {
              setfrontImage(ImageUrl);
            }
            if (Side === "back") {
              setbackImage(ImageUrl);
            }
            if (Side === "left") {
              setLeftImage(ImageUrl);
            }

            if (Side === "right") {
              setrightImage(ImageUrl);
            }
            if (Side === "additionalSide1") {
              setAdditionalSide1(ImageUrl);
            }
            if (Side === "additionalSide2") {
              setAdditionSide2(ImageUrl);
            }
          }
          HandleCropToolModel(ImageUrl, Side);
        } catch (e) {
          ErrorToaster(e);
        } finally {
        }

        // upload logic here
      } else {
        ErrorToaster(` Kindly upload Image of size 400 * 500`);
      }
    };
  };

  // Handle Crop Tool Model:

  const HandleCropToolModel = (Image, Side) => {
    if (Image === dummyImage) {
      ErrorToaster("please select an image first!");
    } else {
      setCropImage({ name: Side, image: Image });
      setopenCropTool(!openCropTool);
    }
  };

  // Submit data of Crop
  const SubmitDataOfCrop = (Data) => {
    if (Data.name === "front") {
      setFrontImageCrop(Data);
      setCustomisePicturesData(Data);
    }
    if (Data.name === "back") {
      setBackImageCrop(Data);
      setCustomisePicturesData(Data);
    }
    if (Data.name === "left") {
      setLeftImageCrop(Data);
      setCustomisePicturesData(Data);
    }
    if (Data.name === "right") {
      setRightImageCrop(Data);
      setCustomisePicturesData(Data);
    }
    if (Data.name === "additionalSide1") {
      setadditionalImageCrop(Data);
      setCustomisePicturesData(Data);
    }
    if (Data.name === "additionalSide2") {
      setadditional2ImageCrop(Data);
      setCustomisePicturesData(Data);
    }

    // close Dialog box
    setopenCropTool(!openCropTool);
  };

  const HandleButton = () => {
    if (customisePictureData.length !== 0) {
      handleStep("next", 1);
    } else {
      ErrorToaster("Kindly upload atleast 1 customise image with crop! ");
    }
  };
  useEffect(() => {
    handlingDefaultData();
  }, [URL]);

  return (
    <Box width="100%">
      <Typography
        variant="h4"
        fullWidth
        fontWeight={"900"}
        display="flex"
        alignItems={"start"}
      >
        Add Customised Images
      </Typography>

      <Grid container justifyContent={"center"} mt="50px" gap={"30px"}>
        {/* front Image */}
        <Grid item xs="3">
          <Grid
            container
            justifyContent={"center"}
            flexDirection="column"
            alignItems={"center"}
          >
            {/* Images Section -------------------------------> */}
            <CardMedia
              component="img"
              height="150px"
              width="150px"
              image={frontImage}
              title="front Image"
              sx={{
                objectFit: "contain",
                marginBottom: "30px",
                cursor: "pointer",
              }}
            />
            <Box display={"flex"} gap="10px" alignItems={"center"}>
              <Button
                sx={{
                  color: colors.darkRed,
                  border: `1px solid ${colors.darkRed}`,
                }}
                onClick={() => {
                  HandleCropToolModel(frontImage, "front");
                }}
              >
                Crop Image
              </Button>
              <span
                class="btn btn-primary btn-file"
                style={{
                  position: "relative",
                  overflow: "hidden",
                  background: "#b71431",
                  color: "white",
                  height: "50px",
                  width: "fit-content",
                  padding: "15px",
                  borderRadius: "5px",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <CloudUploadIcon /> Upload
                <input
                  // accept=""
                  style={{
                    position: " absolute",
                    top: "0",
                    right: "0",
                    minWidth: "100%",
                    mineight: "100%",
                    fontSize: " 100px",
                    align: "right",
                    filter: "alpha(opacity=0)",
                    opacity: "0",
                    outline: "none",
                    background: "white",
                    cursor: "inherit",
                    display: "block",
                  }}
                  type="file"
                  onChange={(info) => {
                    UploadImage(info, "front");
                  }}
                ></input>
              </span>
            </Box>
          </Grid>
        </Grid>
        {/* backside Image */}
        <Grid item xs="3">
          <Grid
            container
            justifyContent={"center"}
            flexDirection="column"
            alignItems={"center"}
          >
            {/* Images Section -------------------------------> */}
            <CardMedia
              component="img"
              height="150px"
              width="150px"
              image={backImage}
              title="front Image"
              sx={{
                objectFit: "contain",
                marginBottom: "30px",
                cursor: "pointer",
              }}
            />
            <Box display={"flex"} gap="10px" alignItems={"center"}>
              <Button
                sx={{
                  color: colors.darkRed,
                  border: `1px solid ${colors.darkRed}`,
                }}
                onClick={() => {
                  HandleCropToolModel(backImage, "back");
                }}
              >
                Crop Image
              </Button>
              <span
                class="btn btn-primary btn-file"
                style={{
                  position: "relative",
                  overflow: "hidden",
                  background: "#b71431",
                  color: "white",
                  height: "50px",
                  width: "fit-content",
                  padding: "15px",
                  borderRadius: "5px",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <CloudUploadIcon /> Upload
                <input
                  // accept=""
                  style={{
                    position: " absolute",
                    top: "0",
                    right: "0",
                    minWidth: "100%",
                    mineight: "100%",
                    fontSize: " 100px",
                    align: "right",
                    filter: "alpha(opacity=0)",
                    opacity: "0",
                    outline: "none",
                    background: "white",
                    cursor: "inherit",
                    display: "block",
                  }}
                  type="file"
                  onChange={(info) => {
                    UploadImage(info, "back");
                  }}
                ></input>
              </span>
            </Box>
          </Grid>
        </Grid>
        {/* Left Image */}
        <Grid item xs="3">
          <Grid
            container
            justifyContent={"center"}
            flexDirection="column"
            alignItems={"center"}
          >
            {/* Images Section -------------------------------> */}
            <CardMedia
              component="img"
              height="150px"
              width="150px"
              image={leftImage}
              title="front Image"
              sx={{
                objectFit: "contain",
                marginBottom: "30px",
                cursor: "pointer",
              }}
            />
            <Box display={"flex"} gap="10px" alignItems={"center"}>
              <Button
                sx={{
                  color: colors.darkRed,
                  border: `1px solid ${colors.darkRed}`,
                }}
                onClick={() => {
                  HandleCropToolModel(leftImage, "left");
                }}
              >
                Crop Image
              </Button>
              <span
                class="btn btn-primary btn-file"
                style={{
                  position: "relative",
                  overflow: "hidden",
                  background: "#b71431",
                  color: "white",
                  height: "50px",
                  width: "fit-content",
                  padding: "15px",
                  borderRadius: "5px",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <CloudUploadIcon /> Upload
                <input
                  // accept=""
                  style={{
                    position: " absolute",
                    top: "0",
                    right: "0",
                    minWidth: "100%",
                    mineight: "100%",
                    fontSize: " 100px",
                    align: "right",
                    filter: "alpha(opacity=0)",
                    opacity: "0",
                    outline: "none",
                    background: "white",
                    cursor: "inherit",
                    display: "block",
                  }}
                  type="file"
                  onChange={(info) => {
                    UploadImage(info, "left");
                  }}
                ></input>
              </span>
            </Box>
          </Grid>
        </Grid>
        {/* right Image */}
        <Grid item xs="3">
          <Grid
            container
            justifyContent={"center"}
            flexDirection="column"
            alignItems={"center"}
          >
            {/* Images Section -------------------------------> */}
            <CardMedia
              component="img"
              height="150px"
              width="150px"
              image={rightImage}
              title="front Image"
              sx={{
                objectFit: "contain",
                marginBottom: "30px",
                cursor: "pointer",
              }}
            />
            <Box display={"flex"} gap="10px" alignItems={"center"}>
              <Button
                sx={{
                  color: colors.darkRed,
                  border: `1px solid ${colors.darkRed}`,
                }}
                onClick={() => {
                  HandleCropToolModel(rightImage, "right");
                }}
              >
                Crop Image
              </Button>
              <span
                class="btn btn-primary btn-file"
                style={{
                  position: "relative",
                  overflow: "hidden",
                  background: "#b71431",
                  color: "white",
                  height: "50px",
                  width: "fit-content",
                  padding: "15px",
                  borderRadius: "5px",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <CloudUploadIcon /> Upload
                <input
                  // accept=""
                  style={{
                    position: " absolute",
                    top: "0",
                    right: "0",
                    minWidth: "100%",
                    minHeight: "100%",
                    fontSize: " 100px",
                    align: "right",
                    // filter: "alpha(opacity=0)",
                    opacity: "0",
                    outline: "none",
                    background: "white",
                    cursor: "inherit",
                    display: "block",
                  }}
                  type="file"
                  onChange={(info) => {
                    UploadImage(info, "right");
                  }}
                ></input>
              </span>
            </Box>
          </Grid>
        </Grid>
        {/* Addition Side 2 Image */}
        <Grid item xs="3">
          <Grid
            container
            justifyContent={"center"}
            flexDirection="column"
            alignItems={"center"}
          >
            {/* Images Section -------------------------------> */}
            <CardMedia
              component="img"
              height="150px"
              width="150px"
              image={additionalSide2}
              title="AdditionSide2 Image"
              sx={{
                objectFit: "contain",
                marginBottom: "30px",
                cursor: "pointer",
              }}
            />
            <Box display={"flex"} gap="10px" alignItems={"center"}>
              <Button
                sx={{
                  color: colors.darkRed,
                  border: `1px solid ${colors.darkRed}`,
                }}
                onClick={() => {
                  HandleCropToolModel(additionalSide2, "additionalSide2");
                }}
              >
                Crop Image
              </Button>
              <span
                class="btn btn-primary btn-file"
                style={{
                  position: "relative",
                  overflow: "hidden",
                  background: "#b71431",
                  color: "white",
                  height: "50px",
                  width: "fit-content",
                  padding: "15px",
                  borderRadius: "5px",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <CloudUploadIcon /> Upload
                <input
                  // accept=""
                  style={{
                    position: " absolute",
                    top: "0",
                    right: "0",
                    minWidth: "100%",
                    minHeight: "100%",
                    fontSize: " 100px",
                    align: "right",
                    // filter: "alpha(opacity=0)",
                    opacity: "0",
                    outline: "none",
                    background: "white",
                    cursor: "inherit",
                    display: "block",
                  }}
                  type="file"
                  onChange={(info) => {
                    UploadImage(info, "additionalSide2");
                  }}
                ></input>
              </span>
            </Box>
          </Grid>
        </Grid>

        {/* Addition Side 1 Image */}
        <Grid item xs="3">
          <Grid
            container
            justifyContent={"center"}
            flexDirection="column"
            alignItems={"center"}
          >
            {/* Images Section -------------------------------> */}
            <CardMedia
              component="img"
              height="150px"
              width="150px"
              image={additionalSide1}
              title="additionalSide1"
              sx={{
                objectFit: "contain",
                marginBottom: "30px",
                cursor: "pointer",
              }}
            />
            <Box display={"flex"} gap="10px" alignItems={"center"}>
              <Button
                sx={{
                  color: colors.darkRed,
                  border: `1px solid ${colors.darkRed}`,
                }}
                onClick={() => {
                  HandleCropToolModel(additionalSide1, "additionalSide1");
                }}
              >
                Crop Image
              </Button>
              <span
                class="btn btn-primary btn-file"
                style={{
                  position: "relative",
                  overflow: "hidden",
                  background: "#b71431",
                  color: "white",
                  height: "50px",
                  width: "fit-content",
                  padding: "15px",
                  borderRadius: "5px",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <CloudUploadIcon /> Upload
                <input
                  // accept=""
                  style={{
                    position: " absolute",
                    top: "0",
                    right: "0",
                    minWidth: "100%",
                    minHeight: "100%",
                    fontSize: " 100px",
                    align: "right",

                    opacity: "0",
                    outline: "none",
                    background: "white",
                    cursor: "inherit",
                    display: "block",
                  }}
                  type="file"
                  onChange={(info) => {
                    UploadImage(info, "additionalSide1");
                  }}
                ></input>
              </span>
            </Box>
          </Grid>
        </Grid>

        {/* Handle Buttons======================>  */}
        <Grid item xs="12" mt="70px">
          <Box display="flex" gap="20px" justifyContent={"center"}>
            <Button
              // variant="outlined"
              sx={{
                background: colors.darkRed,
                color: "white",
                border: "none",
                ":hover": { color: "black" },
              }}
              onClick={() => {
                handleStep("prev", 1);
              }}
            >
              prev
            </Button>

            <Button
              sx={{
                background: colors.darkRed,
                color: "white",
                border: "none",
                ":hover": { color: "black" },
              }}
              // variant="outlined"
              onClick={
                // handleStep("next", 1);
                HandleButton
              }
            >
              Next
            </Button>
          </Box>
        </Grid>
      </Grid>
      <CropToolDialog
        open={openCropTool}
        Data={CropImage}
        SubmitData={SubmitDataOfCrop}
      />
    </Box>
  );
}

export default StepADDpictures;
