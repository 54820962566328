import React, { Fragment, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Box, FormControl, Grid, TextField, Typography } from "@mui/material";

// *components
import AppBar from "app/components/AppBar/AppBar";
import { LoadingBtn } from "app/components/UI/Buttons";

import colors from "app/style/Colors";
import { emailRegex } from "app/utils/Index";

import { ErrorToaster, SuccessToaster } from "app/components/UI/Toaster";
import { useNavigate } from "react-router-dom";

import Layout from "app/layout/Layout";

import { UserServices } from "app/apis/Users/User.Services";

function CreateUser() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const password = useRef({});
  password.current = watch("password", "");

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // Create User
  const registerUser = async (data) => {
    setLoading(true);
    try {
      let result = await UserServices.createUser(data);
      if (result.responseCode === 200) {
        SuccessToaster("User Created Successfully");
        navigate('/usermanagement/viewall')
      } else {
        ErrorToaster("Oops an error occur");
      }
    } catch (e) {
      console.log("error", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => { }, []);

  return (
    <Layout>
      <Fragment>
        <AppBar subChild="Create User">Create User</AppBar>

        <Typography
          component="form"
          onSubmit={handleSubmit(registerUser)}
          sx={{
            mb: 2,
            p: 3,
            m: 3,
            borderRadius: "10px",
            bgcolor: colors.white,
            boxShadow:
              "rgb(159 162 191 / 18%) 0px 9px 16px, rgb(159 162 191 / 32%) 0px 2px 2px",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth variant="standard" sx={{ my: 1.3 }}>
                <TextField
                  variant="outlined"
                  label="first name"
                  error={errors?.first_name?.message}
                  helperText={errors?.first_name?.message}
                  {...register("first_name", {
                    required: "Name is required",
                  })}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth variant="standard" sx={{ my: 1.3 }}>
                <TextField
                  variant="outlined"
                  label="last name"
                  error={errors?.last_name?.message && true}
                  helperText={errors?.last_name?.message}
                  {...register("last_name", {
                    required: "last name is required",
                  })}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl variant="standard" sx={{ width: "100%", my: 1.3 }}>
                <TextField
                  variant="outlined"
                  type="email"
                  label="email"
                  error={errors?.email?.message}
                  helperText={errors?.email?.message}
                  sx={{ width: "100%" }}
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: emailRegex,
                      message: "Please enter a valid email address",
                    },
                  })}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl variant="standard" sx={{ width: "100%", my: 1.3 }}>
                <TextField
                  variant="outlined"
                  type="password"
                  label="Password"
                  error={errors?.password?.message}
                  helperText={errors?.password?.message}
                  sx={{ width: "100%" }}
                  {...register("password", {
                    required: "Password is required",
                    minLength: {
                      value: 6,
                      message: "Password must have at least 6 characters",
                    },
                  })}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl variant="standard" sx={{ width: "100%", my: 1.3 }}>
                <TextField
                  variant="outlined"
                  type="password"
                  label="Confirm Password"
                  error={errors?.confirm_password?.message}
                  helperText={errors?.confirm_password?.message}
                  sx={{ width: "100%" }}
                  {...register("confirm_password", {
                    required: "Confirm Password is required",
                    validate: (value) =>
                      value === password.current ||
                      "Confirm password does not match",
                  })}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Box sx={{ textAlign: "right" }}>
            <LoadingBtn loading={loading} type="submit" variant="contained">
              Create user
            </LoadingBtn>
          </Box>
        </Typography>
      </Fragment>
    </Layout>
  );
}

export default CreateUser;
