import React, { useEffect, useState } from "react";
import AppBar from "app/components/AppBar/AppBar";
import {
  Box,
  CardMedia,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { DeleteTwoTone, EditTwoTone } from "@mui/icons-material";
import colors from "app/style/Colors";
import Layout from "app/layout/Layout";
import Swal from "sweetalert2";
import useAuth from "app/hooks/useAuth";
import { async } from "q";
import { UserServices } from "app/apis/Users/User.Services";
import { ErrorToaster, SuccessToaster } from "app/components/UI/Toaster";
import { Loading } from "app/assets/images";

function ViewAllCustomer() {
  const [loading, setLoading] = useState(false);
  const [UsersData, setUsersData] = useState([]);

  const deleteCategory = (id) => {
    try {
      Swal.fire({
        title: `"Are you sure you want to delete"`,
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: `${colors.primary}`,
        cancelButtonColor: `${colors.darkRed}`,
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const result = await UserServices.deleteUser(id);
            if (result.responseCode === 200) {
              SuccessToaster("User Deleted Successfully");
            } else {
              ErrorToaster("Oops Error Occur");
            }
          } catch (e) {
            ErrorToaster(e);
          } finally {
            getAllCustomer();
          }
        } else {
          return;
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const getAllCustomer = async () => {
    setLoading(true);
    try {
      const result = await UserServices.getAllUser();
      if (result.responseCode === 200) {
        setUsersData(result.data);
      } else {
        ErrorToaster("Oops an error Occur!");
      }
    } catch (e) {
      ErrorToaster(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllCustomer();
  }, [URL]);
  return (
    <Layout>
      <>
        <AppBar subChild="Customers">Customers</AppBar>
        <Box sx={{ p: 3 }}>
          {loading ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Loading />
            </Box>
          ) : (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }}>ID</TableCell>
                    <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>
                      first name
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>
                      last name
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>
                      email address
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>
                      date created
                    </TableCell>

                    <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {UsersData.map((user, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell sx={{ py: 1.5 }}>{index + 1}</TableCell>

                      <TableCell sx={{ py: 1.5, textAlign: "center" }}>
                        <Typography>{user.first_name}</Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          py: 1.5,
                          textAlign: "center",
                        }}
                      >
                        <Typography>{user.last_name}</Typography>
                      </TableCell>

                      <TableCell sx={{ py: 1.5, textAlign: "center" }}>
                        {user.email_address}
                      </TableCell>
                      <TableCell sx={{ py: 1.5, textAlign: "center" }}>
                        {user.date_created}
                      </TableCell>
                      <TableCell sx={{ py: 0, textAlign: "center" }}>
                        <Tooltip title="Edit Category">
                          <IconButton
                            component={Link}
                            to={`/usermanagement/edit/${user.id}`}
                            state={{
                              User: user,
                            }}
                          >
                            <EditTwoTone
                              color="primary"
                              sx={{ fontSize: "18px" }}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete Category">
                          <IconButton
                            onClick={() => {
                              deleteCategory(user.user_id);
                            }}
                          >
                            <DeleteTwoTone
                              sx={{ color: colors.darkRed, fontSize: "18px" }}
                            />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </>
    </Layout>
  );
}

export default ViewAllCustomer;
