import React, { Fragment, useState } from "react";
import { Box, FormControl, Grid, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import colors from "app/style/Colors";
import useAuth from "app/hooks/useAuth";
// *import components
import { LoadingBtn, PrimaryButton } from "app/components/UI/Buttons";
import AppBar from "app/components/AppBar/AppBar";
import { Error } from "app/components/UI/Error";
import { ErrorToaster, SuccessToaster } from "app/components/UI/Toaster";
import { TextInput } from "app/components/UI/TextField";

import Layout from "app/layout/Layout.js";
import SizesServices from "app/apis/Sizes/SizesServices";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const useStyles = makeStyles({
  image: {
    borderRadius: 2,
    objectFit: "contain",
  },
});

function UpdateSizes() {
  const { user } = useAuth();
  let navigate = useNavigate()

  // *For Form Validation
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const previousSize = useLocation();
  let { id } = useParams();

  const [loading, setLoading] = useState(false);

  const updatesize = async (data) => {
    setLoading(true);
    try {
      let result = await SizesServices.updateSize(id, data);
      if (result.responseCode === 200) {
        SuccessToaster("Size has been updated");
        navigate('/Productmanagement/viewallsize')
      } else {
        ErrorToaster("Oops! an error occur");
      }
    } catch (e) {
      console.log("error", e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <Fragment>
        <AppBar subChild="Update Sizes">Update Sizes</AppBar>

        <Grid
          container
          spacing={2}
          sx={{
            mb: 2,
            p: 3,
            m: 3,
            width: "95%",
            display: "flex",
            borderRadius: "10px",
            bgcolor: colors.white,
            boxShadow:
              "rgb(159 162 191 / 18%) 0px 9px 16px, rgb(159 162 191 / 32%) 0px 2px 2px",
          }}
        >
          <Typography
            component="form"
            onSubmit={handleSubmit(updatesize)}
            sx={{
              width: "100%",
              p: 1,
              display: "flex",
              alignItems: "center",
              gap: "30px",
            }}
          >
            <Grid item xs={3}>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Enter Sizes"
                defaultValue={previousSize.state.name}
                error={errors?.name?.message}
                helperText={errors?.name?.message}
                {...register("name", {
                  required: "Color Name is required",
                })}
              />
            </Grid>
            <Grid item xs={3} sx={{ textAlign: "left", my: "auto" }}>
              <LoadingBtn loading={loading} type="submit" variant="contained">
                Update
              </LoadingBtn>
            </Grid>
          </Typography>
        </Grid>
      </Fragment>
    </Layout>
  );
}

export default UpdateSizes;
