import { Navigate, useNavigate } from "react-router-dom";

export const ErrorHandler = (error) => {
  console.log(error);
  if (error.response) {
    if (error.response.status === 401) {
      const token = localStorage.getItem("jwt");
      if (token) {
        localStorage.clear();
        window.location.reload();
        // navigate("/login");
      }
    }
    return error.response.data.message;
  } else if (error.request) {
    return error.message;
  } else {
    return "Something went wrong";
  }
};
