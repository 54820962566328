const { CategoryTwoTone, HomeTwoTone } = require("@mui/icons-material");

const style = { fontSize: 22, verticalAlign: "bottom", mr: 1 };

// List top without dropdown
export const list = [
  { label: "Dashboard", path: "/", icon: <HomeTwoTone sx={style} /> },
];

// Order Management-->

export const OrderManagement = [
  {
    name: "Order Management",
    icon: <CategoryTwoTone sx={style} />,
    path: ["/viewordermanagement"],
    children: [
      {
        name: "View Order",
        path: "/viewordermanagement",
      },
      {
        name: "Quotations Order",
        path: "/quotedorders",
      },
    ],
  },
];

// user Management-->
export const UserManagement = [
  {
    name: "Manage users",
    icon: <CategoryTwoTone sx={style} />,
    path: ["/usermanagement/add", "/usermanagement/viewall"],
    children: [
      { name: "Add Users", path: "/usermanagement/add" },
      { name: "View All Users", path: "/usermanagement/viewall" },
    ],
  },
];

// Tool Management-->
export const ToolManagement = [
  {
    name: "Tool-Categories",
    icon: <CategoryTwoTone sx={style} />,
    path: [
      "/Toolmanagement/addcat",
      "/Toolmanagement/viewall",
      "/Toolmanagement/addSub",
      "/Toolmanagement/viewallsub",
      "/Toolmanagement/managecustomisation",
      "/Toolmanagement/name",
    ],
    children: [
      { name: "Add tool category", path: "/Toolmanagement/name" },
      { name: "Customisation", path: "/Toolmanagement/managecustomisation" },
      { name: "View All", path: "/Toolmanagement/viewall" },
    ],
  },
];

// product Management-->

export const ProductManagement = [
  {
    name: "Categories",
    icon: <CategoryTwoTone sx={style} />,
    path: [
      "/Productmanagement/addcat",
      "/Productmanagement/viewallcat",
      "/Productmanagement/addSub",
      "/Productmanagement/viewallsub",
    ],
    children: [
      { name: "Add Category", path: "/Productmanagement/addcat" },
      { name: "View All Category", path: "/Productmanagement/viewallcat" },
    ],
  },

  {
    name: "Manage Sizes",
    icon: <CategoryTwoTone sx={style} />,
    path: ["/Productmanagement/addsize", "/Productmanagement/viewallsize"],
    children: [
      { name: "Add Size", path: "/Productmanagement/addsize" },
      { name: "View Size", path: "/Productmanagement/viewallsize" },
    ],
  },
  {
    name: "Manage Product",
    icon: <CategoryTwoTone sx={style} />,
    path: [
      "/Productmanagement/addproduct",
      "/Productmanagement/viewallproduct",
    ],

    children: [
      { name: "Add Product", path: "/Productmanagement/addproduct" }, // Add with Stepping-------->
      { name: "View All Product", path: "/Productmanagement/viewallproduct" },
    ],
  },
  {
    name: "Manage Color",
    path: ["/add-color", "/view-color"],
    icon: <CategoryTwoTone sx={style} />,

    children: [
      { name: "Add Color", path: "/add-color" },
      { name: "View Color", path: "/view-color" },
    ],
  },
];
