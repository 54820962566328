import {post,get,deleted} from "../index.js"
import { UploadImageRoute } from "./UploadImage.Routes.js"

const UploadImageServices  = 
{
    uploadImage:async(ImageFile)=>
    {
        let result =  await post (UploadImageRoute.uploadImage,ImageFile)
        if(result.status===200) return result.data
        else throw result
    }
}
export default UploadImageServices