import React, { Fragment, useEffect, useState } from 'react'
import { Grid, Box, Typography, Container } from '@mui/material'
import { AssignmentIndTwoTone, BallotTwoTone, RequestQuoteTwoTone, SupervisedUserCircleTwoTone } from '@mui/icons-material';

import colors from 'app/style/Colors';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { Bar } from 'react-chartjs-2';

import { faker } from '@faker-js/faker';
import Layout from '../../layout/Layout';


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: '',
    },
  },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const data = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      data: labels.map(() => faker.datatype.number({ min: 20, max: 1000 })),
      backgroundColor: '#2D323E',
    },
    {
      label: 'Dataset 2',
      data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      backgroundColor: '#b71431',
    },
  ],
};

function DashboardBox() {

  const [counts, setCounts] = useState();

  const getCounts = async () => {
    // const { data } = await Service.GetCounts()
    setCounts(data[0])
  }

  useEffect(() => {
    getCounts()
    window.scrollTo({ top: 0 })
  }, [])


  return (
    <Layout>
      <>
        <Grid container spacing={4} sx={{ p: 3, mb: 4 }}>

          <Grid item xs={6}>
            <Box sx={{
              bgcolor: colors.white,
              boxShadow: "rgb(159 162 191 / 18%) 0px 9px 16px, rgb(159 162 191 / 32%) 0px 2px 2px",
              py: 2,
              borderRadius: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}>
              <SupervisedUserCircleTwoTone sx={{ color: colors.white, bgcolor: colors.darkRed, p: 0.5, borderRadius: "30px", fontSize: 63 }} />
              <Box sx={{
                textAlign: "center"
              }} >
                <Typography variant='h5' sx={{ fontWeight: "bold" }} >Products</Typography>
                <Typography variant='h5' sx={{ fontWeight: "bold", textAlign: "center" }} >{counts?.buyer_count}</Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Box sx={{
              bgcolor: colors.white,
              boxShadow: "rgb(159 162 191 / 18%) 0px 9px 16px, rgb(159 162 191 / 32%) 0px 2px 2px",
              py: 2,
              borderRadius: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}>
              <AssignmentIndTwoTone sx={{ color: colors.white, bgcolor: colors.yellow, p: 0.5, borderRadius: "30px", fontSize: 63 }} />
              <Box sx={{
                textAlign: "center"
              }} >
                <Typography variant='h5' sx={{ fontWeight: "bold" }} >Customers</Typography>
                <Typography variant='h5' sx={{ fontWeight: "bold", textAlign: "center" }} >{counts?.supplier_count}</Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Box sx={{
              bgcolor: colors.white,
              boxShadow: "rgb(159 162 191 / 18%) 0px 9px 16px, rgb(159 162 191 / 32%) 0px 2px 2px",
              py: 2,
              borderRadius: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}>
              <BallotTwoTone sx={{ color: colors.white, bgcolor: colors.lightBlue, p: 0.5, borderRadius: "30px", fontSize: 63 }} />
              <Box sx={{
                textAlign: "center"
              }} >
                <Typography variant='h5' sx={{ fontWeight: "bold" }} >Orders</Typography>
                <Typography variant='h5' sx={{ fontWeight: "bold", textAlign: "center" }} >{counts?.rfq_count}</Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Box sx={{
              bgcolor: colors.white,
              boxShadow: "rgb(159 162 191 / 18%) 0px 9px 16px, rgb(159 162 191 / 32%) 0px 2px 2px",
              py: 2,
              borderRadius: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}>
              <RequestQuoteTwoTone sx={{ color: colors.white, bgcolor: colors.primary, p: 0.5, borderRadius: "30px", fontSize: 63 }} />
              <Box sx={{
                textAlign: "center"
              }} >
                <Typography variant='h5' sx={{ fontWeight: "bold" }} >Quotations</Typography>
                <Typography variant='h5' sx={{ fontWeight: "bold", textAlign: "center" }} >{counts?.quot_count}</Typography>
              </Box>
            </Box>
          </Grid>

        </Grid>
        <Container>
          <Bar options={options} data={data} />
        </Container>
      </>
    </Layout>
  )
}

export default DashboardBox




