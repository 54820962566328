import React, { Fragment, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Box, FormControl, Grid, TextField, Typography } from "@mui/material";

// *components
import AppBar from "app/components/AppBar/AppBar";
import { LoadingBtn } from "app/components/UI/Buttons";

import colors from "app/style/Colors";
import { emailRegex } from "app/utils/Index";

import { ErrorToaster, SuccessToaster } from "app/components/UI/Toaster";
import { useNavigate } from "react-router-dom";

import Layout from "app/layout/Layout";

import { UserServices } from "app/apis/Users/User.Services";
import AuthServices from "app/apis/Auth/AuthServices";

function ChangePassword() {

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset
  } = useForm();

  const password = useRef({});
  password.current = watch("newPassword", "");

  const [loading, setLoading] = useState(false);

  // *For Change Password
  const changePassword = async (formData) => {
    setLoading(true);
    try {
      let obj = {
        old_password: formData.oldPassword,
        new_password: formData.newPassword,
      }
      let result = await AuthServices.changePassword(obj);
      if (result.responseCode === 200) {
        SuccessToaster("Password change successfully");
        reset()
      }
    } catch (e) {
      ErrorToaster(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => { }, []);

  return (
    <Layout>
      <Fragment>
        <AppBar subChild="Change Password">Change Password</AppBar>

        <Typography
          component="form"
          onSubmit={handleSubmit(changePassword)}
          sx={{
            mb: 2,
            p: 3,
            m: 3,
            borderRadius: "10px",
            bgcolor: colors.white,
            boxShadow:
              "rgb(159 162 191 / 18%) 0px 9px 16px, rgb(159 162 191 / 32%) 0px 2px 2px",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl variant="standard" sx={{ width: "100%", my: 1.3 }}>
                <TextField
                  variant="outlined"
                  type="password"
                  label="Old Password"
                  error={errors?.oldPassword?.message}
                  helperText={errors?.oldPassword?.message}
                  sx={{ width: "100%" }}
                  {...register("oldPassword", {
                    required: "Old Password is required",
                  })}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl variant="standard" sx={{ width: "100%", my: 1.3 }}>
                <TextField
                  variant="outlined"
                  type="password"
                  label="New Password"
                  error={errors?.newPassword?.message}
                  helperText={errors?.newPassword?.message}
                  sx={{ width: "100%" }}
                  {...register("newPassword", {
                    required: "New Password is required",
                    minLength: {
                      value: 6,
                      message: "Password must have at least 6 characters",
                    },
                  })}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl variant="standard" sx={{ width: "100%", my: 1.3 }}>
                <TextField
                  variant="outlined"
                  type="password"
                  label="Confirm Password"
                  error={errors?.confirm_password?.message}
                  helperText={errors?.confirm_password?.message}
                  sx={{ width: "100%" }}
                  {...register("confirm_password", {
                    required: "Confirm Password is required",
                    validate: (value) =>
                      value === password.current ||
                      "Confirm password does not match",
                  })}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Box sx={{ textAlign: "right" }}>
            <LoadingBtn loading={loading} type="submit" variant="contained">
              Change Password
            </LoadingBtn>
          </Box>
        </Typography>
      </Fragment>
    </Layout>
  );
}

export default ChangePassword;
