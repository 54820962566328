import { post, } from 'app/apis';
import AuthRoutes from './Auth.Routes';

const AuthServices = {
    login: async (obj) => {
        let result = await post(AuthRoutes.login, obj);
        if (result.status === 200) return result.data;
        else throw result;
    },

    logout: async () => {
        let result = await post(AuthRoutes.logout);
        if (result.status === 200) return result.data;
        else throw result;
    },
    changePassword: async (obj) => {
        let result = await post(AuthRoutes.changePassword,obj);
        if (result.status === 200) return result.data;
        else throw result;
    },
}

export default AuthServices