import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";

import { DeleteTwoTone, EditTwoTone } from "@mui/icons-material";
import Swal from "sweetalert2";

import useAuth from "app/hooks/useAuth";

// *import components
import AppBar from "app/components/AppBar/AppBar";
import colors from "app/style/Colors";
import { Link, useNavigate } from "react-router-dom";
import { ErrorToaster, SuccessToaster } from "app/components/UI/Toaster.js";
import Layout from "app/layout/Layout";
import SizesServices from "app/apis/Sizes/SizesServices";
import { Loading } from "app/assets/images";

function ViewAllSizes() {
  const { user } = useAuth();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [Sizes, setSizes] = useState([]);

  const getAllSizes = async () => {
    setLoading(true);
    try {
      const { responseCode, data } = await SizesServices.getAllSizes();
      if (responseCode === 200) {
        setSizes(data);
      }
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteCategory = (id) => {
    try {
      Swal.fire({
        title: `"Are you sure you want to delete"`,
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: `${colors.primary}`,
        cancelButtonColor: `${colors.darkRed}`,
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        try {
          if (result.isConfirmed) {
            const { responseCode, message } = await SizesServices.deleteSizes(id);
            if (responseCode === 200) {
              SuccessToaster(message);
              getAllSizes();
            }
          }
        } catch (error) {
          ErrorToaster(error);
        } finally {
        }
      });
    } catch (error) {
      ErrorToaster(error);
    }
  };
  useEffect(() => {
    getAllSizes();
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <Layout>
      <Fragment>
        <AppBar subChild="view all sizes">All Sizes</AppBar>

        {loading ? (
          <Box sx={{ width: 1, textAlign: "center", pt: 5 }}>
            <Loading />
          </Box>
        ) : (
          <Box sx={{ mb: 2, p: 3 }}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }}>S.No</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Sizes</TableCell>
                    <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Sizes.map((item, index) => (
                    <TableRow
                      key={item.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell sx={{ py: 1.5 }}>{index + 1}</TableCell>
                      <TableCell sx={{ py: 1.5 }}>{item.name}</TableCell>
                      <TableCell sx={{ py: 0, textAlign: "center" }}>
                        <Tooltip title="Edit Category">
                          <IconButton
                            component={Link}
                            to={`/Productmanagement/updatesize/${item.id}`}
                            state={{
                              name: item.name,
                            }}
                          >
                            <EditTwoTone
                              color="primary"
                              sx={{ fontSize: "18px" }}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete Category">
                          <IconButton onClick={() => deleteCategory(item.id)}>
                            <DeleteTwoTone
                              sx={{ color: colors.darkRed, fontSize: "18px" }}
                            />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </Fragment>
    </Layout>
  );
}

export default ViewAllSizes;
