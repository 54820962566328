import { deleted, get, post } from "app/apis";
import { productRoutes } from "./Product.Routes";

const ProductCategoryServices = {
  createCategory: async (data) => {
    let result = await post(productRoutes.createCategories, data);
    if (result.status === 200) return result.data;
    else throw result;
  },
  getAllCategory: async () => {
    let result = await get(productRoutes.getCategories);
    if (result.status === 200) {
      return result.data;
    } else throw result;
  },
  updateProductCategory: async (id, data) => {
    let result = await post(productRoutes.updateCategory + id, data);
    if (result.status === 200) return result.data;
    else throw result;
  },
  deleteProductCategory: async (id) => {
    let result = await deleted(productRoutes.deleteCategory + `${id}`);
    if (result.status === 200) return result.data;
    else throw result;
  },
};
export default ProductCategoryServices;
