import React, { Fragment, useState } from "react";
import {
  Box,
  FormControl,
  Grid,
  TextField,
  Typography,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import colors from "app/style/Colors";
import useAuth from "app/hooks/useAuth";
// *import components
import { LoadingBtn, PrimaryButton } from "app/components/UI/Buttons";
import AppBar from "app/components/AppBar/AppBar";
import { Error } from "app/components/UI/Error";
import { TextInput } from "app/components/UI/TextField";
import Layout from "app/layout/Layout.js";
import ProductCategoryServices from "app/apis/ProductCategories/ProductCategoryServices";
import { ErrorToaster, SuccessToaster } from "app/components/UI/Toaster";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles({
  image: {
    borderRadius: 2,
    objectFit: "contain",
  },
});

function AddCategory() {
  const { user } = useAuth();

  const classes = useStyles();

  // *For Form Validation
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const navigate = useNavigate();

  const [selectedImage, setSelectedImage] = useState("");
  const [uploadedImage, setUploadedImage] = useState();
  const [selectedCustomize, setSelectedCustomize] = useState("");
  console.log(selectedCustomize);

  const [loading, setLoading] = useState(false);

  const imageChange = (e) => {
    setUploadedImage(e.target.files[0]);
    setSelectedImage(URL.createObjectURL(e.target.files[0]));
  };

  const handleChange = (event) => {
    setSelectedCustomize(event.target.value);
  };

  const addCategory = async (data) => {
    let Formdta = new FormData();
    Formdta.append("name", data.categoryName);
    Formdta.append("type", data.categoryCustomization);

    setLoading(true);
    try {
      let result = await ProductCategoryServices.createCategory(Formdta);
      if (result.responseCode === 200) {
        SuccessToaster("Category successfully created!");
        navigate("/Productmanagement/viewallcat");
      } else {
        ErrorToaster("Oops! an error occur");
      }
    } catch (e) {
      ErrorToaster(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <Fragment>
        <AppBar subChild="Categories">Add Category</AppBar>

        <Typography
          component="form"
          onSubmit={handleSubmit(addCategory)}
          sx={{
            // width: "100%",
            p: 1,
            // display: "flex",
            // flexDirection: "column",
            gap: "20px",
          }}
        >
          <Grid
            container
            spacing={2}
            // gap="10px"
            sx={{
              mb: 2,
              p: 3,
              m: 3,
              width: "95%",
              borderRadius: "10px",
              bgcolor: colors.white,
              // boxShadow: "rgb(159 162 191 / 18%) 0px 9px 16px, rgb(159 162 191 / 32%) 0px 2px 2px",
              // display: "flex",
              // justifyContent: "center",
            }}
          >
            <Grid item md={6}>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Category Name"
                // sx={{ width: "400px" }}
                error={errors?.categoryName?.message}
                helperText={errors?.categoryName?.message}
                {...register("categoryName", {
                  required: "Please enter category Name",
                })}
              />
            </Grid>
            {/* <Grid item md={6}>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Sub-category Name"
                // sx={{ width: "400px" }}
                error={errors?.subCategoryName?.message}
                helperText={errors?.subCategoryName?.message}
                {...register("subCategoryName")} //, {required: "Please enter sub-category Name",}
              />
            </Grid> */}
            {/* <Grid item md={6}>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Category Description"
                // sx={{ width: "400px" }}
                error={errors?.categoryDescription?.message}
                helperText={errors?.categoryDescription?.message}
                {...register("categoryDescription", {
                  required: "Please enter category Description",
                })}
              />
            </Grid> */}
            <Grid item md={6}>
              <FormControl
                fullWidth
                error={errors?.categoryCustomization?.message}
              >
                <InputLabel id="demo-simple-select-label">
                  Select Customization
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedCustomize}
                  label="Select Customization"
                  {...register("categoryCustomization", {
                    required: "Please choose customizable",
                  })}
                  onChange={handleChange}
                >
                  <MenuItem value={"Customizable"}>{"Customizable"}</MenuItem>
                  <MenuItem value={"NotCustomizable"}>
                    {"NotCustomizable"}
                  </MenuItem>
                </Select>
                <FormHelperText>
                  {errors?.categoryCustomization?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
            {/* <Grid item md={6}>
              <Box>
                <Typography>Recommended Size: 500 x 500</Typography>
                <Box
                  sx={{
                    border: `0.5px solid ${colors.gray}`,
                    // p: 0.5,
                    mb: 2,
                    display: "flex",
                    width: "fit-content",
                  }}
                >
                  {selectedImage ? (
                    <img
                      src={selectedImage}
                      alt=""
                      width="auto"
                      height="200px"
                      className={classes.image}
                    />
                  ) : (
                    <Box sx={{ height: "auto" }}></Box>
                  )}
                </Box>
                <PrimaryButton variant="contained" component="label">
                  Upload Image
                  <input
                    accept="image/x-png,image/gif,image/jpeg"
                    type="file"
                    hidden={true}
                    error={errors?.selectedImage?.message}
                    {...register("selectedImage")}
                    onChange={imageChange}
                    sx={{ display: "none" }}
                  />
                </PrimaryButton>
                {errors?.selectedImage?.message && (
                  <Error message={errors?.selectedImage?.message} />
                )}
              </Box>
            </Grid> */}
            <Grid item xs={12} sx={{ textAlign: "right" }}>
              <LoadingBtn loading={loading} type="submit" variant="contained">
                Submit
              </LoadingBtn>
            </Grid>
          </Grid>
        </Typography>
      </Fragment>
    </Layout>
  );
}

export default AddCategory;
