import React, { useEffect, useState } from "react";
import AppBar from "app/components/AppBar/AppBar";
import AbcIcon from "@mui/icons-material/Abc";
import {
  Box,
  CardMedia,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import SearchIcon from "@mui/icons-material/Search";
import { Link } from "react-router-dom";
import {
  DeleteTwoTone,
  EditTwoTone,
  ElectricScooterRounded,
  Visibility,
} from "@mui/icons-material";
import colors from "app/style/Colors";
import Layout from "app/layout/Layout";
import Dialog from "app/components/DialogBox/Dialog";
import Swal from "sweetalert2";
import useAuth from "app/hooks/useAuth";
import OrdersServices from "app/apis/Orders/Orders.Services";
import { ErrorToaster, SuccessToaster } from "app/components/UI/Toaster";
import { Loading } from "app/assets/images";
import OrderDetailDialog from "./../../components/DialogBox/OrderDetailDialog";
import { LoadingButton } from "@mui/lab";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import EditIcon from "@mui/icons-material/Edit";
import EditOrderStatus from "app/components/DialogBox/EditOrderStatus";

function Orders() {
  const [openDialog, setOpenDialog] = useState(false);
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [orderDetail, setOrderDetail] = useState([]);
  const [orderInfo, setOrderInfo] = useState([]);
  const [Search, setSearch] = useState("");

  const [editDialog, seteditDialog] = useState({ status: false, Data: "" });

  //* Orders API
  const getOrders = async () => {
    setLoading(true);
    try {
      const { data, responseCode } = await OrdersServices.getOrders();
      if (responseCode === 200) {
        setOrders(data);
      }
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoading(false);
    }
  };

  const getOrdersDetail = async (id, item) => {
    setBtnLoading(true);
    try {
      const { data, responseCode } = await OrdersServices.getOrderDetail(id);
      if (responseCode === 200) {
        setOrderDetail(data);
        setOrderInfo(item);
        setOpenDialog(true);
      }
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setBtnLoading(false);
    }
  };

  // Sweet Alert
  const deleteCategory = (id) => {
    try {
      Swal.fire({
        title: `"Are you sure you want to delete"`,
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: `${colors.primary}`,
        cancelButtonColor: `${colors.darkRed}`,
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const { status, responseCode, message } =
            await OrdersServices.deleteCategories(id, user);
          if (status === true && responseCode === 200) {
            SuccessToaster(message);
          }
        }
      });
    } catch (error) {
      ErrorToaster(error);
    }
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };
  // Search Data ================>
  const SearchData = async () => {
    if (Search) {
      setLoading(true);
      try {
        const result = await OrdersServices.getSearchOrder(Search);
        if (result.responseCode === 200) {
          setOrders(result.data);
        }
      } catch {
      } finally {
        setLoading(false);
      }
    } else {
      ErrorToaster("Kindly Enter something to search");
    }
  };

  const HandleEditDialog = (item) => {
    if (item) {
      seteditDialog({
        status: editDialog.status ? false : true,
        Data: item,
      });
    } else {
      seteditDialog({
        status: editDialog.status ? false : true,
        Data: "",
      });
    }
  };

  useEffect(() => {
    getOrders();
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <Layout>
      <>
        <AppBar subChild="View Orders">View Orders</AppBar>
        {loading ? (
          <Box sx={{ width: 1, textAlign: "center", pt: 5 }}>
            <Loading />
          </Box>
        ) : (
          <Box sx={{ p: 3 }}>
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              sx={{
                mb: "10px",
                gap: "10px",
                ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
                  padding: "10px",
                },
              }}
            >
              <OutlinedInput
                value={Search}
                endAdornment={
                  <InputAdornment
                    position="end"
                    onClick={SearchData}
                    sx={{ cursor: "pointer" }}
                  >
                    <PersonSearchIcon />
                  </InputAdornment>
                }
                placeholder="Search here"
                textAlign="center"
                sx={{
                  ".css-8q2m5j-MuiInputBase-root-MuiInput-root": {
                    fontFamily: "Hind, sans-serif",
                    fontSize: "20px",
                  },
                  mt: "10px",
                }}
                onChange={(event) => {
                  handleSearchChange(event);
                }}
                id="standard-multiline-flexible"
                variant="standard"
              />
            </Box>
            {orders.length !== 0 ? (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>S.No</TableCell>
                      {/* <TableCell sx={{ fontWeight: "bold" }}>Picture</TableCell> */}
                      <TableCell
                        sx={{ fontWeight: "bold", whiteSpace: "nowrap" }}
                      >
                        Customer Name
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Customer Email
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: "bold", whiteSpace: "nowrap" }}
                      >
                        Order Date
                      </TableCell>

                      <TableCell
                        sx={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Quantity
                      </TableCell>

                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          textAlign: "center",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Total price
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        City
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Country
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          textAlign: "center",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Shipping Address
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Billing Address
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Status
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orders.map((item, index) => (
                      <TableRow
                        key={item.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell sx={{ py: 1.5 }}>{index + 1}</TableCell>

                        <TableCell sx={{ py: 1.5, textAlign: "left" }}>
                          {item.customer_name}
                        </TableCell>
                        {/* <TableCell sx={{ py: 1.5, textAlign: "center" }}>12345asd</TableCell> */}
                        <TableCell sx={{ py: 1.5, textAlign: "left" }}>
                          {item.customer_email}
                        </TableCell>
                        <TableCell
                          sx={{
                            py: 1.5,
                            textAlign: "left",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {item.order_date}
                        </TableCell>
                        <TableCell sx={{ py: 1.5, textAlign: "center" }}>
                          {item.total_quantity}
                        </TableCell>
                        <TableCell sx={{ py: 1.5, textAlign: "center" }}>
                          {item.total_amount}
                        </TableCell>
                        <TableCell sx={{ py: 1.5, textAlign: "center" }}>
                          {item.billing_city}
                        </TableCell>
                        <TableCell sx={{ py: 1.5, textAlign: "center" }}>
                          {item.billing_country}
                        </TableCell>
                        <TableCell sx={{ py: 1.5, textAlign: "center" }}>
                          {item.shipping_address}
                        </TableCell>
                        <TableCell sx={{ py: 1.5, textAlign: "center" }}>
                          {item.billing_address}
                        </TableCell>
                        <TableCell sx={{ py: 1.5 }}>
                          <Typography
                            sx={{
                              padding: "10px",
                              background:
                                item.status === "Pending"
                                  ? "red"
                                  : item.status === "Shipped"
                                  ? "orange"
                                  : "limegreen",
                              // background: index === 0 ? "red" : index === 1 ? "orange" : "limegreen",
                              borderRadius: "20px",
                              textAlign: "center",
                              fontWeight: "900",
                              color: "white",
                            }}
                          >
                            {item.status}
                          </Typography>
                        </TableCell>
                        <TableCell align="right" sx={{ py: 0 }}>
                          <Box sx={{ display: "flex", gap: "5px" }}>
                            <Tooltip title="Order Detail">
                              <IconButton
                                onClick={() => {
                                  getOrdersDetail(item.id, item);
                                }}
                              >
                                <Visibility />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Edit Status">
                              <IconButton
                                onClick={() => {
                                  HandleEditDialog(item);
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Box width="100%">
                <Typography
                  variant="h4"
                  fullWidth
                  textAlign={"center"}
                  sx={{
                    color: "gray",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    justifyContent: "center",
                  }}
                >
                  No user found{" "}
                  <SearchOffIcon sx={{ width: "50px", height: "50px" }} />{" "}
                </Typography>
              </Box>
            )}
          </Box>
        )}
        {/* Dialog Box-----------> */}
        <OrderDetailDialog
          open={openDialog}
          setOpen={() => {
            setOpenDialog(!openDialog);
            setOrderInfo([]);
          }}
          data={orderDetail}
          info={orderInfo}
          loading={btnLoading}
        />

        <EditOrderStatus
          open={editDialog}
          close={HandleEditDialog}
          getOrders={getOrders}
        />
      </>
    </Layout>
  );
}

export default Orders;
