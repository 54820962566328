import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  CardMedia,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";

import { DeleteTwoTone, EditTwoTone } from "@mui/icons-material";
import Swal from "sweetalert2";

import useAuth from "app/hooks/useAuth";

// *import components
import AppBar from "app/components/AppBar/AppBar";
import colors from "app/style/Colors";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../layout/Layout.js";
import { Loading } from "app/assets/images.js";
import ProductCategoryServices from "app/apis/ProductCategories/ProductCategoryServices.js";
import { ErrorToaster, SuccessToaster } from "app/components/UI/Toaster.js";

function ViewCategory() {
  const { user } = useAuth();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [categories, setCategories] = useState([]);

  const deleteCategory = (id) => {
    try {
      Swal.fire({
        title: `"Are you sure you want to delete"`,
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: `${colors.primary}`,
        cancelButtonColor: `${colors.darkRed}`,
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            let result = await ProductCategoryServices.deleteProductCategory(
              id
            );
            if (result.responseCode === 200) {
              SuccessToaster("Category is deleted successfully");
              getCategory();
            } else {
              ErrorToaster(result.message);
            }
          } catch (error) {
            console.log(error);
            ErrorToaster(error);
          }
        } else {
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const getCategory = async () => {
    setLoading(true);
    try {
      const result = await ProductCategoryServices.getAllCategory();
      if (result.responseCode === 200) {
        setCategories(result.data);
        console.log("result.data=>", result.data);
      } else {
        ErrorToaster("Oops an error occur!");
      }
    } catch (e) {
      console.log("error", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCategory();
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <Layout>
      <Fragment>
        <AppBar subChild="Categories">All Categories</AppBar>

        {loading ? (
          <Box sx={{ width: 1, textAlign: "center", pt: 5 }}>
            <Loading />
          </Box>
        ) : (
          <Box sx={{ mb: 2, p: 3 }}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }}>S.No</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Category Name
                    </TableCell>

                    <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {categories.map((item, index) => (
                    <TableRow
                      key={item.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell sx={{ py: 1.5 }}>{index + 1}</TableCell>
                      <TableCell sx={{ py: 1.5 }}>
                        {" "}
                        <Box display="flex" alignItems={"center"} gap="20px">
                          <Typography variant="p" fontWeight={"900"}>
                            {" "}
                            {item.name}
                          </Typography>
                        </Box>
                      </TableCell>

                      <TableCell sx={{ py: 0, textAlign: "center" }}>
                        <Tooltip title="Edit Category">
                          <IconButton
                            component={Link}
                            to={`/Productmanagement/updatecat/${item.id}`}
                            state={item}
                          >
                            <EditTwoTone
                              color="primary"
                              sx={{ fontSize: "18px" }}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete Category">
                          <IconButton onClick={() => deleteCategory(item.id)}>
                            <DeleteTwoTone
                              sx={{ color: colors.darkRed, fontSize: "18px" }}
                            />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </Fragment>
    </Layout>
  );
}

export default ViewCategory;
