import { get, post } from "app/apis";
import OrdersRoutes from "./Orders.Routes";

const OrdersServices = {
  getOrders: async () => {
    const data = await get(OrdersRoutes.getOrders + "?limit=10000&page=1");
    return data.data;
  },
  getOrderDetail: async (id) => {
    const result = await get(OrdersRoutes.getOrderDetail + id);
    if (result.status === 200) return result.data;
  },
  getSearchOrder: async (Data) => {
    const result = await get(
      OrdersRoutes.getFilteredOrder + `?searchTerm=${Data}&limit=10&page=1`
    );
    if (result.status === 200) return result.data;
    else throw result;
  },
  changeOrderStatus: async (id, data) => {
    const result = await post(OrdersRoutes.orderStatus + id, data);
    if (result.status === 200) {
      return result.data;
    } else {
      throw result;
    }
  },
};
export default OrdersServices;
