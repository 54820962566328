import React, { Fragment, useState } from "react";
import { Box, FormControl, Grid, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import colors from "app/style/Colors";

import useAuth from "app/hooks/useAuth";
// *import components
import { LoadingBtn, PrimaryButton } from "app/components/UI/Buttons";
import AppBar from "app/components/AppBar/AppBar";
import { Error } from "app/components/UI/Error";
import { ErrorToaster, SuccessToaster } from "app/components/UI/Toaster";
import { TextInput } from "app/components/UI/TextField";
import Layout from "app/layout/Layout.js";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { palette } from "@mui/system";
import ColorsServices from "app/apis/Colors/ColorsServices";

const useStyles = makeStyles({
  image: {
    borderRadius: 2,
    objectFit: "contain",
  },
});

function UpdateColor() {
  const { user } = useAuth();
  let all = useLocation();
  const [name, setName] = useState(all.state.name);
  const [code, setCode] = useState(all.state.code);

  let { id } = useParams()


  // *For Form Validation
  let navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [loading, setLoading] = useState(false);

  const updateColors = async (Data) => {
    setLoading(true);
    try {
      const { responseCode, message } = await ColorsServices.updateColor(id, Data);
      if (responseCode === 200) {
        SuccessToaster(message);
        navigate('/view-color')
      }
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <Fragment>
        <AppBar subChild="Update Colors">Update Color</AppBar>

        <Grid
          container
          spacing={2}
          sx={{
            mb: 2,
            p: 3,
            m: 3,
            width: "95%",
            display: "flex",
            borderRadius: "10px",
            alignItems: "center",
            bgcolor: colors.white,
            boxShadow:
              "rgb(159 162 191 / 18%) 0px 9px 16px, rgb(159 162 191 / 32%) 0px 2px 2px",
          }}
        >
          <Typography
            component="form"
            onSubmit={handleSubmit(updateColors)}
            sx={{
              width: "100%",
              p: 1,
              display: "flex",
              alignItems: "center",
              gap: "30px",

            }}
          >
            <Grid item xs={3}>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                defaultValue={name}
                label="Color Name"
                error={errors?.name?.message}
                helperText={errors?.name?.message}
                {...register("name", {
                  required: "Color Name is required",
                })}
              />
            </Grid>
            <Grid item xs={1}>
              <input
                style={{
                  height: "50px",
                  border: "none",
                  cursor: "pointer",
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                }}
                {...register("code", {
                  required: "Please select the Color",
                })}
                type="color"
                placeholder={code}
                variant="outlined"
                label="Color Name"
                // onChange={(event) => {
                //   console.log(
                //     "==============> color value",
                //     event.target.value
                //   );
                // }}
                error={errors?.categoryName?.message}
              />
            </Grid>

            <Grid item xs={12} sx={{ textAlign: "left", my: "auto" }}>
              <LoadingBtn loading={loading} type="submit" variant="contained">
                Update
              </LoadingBtn>
            </Grid>
          </Typography>
        </Grid>
      </Fragment>
    </Layout>
  );
}

export default UpdateColor;
