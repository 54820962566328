import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  CardMedia,
  IconButton,
  Pagination,
  PaginationItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from "@mui/icons-material/Edit";

import { DeleteTwoTone } from "@mui/icons-material";
import useAuth from "app/hooks/useAuth";

import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import MoreVertIcon from "@mui/icons-material/MoreVert";

// *import components
import AppBar from "app/components/AppBar/AppBar";
import colors from "app/style/Colors";
import Layout from "app/layout/Layout";
import { Loading } from "app/assets/images";
import { CreateProductServices } from "app/apis/CreateProduct/CreateProductServices";
import { ErrorToaster, SuccessToaster } from "app/components/UI/Toaster";
import { ToolManagementServices } from "app/apis/ToolManagement/ToolManagement.Services";

function ViewAllTools() {
  let ClearInfo = useRef();
  const [data, SetData] = useState([]);
  const [paging, setpaging] = useState(1);

  const navigate = useNavigate();
  const [page, setPage] = React.useState(1);
  const [count, setCount] = useState(0);

  // pagination Handle
  // const handleChange = (event, value) => {
  //   getProduct(value);
  //   setPage(value);
  // };

  const [Hide, setHide] = useState(false);
  const [loading, setLoading] = useState(false);

  // *For Get Product List

  const deleteProduct = async (Id) => {
    try {
      Swal.fire({
        title: `"Are you sure you want to delete"`,
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: `${colors.primary}`,
        cancelButtonColor: `${colors.darkRed}`,
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          <></>;
          try {
            const result =
              await ToolManagementServices.deleteCustomiseDesignById(Id);
            if (result.responseCode === 200) {
              SuccessToaster("Product Deleted Successfully");
            }
          } catch (e) {
            ErrorToaster(e);
          } finally {
            getAllCustomiseDesign();
          }
        } else {
          <></>;
        }
      });
    } catch (error) {}
  };

  const getAllCustomiseDesign = async (PgNo, option) => {
    setLoading(true);
    try {
      let result = await ToolManagementServices.getAllCustomiseProducts();
      if (result.responseCode === 200) {
        SetData(result.data);
      } else {
        ErrorToaster("Oops and error occur");
      }
    } catch (e) {
      ErrorToaster(e);
    } finally {
      setLoading(false);
      window.scrollTo({ top: "0px" });
    }
  };

  // Clear Image Ref

  useEffect(() => {
    getAllCustomiseDesign(page);
    window.scrollTo({ top: "0px" });
  }, [URL]);

  return (
    <Layout>
      <Fragment>
        <AppBar subChild="Manage Customisation">View Tool Products</AppBar>
        {loading ? (
          <Box sx={{ width: 1, textAlign: "center", pt: 5 }}>
            <Loading />
          </Box>
        ) : (
          <Box sx={{ mb: 2, p: 3 }}>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }}>S.No</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Sku number
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Main Category
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Sub Category
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Customise Category
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>
                      Image
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Title</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Size</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Available Colors
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Price</TableCell>

                    <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((item, index) => (
                    <TableRow
                      key={item.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell sx={{ py: 1.5 }}>{index + 1}</TableCell>
                      <TableCell sx={{ py: 1.5 }}>{item.sku}</TableCell>
                      <TableCell sx={{ py: 1.5 }}>
                        {item.category_name}
                      </TableCell>
                      <TableCell sx={{ py: 1.5 }}>
                        {item?.subcategory_name}
                      </TableCell>
                      <TableCell sx={{ py: 1.5 }}>
                        {item?.customise_cat_name}
                      </TableCell>
                      <TableCell sx={{ py: 1.5 }}>
                        <Box display="flex" justifyContent={"start"}>
                          <CardMedia
                            component="img"
                            height="70"
                            image={item?.image}
                            alt="Paella dish"
                            sx={{ objectFit: "contain" }}
                          />
                        </Box>
                      </TableCell>
                      <TableCell sx={{ py: 1.5 }}>{item.title}</TableCell>
                      <TableCell sx={{ py: 1.5 }}>
                        {item.mono_size === "-"
                          ? item.hevi_size
                          : item.hevi_size === "-"
                          ? item.mono_size
                          : ""}
                      </TableCell>
                      <TableCell sx={{ py: 1.5 }}>
                        <Box display="flex" gap="10px">
                          {/* {item.map((e) => ( */}
                          <Box
                            sx={{
                              height: "20px",
                              width: "20px",
                              background: item.color_code,
                              borderRadius: "50%",
                              boxShadow:
                                " rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px",
                            }}
                          ></Box>
                          {/* ))} */}
                        </Box>
                      </TableCell>
                      <TableCell sx={{ py: 1.5 }}>
                        <Box display="flex" gap="10px">
                          <Typography variant="p">{item.price}$</Typography>
                        </Box>
                      </TableCell>

                      <TableCell sx={{ py: 0, textAlign: "center" }}>
                        <Tooltip title="Edit Design">
                          <IconButton
                            component={Link}
                            to={`/Toolmanagement/editdesign/${item.customise_id}`}
                            state={{ Design: item }}
                          >
                            <EditIcon sx={{ fontSize: "20px" }} />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete Design">
                          <IconButton
                            onClick={() => deleteProduct(item.customise_id)}
                          >
                            <DeleteTwoTone
                              sx={{ color: colors.darkRed, fontSize: "18px" }}
                            />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </Fragment>
    </Layout>
  );
}

export default ViewAllTools;
