import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";

import { DeleteTwoTone, EditTwoTone } from "@mui/icons-material";
import Swal from "sweetalert2";
import useAuth from "app/hooks/useAuth";

// *import components
import AppBar from "app/components/AppBar/AppBar";
import colors from "app/style/Colors";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../layout/Layout.js";
import { ErrorToaster, SuccessToaster } from "app/components/UI/Toaster.js";
import { Loading } from "app/assets/images.js";
import ColorsServices from "app/apis/Colors/ColorsServices.js";

function ViewCategory() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [allColors, setAllColors] = useState([]);

  const getAllColors = async () => {
    setLoading(true)
    try {
      const { data, responseCode } = await ColorsServices.getAllColors();
      if (responseCode === 200) {
        setAllColors(data);
      }

    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoading(false)
    }
  };

  const deleteCategory = (id) => {

    try {
      Swal.fire({
        title: `"Are you sure you want to delete"`,
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: `${colors.primary}`,
        cancelButtonColor: `${colors.darkRed}`,
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLoading(true)
          try {
            const { responseCode, message } = await ColorsServices.deleteColor(id);
            if (responseCode === 200) {
              SuccessToaster(message);
              getAllColors();
            }
          } catch (error) {
            ErrorToaster(error);
          } finally {
            setLoading(false);
          }
        }
      });
    } catch (error) {
      ErrorToaster(error)
    }
  };

  useEffect(() => {
    getAllColors();
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <Layout>
      <Fragment>
        <AppBar subChild="view all colors">View All Colors</AppBar>

        {loading ? (
          <Box sx={{ width: 1, textAlign: "center", pt: 5 }}>
            <Loading />
          </Box>
        ) : (
          <Box sx={{ mb: 2, p: 3 }}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }}>S.No</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Color Name
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Color</TableCell>
                    <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allColors.map((item, index) => (
                    <TableRow
                      key={item.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell sx={{ py: 1.5 }}>{index + 1}</TableCell>
                      <TableCell sx={{ py: 1.5 }}>
                        <Typography sx={{ width: "100%" }}>
                          {item.name}
                        </Typography>
                      </TableCell>

                      <TableCell sx={{ py: 1.5 }}>
                        {" "}
                        <Box
                          sx={{
                            background: item?.code,
                            height: "30px",
                            width: "30px",
                            borderRadius: "50%",
                            boxShadow:
                              "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
                          }}
                        ></Box>
                      </TableCell>

                      <TableCell sx={{ py: 0, textAlign: "center" }}>
                        <Tooltip title="Edit Category">
                          <IconButton
                            component={Link}
                            to={`/Productmanagement/updateColor/${item.id}`}
                            state={{
                              name: item.name,
                              code: item.code,
                            }}
                          >
                            <EditTwoTone
                              color="primary"
                              sx={{ fontSize: "18px" }}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete Category">
                          <IconButton onClick={() => deleteCategory(item.id)}>
                            <DeleteTwoTone
                              sx={{ color: colors.darkRed, fontSize: "18px" }}
                            />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </Fragment>
    </Layout>
  );
}

export default ViewCategory;
