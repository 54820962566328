// *Email Regex
export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const server = "https://api-designtool.baitalkhairkitchen.com";

// *Remove Space
export const RemoveSpace = (string) => {
  const name = string.replace(/ /g, "-").toLowerCase();
  return name;
};

// *Remove Space
export const SpaceRemove = (string) => {
  const name = string.replace(/ /g, "").toLowerCase();
  return name;
};

// *For Image Url
export const imageURL = (url) => {
  const image = "https://api-designtool.baitalkhairkitchen.com" + url;
  return image;
};
