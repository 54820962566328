export const ToolManagementRoutes = {
  getMainCategory: "admin/get-customise-category",
  getSubCategory: "admin/get-customise-sub-cat-by-catid/",
  createToolProduct: "admin/create-customise",
  getAllDesignSizes: "admin/get-customise-fittype",
  getAllTools: "admin/get-customise-detail-list",
  getAllCustomiseDesignById: "/admin/get-customise-detail/",
  deleteCustomiseDesignById: "/admin/delete-customise/",
  updateDesign: "admin/update-customise/",
  createName: "/admin/create-category",
  getNames: "/admin/get-categories",
  deleteName: "/admin/delete-category/",
};
