import {
  Box,
  Button,
  CardMedia,
  Chip,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { Fragment, useRef, useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import colors from "app/style/Colors";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import AddIcon from "@mui/icons-material/Add";
import Layout from "app/layout/Layout";
import AppBar from "app/components/AppBar/AppBar";
import Images from "app/assets/images";
import { useEffect } from "react";
import { ErrorToaster, SuccessToaster } from "app/components/UI/Toaster";
import { ToolManagementServices } from "app/apis/ToolManagement/ToolManagement.Services";
import UploadImageServices from "app/apis/UploadImage/UploadImage";
import ColorsServices from "app/apis/Colors/ColorsServices";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { Error } from "app/components/UI/Error";
import { UniqueId } from "app/components/UniqueId/UniqueId";

function EditDesign() {
  const { id } = useParams();
  const { state } = useLocation();
  console.log("🚀 state", state);
  const [MainCategory, setMainCategory] = useState(state.Design.category_name);
  const [SubCategory, setSubCategory] = useState(state.Design.subcategory_name);
  // Image upload Hevi
  const [HeviImage, setHeviImage] = useState("");
  const [MonogramImage, setMonogramImage] = useState("");
  const [Sizes, setSizes] = useState([]);
  const [selectedSizes, setselectedSizes] = useState("");
  const [MainCategoryData, setMainCategoryData] = useState([]);
  const [SubCategoryData, setSubCategoryData] = useState([]);
  const [Disability, setDisability] = useState(true);
  const [Colors, setColors] = useState([]);
  const [Loading, setLoading] = useState(false);
  // Hevi Image and color
  const [ImageAndColorHevi, setImageAndColorHevi] = useState([]);
  console.log("🚀 ImageAndColorHevi", ImageAndColorHevi);
  // MonogGram Image and color
  const [ImageAndColorMonoGram, setImageAndColorMonoGram] = useState([]);
  console.log("🚀 ImageAndColorMonoGram", ImageAndColorMonoGram);
  //  color Change
  const [selectedColorValue, setSelectColor] = useState("");
  // Category Name
  const [nameCategories, setNameCategories] = useState([]);
  const [selectAphaCategory, setSelectAphaCategory] = useState("");
  console.log("🚀 selectAphaCategory", selectAphaCategory);
  const [SKU, setSKU] = useState("");
  const [price, setPrice] = useState("");
  const [title, setTitle] = useState("");
  const [size, setSize] = useState("");

  console.log("🚀 selectAphaCategory", selectAphaCategory);
  console.log("🚀 nameCategories", nameCategories);
  const navigate = useNavigate();

  const style = {
    m: 0,
    ".MuiInputLabel-root": { background: "#f2f2f2" },
  };

  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const ImageRef = useRef();
  // Main Category Change
  const categoryChange = (event) => {
    setSelectColor("");
    const IdOfCategory = MainCategoryData.find((e) => {
      if (e.name === event.target.value) {
        return e;
      }
    });
    setMainCategory(event.target.value);
    getSubCategoryData(IdOfCategory.id);
    setImageAndColorHevi([]);
    setImageAndColorMonoGram([]);
    setSubCategoryData([]);
    setSubCategory("");
  };

  // Sub Category Change
  const subcategorychange = (event) => {
    setSubCategory(event.target.value);
  };

  // Submit Data ------------->
  const Submitdata = async (data) => {
    let DataArray = [];
    const category_id = MainCategoryData.find((items) => {
      if (items.name === MainCategory) {
        return items.id;
      }
    });

    const subcategory_id = SubCategoryData.find((items) => {
      if (items.name === SubCategory) {
        return items.id;
      }
    });
    if (ImageAndColorHevi.length !== 0 || ImageAndColorMonoGram.length !== 0) {
      if (MainCategory === "HEVI Design") {
        const colorAndImage = ImageAndColorHevi.map((Data) => {
          return {
            image: Data.image,
            color_id: Colors.find((color) => Data.color_name === color.name),
          };
        });

        const ColorAndImageFinalData = colorAndImage.map((e) => {
          return {
            image: e.image,
            color_id: e.color_id.id,
          };
        });

        DataArray = [
          {
            sku: data?.sku,
            title: data?.title,
            size: data?.size,
            price: data?.price,
            customisecolors: ColorAndImageFinalData,
          },
        ];
      } else {
        // const colorAndImage = ImageAndColorMonoGram.map((Data) => {
        //   return {
        //     image: Data.image,
        //     color_id: Colors.find((color) => {
        //       if (Data.color_name === color.name) {
        //         return color.id;
        //       }
        //     }),
        //   };
        // });
        // const ColorAndImageFinalData = colorAndImage.map((e) => {
        //   return {
        //     image: e.image,
        //     color_id: e.color_id.id,
        //   };
        // });
        ImageAndColorMonoGram.forEach((e) => {
          const colorId = Colors.find((color) => color.name === e?.color)?.id;
          let obj = {
            sku: !e.sku ? state?.Design?.sku : e.sku,
            title: !e.title ? state?.Design?.title : e.title,
            image: !e.image ? state?.Design?.image : e.image,
            color_id: !colorId ? state?.Design?.color_id : colorId,
            price: !e.price ? state?.Design?.price : e.price,
          };
          DataArray.push(obj);
        });
        console.log(DataArray);
      }

      const FinalData = {
        category_id: !category_id.id
          ? state?.Design?.category_id
          : category_id.id,
        subcategory_id: !subcategory_id.id
          ? state?.Design?.subcategory_id
          : subcategory_id.id,
        size: !selectedSizes ? state?.Design?.size : selectedSizes,
        customise_cat_id: !selectAphaCategory.id
          ? state?.Design?.customise_cat_id
          : selectAphaCategory.id,
        customisedetails: DataArray,
      };
      SuccessToaster("Working");
      // Call Api ----------->
      try {
        setLoading(true);

        const result = await ToolManagementServices.updateDesign(id, FinalData);
        if (result.responseCode === 200) {
          SuccessToaster("Product Created Successfully");
        } else {
          ErrorToaster("Oops something went wrong!");
        }
      } catch (e) {
        ErrorToaster(e);
      } finally {
        setLoading(false);
        navigate("/Toolmanagement/viewall");
      }
    } else {
      ErrorToaster("Kindly Enter Design First");
    }
  };

  // Get Main Category Data
  const getMainCategoryData = async () => {
    try {
      const result = await ToolManagementServices.getMainCategory();
      if (result.responseCode === 200) {
        const FilteredMainCategory = result.data.filter((e) => {
          if (e.name !== "Custom Design") {
            return e;
          }
        });
        setMainCategoryData(FilteredMainCategory);
      } else {
        ErrorToaster("Oops an error occur");
      }
    } catch (e) {
      ErrorToaster(e);
    } finally {
    }
  };

  // Get Sub Category Data
  const getSubCategoryData = async (id) => {
    try {
      const result = await ToolManagementServices.getSubCategory(id);
      if (result.responseCode === 200) {
        setSubCategoryData(result.data);
      } else {
        ErrorToaster("Oops an error occur");
      }
    } catch (e) {
      ErrorToaster(e);
    } finally {
      setDisability(false);
    }
  };

  // Handle Hevi Image
  const handleUploadImage = async (event, type) => {
    SuccessToaster("Image is loading please wait");
    try {
      const formData = new FormData();
      formData.append("image", event.target.files[0]);
      const result = await UploadImageServices.uploadImage(formData);
      if (result.responseCode === 200) {
        if (type === "hevi") {
          setHeviImage(result.data);
        }
        if (type === "monogram") {
          setMonogramImage(result.data);
        }
      }
    } catch (e) {
      ErrorToaster(e);
    } finally {
      ClearRef();
    }
  };

  // Get All colors
  const getAllColors = async () => {
    try {
      const result = await ColorsServices.getAllColors();
      if (result.responseCode === 200) {
        setColors(result.data);
      }
    } catch (e) {
      ErrorToaster(e);
    }
  };

  // Get All sizes Monograms
  const getAllSizes = async () => {
    try {
      const result = await ToolManagementServices.getAllDesignSizes();
      if (result.responseCode === 200) {
        setSizes(result.data);
      } else {
        ErrorToaster("Oops an error occur");
      }
    } catch (e) {
      ErrorToaster(e);
    } finally {
    }
  };

  // HandleSize Change
  const handleSizeChange = (event) => {
    setselectedSizes(event.target.value);
  };

  // Hevi Design Managing Customisation
  const handleColorValueChange = (event) => {
    setSelectColor(event.target.value);
  };

  // Add Data Hevi Design
  const AddData = (Type) => {
    if (Type === "HeviDesign") {
      if (HeviImage && selectedColorValue) {
        const ID = UniqueId();
        let Array = [...ImageAndColorHevi];
        const obj = {
          id: ID,
          image: HeviImage,
          sku: SKU,
          price: price,
          title: title,
          color: selectedColorValue,
        };
        Array.push(obj);
        setImageAndColorHevi(Array);
        SuccessToaster("Item Added");
        setSelectColor("");
        setHeviImage("");
        setSKU("");
        setPrice("");
        setTitle("");
        setSize("");
      } else {
        ErrorToaster("please select image and color first");
      }
    } else {
      if (MonogramImage && selectedColorValue) {
        const ID = UniqueId();
        let Array = [...ImageAndColorMonoGram];
        const obj = {
          id: ID,
          sku: SKU,
          price: price,
          title: title,
          color: selectedColorValue,
          image: MonogramImage,
        };
        Array.push(obj);
        setImageAndColorMonoGram(Array);
        SuccessToaster("Item Added");
        setSelectColor("");
        setMonogramImage("");
        setSKU("");
        setPrice("");
        setTitle("");
      } else {
        ErrorToaster("please select image and color first");
      }
    }
  };
  // Delete Product from Queue Hevi Design
  const DeleteItemHevi = (Data) => {
    const NewData = ImageAndColorHevi.filter((e) => {
      if (Data.id !== e.id) {
        return e;
      }
    });
    setImageAndColorHevi(NewData);
  };

  const DeleteItemMonogram = (Data) => {
    const NewData = ImageAndColorMonoGram.filter((e) => {
      if (Data.id !== e.id) {
        return e;
      }
    });
    setImageAndColorMonoGram(NewData);
  };

  const defaultValue = () => {
    const image = state?.Design?.image.split(
      "http://api-designtool.baitalkhairkitchen.com"
    );
    const FilteredInfo = [
      {
        image: image[1],
        color: state?.Design?.color_name,
        sku: state?.Design?.sku,
        title: state?.Design?.title,
        price: state?.Design?.price,
        size: state?.Design?.hevi_size,
      },
    ]; //state.Design.colors.map((e) => {
    // const image = e.image.split(
    //   "https://phplaravel-1079200-3775334.cloudwaysapps.com"
    // );
    // const id = UniqueId();

    // return {
    //   id: id,
    //   image: image[1],
    //   color_name: e.color_name,
    // };
    //});
    console.log("FilteredInfo=>", FilteredInfo);
    if (state.Design.category_name === "HEVI Design") {
      setValue("sku", state?.Design?.sku);
      setValue("price", state?.Design?.price);
      setValue("title", state?.Design?.title);
      setValue("size", state?.Design?.hevi_size);
      setSelectColor(state?.Design?.color_name);
      setImageAndColorHevi(FilteredInfo);
    } else {
      setValue("skuMonogram", state?.Design?.sku);
      setValue("priceMonoGram", state?.Design?.price);
      setValue("MonoGramTitle", state?.Design?.title);
      setselectedSizes(state.Design.mono_size);
      setSelectAphaCategory(state.Design.customise_cat_name);
      setImageAndColorMonoGram(FilteredInfo);
      setSelectColor(state?.Design?.color_name);
      setSKU(state?.Design?.sku);
      setPrice(state?.Design?.price);
      setTitle(state?.Design?.title);
      setSize(state?.Design?.hevi_size);
    }
  };
  // Clear Image Inner Value
  const ClearRef = () => {
    ImageRef.current.value = null;
  };

  //Category Name
  const getAllName = async () => {
    try {
      const { data, responseCode } = await ToolManagementServices.getNames();
      if (responseCode === 200) {
        setNameCategories(data);
        console.log(data);
      }
    } catch (error) {
      console.log("error=>", error);
    }
  };

  useEffect(() => {
    getMainCategoryData();
    getSubCategoryData(state.Design.category_id);
    getAllColors();
    getAllSizes();
    getAllName();
    defaultValue();
  }, [URL]);

  return (
    <Layout>
      <Fragment>
        <AppBar subChild="Edit Design">Edit Design</AppBar>

        <Box sx={{ p: 4, pt: 5, boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px" }}>
          <Typography
            variant="h4"
            fullWidth
            fontWeight={"900"}
            display="flex"
            alignItems={"start"}
          >
            Customise product
          </Typography>
          <Grid
            mt="10px"
            sx={{}}
            container
            flexDirection={"column"}
            width="100%"
            alignItems="center"
            p="20px"
          >
            <Grid
              component="form"
              onSubmit={handleSubmit(Submitdata)}
              container
              marginTop="10px"
              width={"100%"}
              gap="15px"
              justifyContent={"center"}
            >
              {/* Main Category */}
              <Grid item xs="5">
                <FormControl fullWidth sx={style}>
                  <InputLabel id="demo-simple-select-helper-label" fullWidth>
                    Main category
                  </InputLabel>
                  <Select
                    // fullWidth
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={MainCategory}
                    label="Age"
                    onChange={categoryChange}
                  >
                    {MainCategoryData.map((category) => {
                      return (
                        <MenuItem value={category.name}>
                          {category.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {/* <FormHelperText>With label + helper text</FormHelperText> */}
                </FormControl>
              </Grid>

              {/* sub Category */}
              <Grid item xs="5">
                <FormControl fullWidth sx={style}>
                  <InputLabel id="demo-simple-select-helper-label">
                    Sub Category
                  </InputLabel>
                  <Select
                    // fullWidth
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={SubCategory}
                    label="Sub Category"
                    onChange={subcategorychange}
                  >
                    {SubCategoryData.map((SubCategory) => {
                      return (
                        <MenuItem value={SubCategory.name}>
                          {SubCategory.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>

              {/* Enter Sizes */}
              {MainCategory === "Monograms" && SubCategory && (
                <Grid item xs="5">
                  <FormControl fullWidth>
                    <InputLabel id="demo-select-small">Select Size</InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={selectedSizes}
                      label="Select Size"
                      onChange={handleSizeChange}
                    >
                      {Sizes.map((e) => (
                        <MenuItem value={e.name}>{e.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              {/* Category Name */}
              {MainCategory === "Monograms" && SubCategory && (
                <Grid item xs="5">
                  <FormControl fullWidth>
                    <InputLabel id="demo-select-small">
                      Select Category
                    </InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      defaultValue={state.Design.customise_cat_name}
                      value={selectAphaCategory}
                      label="Select Category"
                      onChange={(e) => setSelectAphaCategory(e.target.value)}
                    >
                      {nameCategories.map((e, i) => (
                        <MenuItem key={i} value={e.id}>
                          {e.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              {/* Optional Fields ------------------------------------------------------------------------> */}

              <Grid item xs="12">
                <Grid container justifyContent={"center"}>
                  <Grid item xs="12">
                    <Grid container>
                      {MainCategory === "Monograms" && (
                        <Grid item md={12}>
                          <Grid container spacing={2} mt={"20px"}>
                            <Grid item xs="12">
                              <Typography
                                variant="h5"
                                sx={{ fontWeight: "900" }}
                              >
                                Enter MonogramDesign:
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container justifyContent={"center"} mt={"30px"}>
                            {/* MonoGram Designnnnnn-----------------> */}
                            <Grid item xs="10">
                              <Grid container spacing={2}>
                                <Grid item md={12}>
                                  <Grid
                                    container
                                    columnSpacing={2}
                                    justifyContent={"center"}
                                  >
                                    {/* Sku Number */}
                                    <Grid item xs="4">
                                      <TextField
                                        fullWidth
                                        type="text"
                                        label="SKU number"
                                        placeholder="Enter SKU number"
                                        error={errors?.skuMonogram?.message}
                                        helperText={
                                          errors?.skuMonogram?.message
                                        }
                                        {...register("skuMonogram", {
                                          required:
                                            ImageAndColorMonoGram.length === 0
                                              ? "kindly enter Sku number"
                                              : false,
                                          onChange: (e) =>
                                            setSKU(e.target.value),
                                        })}
                                      />
                                    </Grid>

                                    {/* Enter Price */}
                                    <Grid item xs="4">
                                      <TextField
                                        fullWidth
                                        type="number"
                                        label="Price"
                                        placeholder="Enter price"
                                        error={errors?.priceMonoGram?.message}
                                        helperText={
                                          errors?.priceMonoGram?.message
                                        }
                                        {...register("priceMonoGram", {
                                          required:
                                            ImageAndColorMonoGram.length === 0
                                              ? "kindly enter price"
                                              : false,
                                          onChange: (e) =>
                                            setPrice(e.target.value),
                                        })}
                                      />
                                    </Grid>

                                    {/* Enter Title */}
                                    <Grid item xs="4">
                                      <TextField
                                        fullWidth
                                        type="text"
                                        label="Title"
                                        error={errors?.MonoGramTitle?.message}
                                        helperText={
                                          errors?.MonoGramTitle?.message
                                        }
                                        placeholder="Enter title"
                                        {...register("MonoGramTitle", {
                                          required:
                                            ImageAndColorMonoGram.length === 0
                                              ? "kindly enter title"
                                              : false,
                                          onChange: (e) =>
                                            setTitle(e.target.value),
                                        })}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>

                                <Grid item md={12}>
                                  <Grid container columnSpacing={2}>
                                    {/* Image Upload */}
                                    <Grid item xs="4">
                                      <Box
                                        display={"flex"}
                                        flexDirection="column"
                                        alignItems={"center"}
                                        justifyContent={"center"}
                                        gap="20px"
                                      >
                                        <CardMedia
                                          component="img"
                                          height="100px"
                                          sx={{
                                            objectFit: "contain",
                                          }}
                                          image={
                                            MonogramImage
                                              ? "http://api-designtool.baitalkhairkitchen.com" +
                                                MonogramImage
                                              : Images.UploadBGRemove
                                          }
                                          alt="Paella dish"
                                        />
                                        {/* Image Upload Button */}
                                        <Box>
                                          <span
                                            class="btn btn-primary btn-file"
                                            style={{
                                              position: "relative",
                                              overflow: "hidden",
                                              background: "#b71431",
                                              color: "white",
                                              height: "50px",
                                              width: "fit-content",
                                              padding: "15px",
                                              borderRadius: "5px",
                                              cursor: "pointer",
                                              display: "flex",
                                              alignItems: "center",
                                              gap: "10px",
                                            }}
                                          >
                                            <CloudUploadIcon />
                                            Upload Image
                                            <input
                                              name="image"
                                              ref={ImageRef}
                                              // error={errors?.imageMonogram?.message}
                                              // {...register("imageMonogram", {
                                              //   required: "Image is required",
                                              // })}
                                              style={{
                                                position: " absolute",
                                                top: "0",
                                                right: "0",
                                                minWidth: "100%",
                                                mineight: "100%",
                                                fontSize: " 100px",
                                                align: "right",
                                                filter: "alpha(opacity=0)",
                                                opacity: "0",
                                                outline: "none",
                                                background: "white",
                                                cursor: "inherit",
                                                display: "block",
                                              }}
                                              type="file"
                                              onChange={(e) => {
                                                handleUploadImage(
                                                  e,
                                                  "monogram"
                                                );
                                              }}
                                            ></input>
                                          </span>
                                          {/* {errors?.imageMonogram?.message && (
                                        <Error
                                          message={
                                            errors?.imageMonogram?.message
                                          }
                                        />
                                      )} */}
                                        </Box>
                                      </Box>
                                    </Grid>

                                    {/* Color Data */}
                                    <Grid item xs="4">
                                      <FormControl
                                        fullWidth
                                        sx={{
                                          ".MuiSelect-select ": {
                                            display: "flex",
                                            gap: "10px",
                                          },
                                        }}
                                      >
                                        <InputLabel id="demo-simple-select-helper-label">
                                          Colors
                                        </InputLabel>
                                        <Select
                                          sx={{ display: "flex" }}
                                          labelId="demo-simple-select-helper-label"
                                          id="demo-simple-select-helper"
                                          value={selectedColorValue}
                                          label="Colors"
                                          onChange={handleColorValueChange}
                                        >
                                          {Colors.map((colors) => (
                                            <MenuItem
                                              value={colors.name}
                                              sx={{
                                                display: "flex",
                                                gap: "10px",
                                                flexDirection: "c",
                                              }}
                                            >
                                              <Box
                                                sx={{
                                                  height: "20px",
                                                  width: "20px",
                                                  borderRadius: "50%",
                                                  background: colors.code,
                                                }}
                                              ></Box>
                                              {colors.name}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                        {/* <FormHelperText>
                                    With label + helper text
                                  </FormHelperText> */}
                                      </FormControl>
                                    </Grid>

                                    <Grid item xs="2">
                                      <Button
                                        sx={{ border: "1px solid green" }}
                                        onClick={() => {
                                          AddData("Monogram");
                                        }}
                                      >
                                        Add
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                {/* Table That shows Items in Queue  MonoGram*/}
                                {ImageAndColorMonoGram.length !== 0 && (
                                  <Grid item xs="12">
                                    <TableContainer component={Paper}>
                                      <Table
                                        size="small"
                                        aria-label="a dense table"
                                      >
                                        <TableHead>
                                          <TableRow>
                                            <TableCell align="center">
                                              Image
                                            </TableCell>
                                            <TableCell align="center">
                                              Title
                                            </TableCell>
                                            <TableCell align="right">
                                              SKU
                                            </TableCell>
                                            <TableCell align="right">
                                              Color
                                            </TableCell>
                                            <TableCell align="right">
                                              Action
                                            </TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {ImageAndColorMonoGram.map(
                                            (Data, index) => (
                                              <TableRow
                                                key={index}
                                                sx={{
                                                  "&:last-child td, &:last-child th":
                                                    { border: 0 },
                                                }}
                                              >
                                                <TableCell
                                                  component="th"
                                                  scope="row"
                                                >
                                                  <CardMedia
                                                    component="img"
                                                    height="50px"
                                                    sx={{
                                                      objectFit: "contain",
                                                    }}
                                                    image={
                                                      "http://api-designtool.baitalkhairkitchen.com" +
                                                      Data.image
                                                    }
                                                    alt="Paella dish"
                                                  />
                                                </TableCell>
                                                <TableCell align="center">
                                                  {Data.title}
                                                </TableCell>
                                                <TableCell align="right">
                                                  {Data.sku}
                                                </TableCell>
                                                <TableCell align="right">
                                                  {Data.color}
                                                </TableCell>
                                                <TableCell align="right">
                                                  <Box
                                                    sx={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                      DeleteItemMonogram(Data);
                                                    }}
                                                  >
                                                    <DeleteIcon
                                                      sx={{ color: "red" }}
                                                    />
                                                  </Box>
                                                </TableCell>
                                              </TableRow>
                                            )
                                          )}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  </Grid>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}

                      {/* Hevi Design --------------> */}
                      {MainCategory === "HEVI Design" && (
                        <Box>
                          <Grid item xs="12" mt="20px">
                            <Typography variant="h5" sx={{ fontWeight: "900" }}>
                              Enter Design:
                            </Typography>
                          </Grid>

                          <Grid item xs="12">
                            <Grid
                              container
                              justifyContent={"center"}
                              gap="20px"
                              mt="30px"
                              alignItems={"center"}
                            >
                              {/* Sku Number */}
                              <Grid item xs="5">
                                <TextField
                                  placeholder="Enter Sku number"
                                  name="Skunumber"
                                  label="Skunumber"
                                  variant="outlined"
                                  error={errors?.sku?.message}
                                  helperText={errors?.sku?.message}
                                  fullWidth
                                  {...register("sku", {
                                    required: "kindly enter sku number",
                                  })}
                                />
                              </Grid>

                              {/* Price  */}
                              <Grid item xs="5">
                                <TextField
                                  label="price"
                                  placeholder="price"
                                  name="price"
                                  type={"number"}
                                  variant="outlined"
                                  error={errors?.price?.message}
                                  helperText={errors?.price?.message}
                                  fullWidth
                                  {...register("price", {
                                    required: "kindly enter price",
                                  })}
                                />
                              </Grid>
                              {/* title */}
                              <Grid item xs="5">
                                <TextField
                                  label="title"
                                  placeholder="title"
                                  name="title"
                                  error={errors?.title?.message}
                                  helperText={errors?.title?.message}
                                  variant="outlined"
                                  fullWidth
                                  {...register("title", {
                                    required: "kindly enter title",
                                  })}
                                />
                              </Grid>
                              {/* Size  */}
                              <Grid item xs="5">
                                <TextField
                                  placeholder="size"
                                  name="size"
                                  label="size"
                                  variant="outlined"
                                  error={errors?.size?.message}
                                  helperText={errors?.size?.message}
                                  fullWidth
                                  {...register("size", {
                                    required: "kindly enter size ",
                                  })}
                                />
                              </Grid>

                              {/* Image Upload Section */}
                              <Grid
                                item
                                xs="2"
                                display="flex"
                                justifyContent={"center"}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    gap: "20px",
                                  }}
                                >
                                  <CardMedia
                                    component={"img"}
                                    sx={{
                                      height: "100px",
                                      objectFit: "contain",
                                      display: "flex",
                                      justifyContent: "start",
                                    }}
                                    image={
                                      HeviImage
                                        ? "http://api-designtool.baitalkhairkitchen.com" +
                                          HeviImage
                                        : Images.UploadBGRemove
                                    }
                                    alt="Paella dish"
                                  />
                                  {/* Upload Image Button Span */}
                                  <Box>
                                    <span
                                      class="btn btn-primary btn-file"
                                      name="image"
                                      style={{
                                        position: "relative",
                                        overflow: "hidden",
                                        background: "#b71431",
                                        color: "white",
                                        height: "50px",
                                        width: "fit-content",
                                        padding: "15px",
                                        borderRadius: "5px",
                                        cursor: "pointer",
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "10px",
                                      }}
                                    >
                                      <CloudUploadIcon /> Upload Image
                                      <input
                                        name="image"
                                        ref={ImageRef}
                                        style={{
                                          position: " absolute",
                                          top: "0",
                                          right: "0",
                                          minWidth: "100%",
                                          mineight: "100%",
                                          fontSize: " 100px",
                                          align: "right",
                                          filter: "alpha(opacity=0)",
                                          opacity: "0",
                                          outline: "none",
                                          background: "white",
                                          cursor: "inherit",
                                          display: "block",
                                        }}
                                        type="file"
                                        onChange={(e) => {
                                          handleUploadImage(e, "hevi");
                                        }}
                                      ></input>
                                    </span>
                                  </Box>
                                </Box>
                              </Grid>

                              {/* Color Data  */}
                              <Grid item xs="2">
                                <FormControl
                                  fullWidth
                                  sx={{
                                    ".MuiSelect-select ": {
                                      display: "flex",
                                      gap: "10px",
                                    },
                                  }}
                                >
                                  <InputLabel id="demo-simple-select-helper-label">
                                    Colors
                                  </InputLabel>
                                  <Select
                                    sx={{ display: "flex" }}
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={selectedColorValue}
                                    label="Colors"
                                    onChange={handleColorValueChange}
                                  >
                                    {Colors.map((colors) => (
                                      <MenuItem
                                        value={colors.name}
                                        sx={{
                                          display: "flex",
                                          gap: "10px",
                                          flexDirection: "c",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            height: "20px",
                                            width: "20px",
                                            borderRadius: "50%",
                                            background: colors.code,
                                          }}
                                        ></Box>
                                        {colors.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>

                              {/* Add Data */}
                              <Grid
                                item
                                xs="1"
                                onClick={() => {
                                  AddData("HeviDesign");
                                }}
                              >
                                <Button sx={{ border: "2px solid green" }}>
                                  Add
                                </Button>
                              </Grid>

                              {/* Table That shows Items in Queue */}
                              {ImageAndColorHevi.length !== 0 && (
                                <Grid item xs="12">
                                  <TableContainer component={Paper}>
                                    <Table
                                      size="small"
                                      aria-label="a dense table"
                                    >
                                      <TableHead>
                                        <TableRow>
                                          <TableCell align="center">
                                            Image
                                          </TableCell>
                                          <TableCell align="center">
                                            Title
                                          </TableCell>
                                          <TableCell align="right">
                                            SKU
                                          </TableCell>
                                          <TableCell align="right">
                                            Color
                                          </TableCell>
                                          <TableCell align="right">
                                            Action
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {ImageAndColorHevi.map(
                                          (Data, index) => (
                                            <TableRow
                                              key={index}
                                              sx={{
                                                "&:last-child td, &:last-child th":
                                                  { border: 0 },
                                              }}
                                            >
                                              <TableCell
                                                component="th"
                                                scope="row"
                                              >
                                                <CardMedia
                                                  component="img"
                                                  height="50px"
                                                  sx={{
                                                    objectFit: "contain",
                                                  }}
                                                  image={
                                                    "http://api-designtool.baitalkhairkitchen.com" +
                                                    Data.image
                                                  }
                                                  alt="Paella dish"
                                                />
                                              </TableCell>
                                              <TableCell align="center">
                                                {Data.title}
                                              </TableCell>
                                              <TableCell align="right">
                                                {Data.sku}
                                              </TableCell>
                                              <TableCell align="right">
                                                {Data.color}
                                              </TableCell>
                                              <TableCell align="right">
                                                <Box
                                                  sx={{ cursor: "pointer" }}
                                                  onClick={() => {
                                                    DeleteItemHevi(Data);
                                                  }}
                                                >
                                                  <DeleteIcon
                                                    sx={{ color: "red" }}
                                                  />
                                                </Box>
                                              </TableCell>
                                            </TableRow>
                                          )
                                        )}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* Submit Button ----------------------------------------------------------------------------> */}
              {Disability === false && (
                <Grid item xs="12" mt="50px">
                  <Grid container justifyContent={"center"}>
                    <LoadingButton
                      loading={Loading}
                      type="submit"
                      sx={{
                        width: "300px",
                        background: colors.darkRed,
                        color: "white",
                        ":hover": {
                          background: "white",
                          color: colors.darkRed,
                        },
                      }}
                    >
                      Update data
                    </LoadingButton>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Box>
      </Fragment>
    </Layout>
  );
}

export default EditDesign;
