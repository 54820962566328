import React, { useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { PrivateRoutes } from './routes/Routes';
import useAuth from './hooks/useAuth';
import SignIn from './views/Session/Login/Login';
import { Toaster } from './components/UI/Toaster';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2D323E',
      contrastText: '#fff',
    },
  },
  typography:
  {
    fontFamily: 'Manrope, sans-serif',
  }
});

function App() {

  const { verifyToken, user } = useAuth();

  const token = localStorage.getItem('jwt')

  useEffect(() => {
    verifyToken();
  })

  return (
    <BrowserRouter>
      <Toaster />
      <ThemeProvider theme={theme}>
        {/* ========== App Routes ========== */}
        <Routes>
          <Route element={!user ? <Outlet /> : <Navigate to="/" />}>
            <Route path="auth/sign-in" element={<SignIn />} />
          </Route>
          <Route element={user || token ? <Outlet /> : <Navigate to="auth/sign-in" />}>
            {PrivateRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={route.component}
              />
            ))}
          </Route>
        </Routes>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
