import React from "react";
import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// MUI MATERIALS-------------------------------------------->
import {
  List,
  Typography,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  ListItemButton,
  ListSubheader,

  Avatar,
} from "@mui/material";
import {
  ExpandLess,
  ExpandMore,
  ExitToAppOutlined,
  Circle,
  QrCodeScannerOutlined,
} from "@mui/icons-material";

import colors from "app/style/Colors";
import { styled } from "@mui/material/styles";
// Management of Navigation
import { list, managementList, CustomiseDesign, ProductManagement, OrderManagement, ToolManagement, UserManagement } from "app/Navigate";
import MuiDrawer from "@mui/material/Drawer";
import useAuth from "app/hooks/useAuth";
import { LoadingButton } from "@mui/lab";
import AuthServices from "app/apis/Auth/AuthServices";


// *import components

const drawerWidth = 290;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(8.6)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));



function SideBar() {
  const { signOut, InfoAdmin } = useAuth();

  const navigate = useNavigate();

  const { pathname } = useLocation();
  const [selectedItem, setSelectedItem] = useState(pathname);

  let pathSLice = pathname.split('/')




  let [abc, setAbc] = useState()
  const handleClick = (val) => {
    setAbc(3)

    if (selectedItem === val) {
      setSelectedItem("");
    } else {
      setSelectedItem(val);
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);



  const token = localStorage.getItem('jwt')

  const logout = async () => {

    try {
      const { status, responseCode, message } = await AuthServices.logout()
      if (responseCode === 200) {
        signOut()
      }

    } catch {

    } finally {

    }
  }



  return (
    <Drawer
      className="customScroll"
      sx={{
        "&>.MuiPaper-root": {
          bgcolor: colors.primary,
          px: 2,
        },
      }}
      variant="permanent"
      open={true}
    >
      {/* Logo Section ----------------------------------------------------------> here */}
      <List
        sx={{
          py: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          ".MuiListItem-root": { justifyContent: "center" }
        }}
      >
        <ListItem >
          <img src={Image.MainLogo} width="150px" alt="" />
        </ListItem>
      </List>

      <Divider sx={{ bgcolor: colors.gray, opacity: 0.1 }} />


      {/* Admin and admin gmail section here ---------------------------------------------------------> */}

      <List sx={{ py: 4 }}>
        <ListItem sx={{ flexDirection: "column" }}>
          <ListItemIcon>
            <Avatar
              alt=""
              src=""
              sx={{ m: "5px auto", width: "60px", height: "60px" }}
            />
          </ListItemIcon>
          <ListItemText
            sx={{
              "&>.MuiTypography-body1": {
                color: colors.white,
                fontSize: 20,
                fontWeight: "bold",
              },
            }}
            primary="Admin"
          />
          <ListItemText
            sx={{
              "&>.MuiTypography-body1": {
                mt: -1,
                color: colors.darkGray,
                fontSize: 14,
              },
            }}

            primary={`admin@gmail.com`}
          />
        </ListItem>
      </List>

      <Divider sx={{ bgcolor: colors.gray, opacity: 0.1 }} />


      {/* List bar without dropdown----------------------------------------------> here */}

      <List sx={{ pl: "8px", mr: "8px" }}>
        {list.map((item1, index) => (
          <ListItemButton
            key={item1.label}
            onClick={() => {
              setSelectedItem("");
              navigate(item1.path);
            }}
            selected={pathname === item1.path ? true : false}
            sx={{
              display: "flex",
              alignItems: "center",
              my: 1,
              py: 0.7,
              pl: 3,
              borderRadius: "10px",
              color: colors.lightGray,
              ".MuiSvgIcon-root": {
                color: colors.lightGray,
              },
              "&:hover": {
                bgcolor: colors.primaryDark,
                "&>.MuiTypography-root": {
                  color: colors.white,
                },
                ".MuiSvgIcon-root": {
                  color: colors.secondary,
                },
                cursor: "pointer",
              },
              "&.Mui-selected": {
                bgcolor: colors.darkRed,
              },
            }}
          >
            <ListItemIcon>{item1.icon}</ListItemIcon>
            <ListItemText
              primary={
                <Typography type="body2" sx={{ fontSize: 14 }}>
                  {item1.label}
                </Typography>
              }
            />
          </ListItemButton>
        ))}
      </List>

      {/* Product management------------------> here  */}
      <List
        subheader={
          <ListSubheader
            component="div"
            sx={{
              bgcolor: "transparent",
              fontSize: 18,
              fontWeight: "bold",
              color: colors.darkGray,
            }}
          >
            Product Management
          </ListSubheader>
        }
      // sx={{ pl: "8px", mr: "8px" }}
      >
        {ProductManagement.map((item, index) => (
          <Fragment>
            <ListItemButton
              key={item?.children?.name}
              button
              onClick={() => handleClick(item?.name)}
              sx={{
                my: 1,
                py: 0.7,
                pl: 3,
                color: colors.lightGray,
                borderRadius: "10px",
                bgcolor:
                  selectedItem === item?.name
                    ? colors.primaryDark
                    : colors.primary,
                ".MuiSvgIcon-root": {
                  color: colors.lightGray,
                },
                "&:hover": {
                  bgcolor: colors.primaryDark,
                  color: colors.white,
                  cursor: "pointer",
                  ".MuiSvgIcon-root": {
                    color: colors.secondary,
                  },
                },
              }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText
                primary={
                  <Typography type="body2" sx={{ fontSize: 14 }}>
                    {item.name}
                  </Typography>
                }
              />
              {selectedItem === item?.name || item.path.includes(selectedItem) ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            {item.children && (
              <Collapse
                in={selectedItem === item?.name || item.path.includes(selectedItem)}
                timeout="auto"
                unmountOnExit
              >
                {item?.children.map((subItems, index) => (
                  <List key={subItems?.name} component="div" disablePadding>
                    <ListItemButton
                      sx={{
                        py: 0.7,
                        pl: 5,
                        color: colors.white,
                        bgcolor:
                          subItems.path === pathname
                            ? colors.darkRed
                            : colors.primary,
                        borderRadius: "10px",
                        my: 0.5,
                        "&:hover": {
                          bgcolor: colors.primaryDark,
                          color: colors.white,
                          cursor: "pointer",
                        },
                      }}
                      onClick={() => { navigate(subItems.path) }}
                    >
                      <ListItemIcon>
                        <Circle
                          sx={{ fontSize: "10px", color: colors.white }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography
                            type="body2"
                            sx={{ fontSize: 14 }}
                          >{`${subItems.name}`}</Typography>
                        }
                      />
                    </ListItemButton>
                  </List>
                ))}
              </Collapse>
            )}
          </Fragment>
        ))}
      </List>

      {/* Product  order Management Side Bar --------------------------------------------------------------------------------------------->*/}
      <List
        subheader={
          <ListSubheader
            component="div"
            sx={{
              bgcolor: "transparent",
              fontSize: 18,
              fontWeight: "bold",
              color: colors.darkGray,
            }}
          >
            Order Management
          </ListSubheader>
        }
        sx={{ pl: "8px", mr: "8px" }}
      >
        {OrderManagement.map((item, index) => (
          <Fragment>
            <ListItem
              key={item?.children?.name}
              button
              onClick={() => handleClick(item?.name)}
              sx={{
                my: 1,
                py: 0.7,
                pl: 3,
                color: colors.lightGray,
                borderRadius: "10px",
                bgcolor:
                  selectedItem === item?.name
                    ? colors.primaryDark
                    : colors.primary,
                ".MuiSvgIcon-root": {
                  color: colors.lightGray,
                },
                "&:hover": {
                  bgcolor: colors.primaryDark,
                  color: colors.white,
                  cursor: "pointer",
                  ".MuiSvgIcon-root": {
                    color: colors.secondary,
                  },
                },
              }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText
                primary={
                  <Typography type="body2" sx={{ fontSize: 14 }}>
                    {item.name}
                  </Typography>
                }
              />
              {selectedItem === item?.name || item.path.includes(selectedItem) ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            {item.children && (
              <Collapse
                in={selectedItem === item?.name || item.path.includes(selectedItem)}
                timeout="auto"
                unmountOnExit
              >
                {item?.children.map((subItems, index) => (
                  <List key={subItems?.name} component="div" disablePadding>
                    <ListItemButton
                      sx={{
                        py: 0.7,
                        pl: 5,
                        color: colors.white,
                        bgcolor:
                          subItems.path === pathname
                            ? colors.darkRed
                            : colors.primary,
                        borderRadius: "10px",
                        my: 0.5,
                        "&:hover": {
                          bgcolor: colors.primaryDark,
                          color: colors.white,
                          cursor: "pointer",
                        },
                      }}
                      onClick={() => navigate(subItems.path)}
                    >
                      <ListItemIcon>
                        <Circle
                          sx={{ fontSize: "10px", color: colors.white }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography
                            type="body2"
                            sx={{ fontSize: 14 }}
                          >{`${subItems.name}`}</Typography>
                        }
                      />
                    </ListItemButton>
                  </List>
                ))}
              </Collapse>
            )}
          </Fragment>
        ))}
      </List>

      {/* Product  Tool Management --------------------------------------------------------------------------------------------->*/}
      <List
        subheader={
          <ListSubheader
            component="div"
            sx={{
              bgcolor: "transparent",
              fontSize: 18,
              fontWeight: "bold",
              color: colors.darkGray,
            }}
          >
            Tool Management
          </ListSubheader>
        }
        sx={{ pl: "8px", mr: "8px" }}
      >
        {ToolManagement.map((item, index) => (
          <Fragment>
            <ListItem
              key={item?.children?.name}
              button
              onClick={() => handleClick(item?.name)}
              sx={{
                my: 1,
                py: 0.7,
                pl: 3,
                color: colors.lightGray,
                borderRadius: "10px",
                bgcolor:
                  selectedItem === item?.name
                    ? colors.primaryDark
                    : colors.primary,
                ".MuiSvgIcon-root": {
                  color: colors.lightGray,
                },
                "&:hover": {
                  bgcolor: colors.primaryDark,
                  color: colors.white,
                  cursor: "pointer",
                  ".MuiSvgIcon-root": {
                    color: colors.secondary,
                  },
                },
              }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText
                primary={
                  <Typography type="body2" sx={{ fontSize: 14 }}>
                    {item.name}
                  </Typography>
                }
              />
              {selectedItem === item?.name || item.path.includes(selectedItem) ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            {item.children && (
              <Collapse
                in={selectedItem === item?.name || item.path.includes(selectedItem)}
                timeout="auto"
                unmountOnExit
              >
                {item?.children.map((subItems, index) => (
                  <List key={subItems?.name} component="div" disablePadding>
                    <ListItemButton
                      sx={{
                        py: 0.7,
                        pl: 5,
                        color: colors.white,
                        bgcolor:
                          subItems.path === pathname
                            ? colors.darkRed
                            : colors.primary,
                        borderRadius: "10px",
                        my: 0.5,
                        "&:hover": {
                          bgcolor: colors.primaryDark,
                          color: colors.white,
                          cursor: "pointer",
                        },
                      }}
                      onClick={() => navigate(subItems.path)}
                    >
                      <ListItemIcon>
                        <Circle
                          sx={{ fontSize: "10px", color: colors.white }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography
                            type="body2"
                            sx={{ fontSize: 14 }}
                          >{`${subItems.name}`}</Typography>
                        }
                      />
                    </ListItemButton>
                  </List>
                ))}
              </Collapse>
            )}
          </Fragment>
        ))}
      </List>



      {/* Product  User Management --------------------------------------------------------------------------------------------->*/}
      <List
        subheader={
          <ListSubheader
            component="div"
            sx={{
              bgcolor: "transparent",
              fontSize: 18,
              fontWeight: "bold",
              color: colors.darkGray,
            }}
          >
            User Management
          </ListSubheader>
        }
        sx={{ pl: "8px", mr: "8px" }}
      >
        {UserManagement.map((item, index) => (
          <Fragment>
            <ListItem
              key={item?.children?.name}
              button
              onClick={() => handleClick(item?.name)}
              sx={{
                my: 1,
                py: 0.7,
                pl: 3,
                color: colors.lightGray,
                borderRadius: "10px",
                bgcolor:
                  selectedItem === item?.name
                    ? colors.primaryDark
                    : colors.primary,
                ".MuiSvgIcon-root": {
                  color: colors.lightGray,
                },
                "&:hover": {
                  bgcolor: colors.primaryDark,
                  color: colors.white,
                  cursor: "pointer",
                  ".MuiSvgIcon-root": {
                    color: colors.secondary,
                  },
                },
              }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText
                primary={
                  <Typography type="body2" sx={{ fontSize: 14 }}>
                    {item.name}
                  </Typography>
                }
              />
              {selectedItem === item?.name || item.path.includes(selectedItem) ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            {item.children && (
              <Collapse
                in={selectedItem === item?.name || item.path.includes(selectedItem)}
                timeout="auto"
                unmountOnExit
              >
                {item?.children.map((subItems, index) => (
                  <List key={subItems?.name} component="div" disablePadding>
                    <ListItemButton
                      sx={{
                        py: 0.7,
                        pl: 5,
                        color: colors.white,
                        bgcolor:
                          subItems.path === pathname
                            ? colors.darkRed
                            : colors.primary,
                        borderRadius: "10px",
                        my: 0.5,
                        "&:hover": {
                          bgcolor: colors.primaryDark,
                          color: colors.white,
                          cursor: "pointer",
                        },
                      }}
                      onClick={() => navigate(subItems.path)}
                    >
                      <ListItemIcon>
                        <Circle
                          sx={{ fontSize: "10px", color: colors.white }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography
                            type="body2"
                            sx={{ fontSize: 14 }}
                          >{`${subItems.name}`}</Typography>
                        }
                      />
                    </ListItemButton>
                  </List>
                ))}
              </Collapse>
            )}
          </Fragment>
        ))}
      </List>


      <Divider sx={{ bgcolor: colors.gray, opacity: 0.1 }} />


      {/* Sign out Section ---------------------------------------------------------> */}
      <List sx={{ pl: "8px", mr: "8px" }}>
        <LoadingButton
          fullWidth
          onClick={() => navigate('/change-password')}
          sx={{
            my: 1,
            py: 0.7,
            pl: 3,
            color: colors.lightGray,
            borderRadius: "10px",
            ".MuiSvgIcon-root": {
              color: colors.lightGray,
            },
            "&:hover": {
              bgcolor: colors.primaryDark,
              color: colors.white,
              cursor: "pointer",
              ".MuiSvgIcon-root": {
                color: colors.secondary,
              },
            },
            textAlign: "start"
          }}
        >
          <ListItemIcon>
            <ExitToAppOutlined
              sx={{ fontSize: 22, verticalAlign: "bottom", mr: 1 }}
            />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography type="body2" sx={{ fontSize: 14, }}>
                Change Password
              </Typography>
            }
          />
        </LoadingButton>
      </List>
      <List sx={{ pl: "8px", mr: "8px" }}>
        <LoadingButton
          fullWidth
          onClick={logout}
          sx={{
            my: 1,
            py: 0.7,
            pl: 3,
            color: colors.lightGray,
            borderRadius: "10px",
            ".MuiSvgIcon-root": {
              color: colors.lightGray,
            },
            "&:hover": {
              bgcolor: colors.primaryDark,
              color: colors.white,
              cursor: "pointer",
              ".MuiSvgIcon-root": {
                color: colors.secondary,
              },
            },
            textAlign: "start"
          }}
        >
          <ListItemIcon>
            <ExitToAppOutlined
              sx={{ fontSize: 22, verticalAlign: "bottom", mr: 1 }}
            />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography type="body2" sx={{ fontSize: 14, }}>
                Sign out
              </Typography>
            }
          />
        </LoadingButton>
      </List>

    </Drawer>
  );
}

export default SideBar;
