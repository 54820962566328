import {
  Autocomplete,
  Box,
  Button,
  CardMedia,
  Checkbox,
  FormControl,
  Grid,
  Input,
  InputLabel,
  ListItem,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import colors from "app/style/Colors";
import Swal from "sweetalert2";
import { set, useForm } from "react-hook-form";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Images, { Loading } from "app/assets/images";
import DeleteIcon from "@mui/icons-material/Delete";
import { useRef } from "react";
import { useEffect } from "react";
import { ErrorToaster, SuccessToaster } from "app/components/UI/Toaster";
import UploadImageServices from "app/apis/UploadImage/UploadImage";
import { baseUrl } from "../../../../axios";
// import makeStyles from "@mui/material";
import { UniqueId } from "app/components/UniqueId/UniqueId";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
//       borderColor: "transparent",
//     },
//     "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
//       borderColor: "#e5e5e5",
//     },
//     "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
//       borderColor: "#e5e5e5",
//     },
//   },
//   selectMenu: {
//     overflowY: "auto",
//     maxHeight: 200,
//   },
// }));

function Step1CreateProduct(props) {
  const [defaultColorDataValue, setDefaultColorData] = useState([]);
  let [image, setImage] = useState([]);
  let [ImageBinary, setImageBinary] = useState([]);
  let [imageLength, setImageLength] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedColors, setSelectedColors] = useState("");
  const [selectSizeChange, setSelectSizeChange] = useState([]);
  console.log("🚀 selectSizeChange:", selectSizeChange);

  const [Quantity, setQuantity] = useState("");

  const [selectCategory, setselectCategory] = useState({});
  const [Type, setType] = useState("");
  const [bannerImage, setBannerImage] = useState("");

  const [BannerLoading, setBannerLoading] = useState(false);

  const aRef = useRef(null);
  const MainRef = useRef(null);
  // Product Details
  const [productDetails, setproductDetails] = useState([]);

  const baseURL = baseUrl;

  let ImageArray = [];
  let ArrayImageBinary = [];

  // Data From Props
  const {
    handleStep,
    setData,
    defaultdata,
    SizesAndColorData,
    ColorChange,
    SizeChange,
    MainCategory,
  } = props;
  console.log("🚀 SizeChange", SizeChange);
  console.log("🚀 MainCategory", MainCategory);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  // const classes = useStyles();
  // const MenuProps = {
  //   PaperProps: {
  //     style: {
  //       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
  //       width: 250,
  //     },
  //   },
  // };

  // Use form
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  // Setting Default Data ======>
  const [loader, setLoader] = useState(false);

  const setDefaultData = () => {
    setLoader(true);
    try {
      setValue("name", defaultdata?.name);
      setValue("sku", defaultdata?.sku);
      setValue("description", defaultdata?.description);
      setValue("price", defaultdata?.price);
      setType(defaultdata?.type);
      setBannerImage(defaultdata.bannerimage);
      if (defaultdata?.category) {
        setselectCategory(defaultdata?.category);
      }
      if (defaultdata?.productdetails) {
        setproductDetails(defaultdata?.productdetails);
      }
    } catch (error) {
    } finally {
      setLoader(false);
    }
  };

  // Submit Data
  const Submitdata = (data) => {
    if (productDetails.length !== 0) {
      const formdata = {
        name: data.name,
        description: data.description,
        price: data.price,
        sku: data.sku,
        type: Type,
        category: selectCategory,
        productdetails: productDetails,
        bannerimage: bannerImage,
      };
      SizesAndColorData({ allcolors: ColorChange, allsizes: SizeChange });
      setData(formdata);

      if (Type === "NotCustomizable") handleStep("next", 2);
      else handleStep("next", 1);
    } else {
      ErrorToaster("Kindly fill all the fields");
    }
  };

  //   Convert Image Function
  let uploadFile = async (event) => {
    if (event.target.files.length < 6) {
      setImageLength(event.target.files.length);
      setLoading(true);
      SuccessToaster("Your Image is loading please wait!");
      for (let i = 0; i < event.target.files.length; i++) {
        try {
          let formdata = new FormData();
          formdata.append("image", event.target.files[i]);
          let { responseCode, data } = await UploadImageServices.uploadImage(
            formdata
          );
          if (responseCode === 200) {
            ImageArray.push({ image: data });
          }
        } catch (e) {
          ErrorToaster(e);
        } finally {
        }

        ArrayImageBinary.push(event.target.files[i]);
      }
      setLoading(false);
      if (image.length > 8) {
        Swal.fire({
          title: `Sorry! you cant select more then 7 files`,
          icon: "warning",
          showCancelButton: true,

          cancelButtonColor: `${colors.darkRed}`,
        });
      } else {
        setImage([...image, ...ImageArray]);
        setImageBinary([...ImageBinary, ...ArrayImageBinary]);
      }
    } else {
      Swal.fire({
        title: `Sorry! you cant select more then 5 images`,
        icon: "warning",
        showCancelButton: true,

        cancelButtonColor: `${colors.darkRed}`,
      });
    }
    resetInput();
  };

  // Clear All Images
  const clearImages = () => {
    setImage([]);
    ImageArray = [];
    ImageBinary = [];
    setImageBinary([]);
    setImageLength(image.length);
  };

  // Add Product
  const AddProduct = () => {
    try {
      if (
        selectedColors &&
        selectSizeChange.length !== 0 &&
        image.length !== 0 &&
        Quantity
      ) {
        let Array = [...productDetails];
        // Get Color Id
        const id = UniqueId();
        Array.push({
          id: id,
          color: selectedColors,
          size: selectSizeChange,
          image: image,
          qty: Quantity,
        });
        console.log("Array=>", Array);
        setproductDetails(Array);
        // Make Values Null
        setSelectedColors("");
        setQuantity("");
        setSelectSizeChange([]);
        setImage([]);
        setImageLength(0);
      } else {
        ErrorToaster("Kindly Enter Details");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  // Delete Data
  const DeleteItem = (Data) => {
    let Items = [...productDetails];
    const getIndex = productDetails.findIndex((e) => e.id === Data.id);

    // Remove Item from queue
    Items.splice(getIndex, 1);
    setproductDetails(Items);
  };
  // Handle Colors Data
  const HandleColorChange = (e) => {
    setSelectedColors(e);
  };

  // Handle Category
  const HandleCategoryChange = (event) => {
    setselectCategory(event);
  };

  // Handle Type
  const HandleType = (event) => {
    setType(event.target.value);
  };

  // Reset input value
  const resetInput = () => {
    aRef.current.value = null;
    MainRef.current.value = null;
  };

  // Upload Banner Image
  const UploadBannerImage = async (event) => {
    setBannerLoading(true);
    try {
      const formdata = new FormData();
      formdata.append("image", event.target.files[0]);
      const result = await UploadImageServices.uploadImage(formdata);
      if (result.responseCode === 200) {
        setBannerImage(result.data);
      }
    } catch (e) {
      ErrorToaster(e);
    } finally {
      setBannerLoading(false);
      resetInput();
    }
  };

  // UseEffect
  useEffect(() => {
    setDefaultData();
    clearImages();
  }, [defaultdata, ColorChange]);

  return (
    <Box width={"100%"}>
      <Typography
        variant="h4"
        fullWidth
        fontWeight={"900"}
        display="flex"
        alignItems={"start"}
      >
        Create Product
      </Typography>
      <Grid
        container
        flexDirection={"column"}
        width="100%"
        alignItems="center"
        p="20px"
      >
        {loader ? (
          <Box>loading</Box>
        ) : (
          <Grid
            container
            component="form"
            onSubmit={handleSubmit(Submitdata)}
            marginTop="10px"
            width={"100%"}
            gap="15px"
            justifyContent={"center"}
          >
            {/* ====================Banner Image Section */}
            <Grid item xs="12">
              <Typography
                variant="h5"
                textAlign={"center"}
                mb="20px"
                sx={{ fontWeight: "800" }}
              >
                Enter Banner Image
              </Typography>
              <Grid
                container
                justifyContent={"center"}
                gap="10px"
                alignItems={"center"}
              >
                {/* Image Upload Button  Banner Image*/}
                <Grid item xs="2">
                  {BannerLoading ? (
                    <Box sx={{ width: 1, textAlign: "center", pt: 5 }}>
                      <Loading />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        borderRadius: "10px",
                      }}
                    >
                      <CardMedia
                        component="img"
                        sx={{
                          objectFit: "contain",
                          width: "200px",
                          height: "200px",
                        }}
                        image={
                          bannerImage
                            ? baseURL + bannerImage
                            : Images.UploadBGRemove
                        }
                        alt="Paella dish"
                      />
                    </Box>
                  )}
                </Grid>
                <Grid item xs="2">
                  <Box display={"flex"} flexDirection="column">
                    <span
                      class="btn btn-primary btn-file"
                      error={errors?.image?.message}
                      helperText={errors?.image?.message}
                      style={{
                        position: "relative",
                        overflow: "hidden",
                        background: "#b71431",
                        color: "white",
                        height: "50px",
                        width: "fit-content",
                        padding: "15px",
                        borderRadius: "5px",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <CloudUploadIcon /> Upload Image
                      <input
                        // accept=""
                        ref={MainRef}
                        // multiple
                        type="file"
                        style={{
                          position: " absolute",
                          top: "0",
                          right: "0",
                          minWidth: "100%",
                          mineight: "100%",
                          fontSize: " 100px",
                          align: "right",
                          filter: "alpha(opacity=0)",
                          opacity: "0",
                          outline: "none",
                          background: "white",
                          cursor: "inherit",
                          display: "block",
                        }}
                        onChange={UploadBannerImage}
                      />
                    </span>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            {/* Product name---> */}
            <Grid item xs="5">
              <TextField
                fullWidth
                label="Enter product name"
                name="Product name"
                error={errors?.name?.message}
                helperText={errors?.name?.message}
                {...register("name", {
                  required: "product name is required",
                })}
              ></TextField>
            </Grid>
            {/* Product label---> */}
            <Grid item xs="5">
              <TextField
                fullWidth
                label="Enter SKU"
                error={errors?.sku?.message}
                helperText={errors?.sku?.message}
                {...register("sku", {
                  required: "SKU is required",
                })}
              ></TextField>
            </Grid>
            {/* Product description */}
            <Grid item xs="5">
              <TextField
                minRows={3}
                multiline
                fullWidth
                label="Enter product description"
                error={errors?.description?.message}
                helperText={errors?.description?.message}
                {...register("description", {
                  required: "description is required",
                })}
              ></TextField>
            </Grid>

            {/* TO check product is customisable or not */}
            <Grid item xs="5">
              <FormControl fullWidth>
                <InputLabel id="demo-select-small">Select Type</InputLabel>
                <Select
                  onChange={HandleType}
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={Type}
                  label="Select Type"
                >
                  <MenuItem value={"Customizable"}>{"Customizable"}</MenuItem>
                  <MenuItem value={"NotCustomizable"}>
                    {"NotCustomizable"}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Select Main Category */}
            <Grid item xs="5">
              {/* Main Category Select  */}
              {MainCategory.length !== 0 && (
                <FormControl fullWidth>
                  <InputLabel id="demo-select-small">
                    Select Category
                  </InputLabel>
                  <Select
                    // onChange={HandleCategoryChange}
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={selectCategory?.name}
                    label="Select Category"
                  >
                    {Type === "Customizable" ? (
                      MainCategory?.map(
                        (category) =>
                          category.type === "Customizable" && (
                            <MenuItem
                              value={category.name}
                              onClick={() => HandleCategoryChange(category)}
                            >
                              {category.name}
                            </MenuItem>
                          )
                      )
                    ) : Type === "NotCustomizable" ? (
                      MainCategory?.map(
                        (category) =>
                          category.type === "NotCustomizable" && (
                            <MenuItem
                              value={category.name}
                              onClick={() => HandleCategoryChange(category)}
                            >
                              {category.name}
                            </MenuItem>
                          )
                      )
                    ) : (
                      <></>
                    )}
                  </Select>
                </FormControl>
              )}
            </Grid>

            {/* Product Price */}
            <Grid item xs="5">
              <TextField
                fullWidth
                error={errors?.price?.message}
                helperText={errors?.price?.message}
                label="Enter product price"
                {...register("price", {
                  required: "please enter price of product",
                })}
              />
            </Grid>

            {/* Images Colors and sizes and Quantity */}

            <Grid
              container
              display={"flex"}
              gap="20px"
              alignItems={"center"}
              m="30px"
              justifyContent="center"
            >
              {/* Images Upload and Clear Button Section  */}
              <Grid
                item
                xs="3"
                display={"flex"}
                flexDirection="column"
                justifyContent={"center"}
                alignItems="center"
              >
                {/* Image Upload Section Here */}
                <Box>
                  <Grid container gap="20px" justifyContent={"center"}>
                    {loading === false ? (
                      image.map((ImageURL) => {
                        return (
                          <Box
                            sx={{
                              borderRadius: "10px",
                            }}
                          >
                            <CardMedia
                              component="img"
                              sx={{
                                objectFit: "contain",
                                width: "100px",
                                height: "100px",
                              }}
                              image={baseURL + ImageURL.image}
                              alt="Paella dish"
                            />
                          </Box>
                        );
                      })
                    ) : (
                      <Box sx={{ width: 1, textAlign: "center", pt: 5 }}>
                        <Loading />
                      </Box>
                    )}
                  </Grid>
                </Box>
                {/* Image Button adn clear Image Button */}
                <Grid
                  container
                  justifyContent={"center"}
                  alignItems="center"
                  gap="20px"
                >
                  {/* Upload Image Section */}
                  <Box display={"flex"} flexDirection="column">
                    <span
                      class="btn btn-primary btn-file"
                      error={errors?.image?.message}
                      helperText={errors?.image?.message}
                      style={{
                        position: "relative",
                        overflow: "hidden",
                        background: "#b71431",
                        color: "white",
                        height: "50px",
                        width: "fit-content",
                        padding: "15px",
                        borderRadius: "5px",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <CloudUploadIcon /> Upload Image
                      <input
                        // accept=""
                        ref={aRef}
                        multiple
                        type="file"
                        style={{
                          position: " absolute",
                          top: "0",
                          right: "0",
                          minWidth: "100%",
                          mineight: "100%",
                          fontSize: " 100px",
                          align: "right",
                          filter: "alpha(opacity=0)",
                          opacity: "0",
                          outline: "none",
                          background: "white",
                          cursor: "inherit",
                          display: "block",
                        }}
                        onChange={uploadFile}
                      />
                    </span>
                  </Box>
                  {/* Clear Image Button */}
                  {imageLength !== 0 && (
                    <Button
                      onClick={clearImages}
                      sx={{
                        backgroundColor: "white",
                        color: colors.darkRed,
                        padding: "10px",
                        border: `1px solid ${colors.darkRed}`,
                      }}
                    >
                      Clear All
                    </Button>
                  )}
                </Grid>
              </Grid>

              {/* Colors  Select----> */}
              <Grid item xs="2">
                {ColorChange.length !== 0 && (
                  <FormControl fullWidth>
                    <InputLabel id="demo-select-small">Select Color</InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={selectedColors?.name ?? ""}
                      label="Select Color"
                    >
                      {ColorChange?.map((color) => (
                        <MenuItem
                          value={color.name}
                          onClick={() => {
                            HandleColorChange(color);
                          }}
                        >
                          {color.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Grid>

              {/* Select Sizes */}
              {/*               
              <Grid item xs="2">
                {SizeChange.length !== 0 && (
                  <FormControl fullWidth>
                    <InputLabel id="demo-select-small">Select Size</InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={selectSizeChange ?? ""}
                      label="Select Size"
                      multiple
                      onChange={() => {
                        handleSizeChange(SizeChange);
                      }}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                    >
                      {SizeChange?.map((size) => (
                        <MenuItem value={size.name}>
                          <Checkbox
                            checked={SizeChange.indexOf(size.name) > -1}
                          />
                          <ListItemText primary={size.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Grid>
              */}
              <Grid item xs="2">
                {SizeChange.length !== 0 && (
                  <FormControl fullWidth>
                    <InputLabel id="demo-multiple-checkbox-label">
                      Select Size
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="emo-multiple-checkbox"
                      value={selectSizeChange.map((size) => size.id) || []}
                      multiple
                      // onChange={handleSizeChange}
                      input={<OutlinedInput label="Select Size" />}
                      renderValue={(selected) => {
                        return selected
                          .map((sizeId) => {
                            const selectedSize = SizeChange.find(
                              (size) => size.id === sizeId
                            );
                            return selectedSize ? selectedSize.name : "";
                          })
                          .join(", ");
                      }}
                      MenuProps={{
                        // className: classes.selectMenu,
                        slotProps: {
                          paper: {
                            sx: {
                              maxHeight: 200,
                              overflow: "auto",
                              // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                            },
                          },
                        },
                      }}
                    >
                      {SizeChange.map((size) => (
                        <MenuItem
                          key={size.id}
                          value={size.id}
                          onClick={() =>
                            setSelectSizeChange((prevSizes) => {
                              const sizeIndex = prevSizes.findIndex(
                                (item) => item.id === size.id
                              );
                              if (sizeIndex === -1) {
                                return [
                                  ...prevSizes,
                                  { id: size.id, name: size.name },
                                ];
                              } else {
                                // Remove the size if already selected
                                const newSizes = [...prevSizes];
                                newSizes.splice(sizeIndex, 1);
                                return newSizes;
                              }
                            })
                          }
                        >
                          <Checkbox
                            checked={selectSizeChange.some(
                              (item) => item.id === size.id
                            )}
                          />
                          <ListItemText primary={size.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Grid>
              {/* Product Quantity */}
              <Grid item xs="2">
                <TextField
                  onChange={(event) => {
                    setQuantity(event.target.value);
                  }}
                  value={Quantity ?? ""}
                  fullWidth
                  label="Enter product quantity"
                  name="qty"
                  type={"number"}
                  // error={errors?.qty?.message}
                  // helperText={errors?.qty?.message}
                  // {...register("qty", {
                  //   required: "please mention available quantity",
                  // })}
                />
              </Grid>
              {/* Add Button */}
              <Grid item xs="1">
                <Button onClick={AddProduct} sx={{ border: "2px solid green" }}>
                  Add
                </Button>
              </Grid>
            </Grid>

            {/* Table that shows data in queue */}
            {productDetails.length !== 0 && (
              <Grid item xs="12">
                <TableContainer component={Paper}>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Image</TableCell>
                        <TableCell align="center">Color</TableCell>
                        <TableCell align="center">Size</TableCell>
                        <TableCell align="center">Qty</TableCell>
                        <TableCell align="center">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {productDetails?.map((Data, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            <CardMedia
                              component="img"
                              height="50px"
                              sx={{
                                objectFit: "contain",
                              }}
                              image={baseURL + Data.image[0].image}
                              alt="Paella dish"
                            />
                          </TableCell>
                          <TableCell align="center">
                            {Data.color.name}
                          </TableCell>
                          <TableCell align="center">
                            {Data.size.map((item) => item.name).join(",")}
                          </TableCell>
                          <TableCell align="center">{Data.qty}</TableCell>
                          <TableCell align="center">
                            <Box
                              sx={{ cursor: "pointer" }}
                              onClick={() => {
                                DeleteItem(Data);
                              }}
                            >
                              <DeleteIcon sx={{ color: "red" }} />
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            )}

            {/* Submit Buton */}
            <Grid item xs="12">
              <Grid container justifyContent={"center"}>
                <Button
                  type="submit"
                  sx={{
                    width: "70px",
                    marginTop: "50px",
                    background: colors.darkRed,
                    color: "white",
                    ":hover": { background: "white", color: colors.darkRed },
                  }}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
export default Step1CreateProduct;
