import { deleted, get, post } from "app/apis";
import ColorsRoutes from "./Colors.Routes";

const ColorsServices = {
  createColor: async (data) => {
    let result = await post(ColorsRoutes.createColor, data);
    if (result.status === 200) return result.data;
    else throw result;
  },
  getAllColors: async () => {
    let result = await get(ColorsRoutes.getColors);
    if (result.status === 200) {
      return result.data;
    } else throw result;
  },
  updateColor: async (id, data) => {
    let result = await post(ColorsRoutes.updateColor + `${id}`, data);

    if (result.status === 200) return result.data;
    else throw result;
  },
  deleteColor: async (id, jwt) => {
    let result = await deleted(ColorsRoutes.deleteColor + `${id}`, jwt);

    if (result.status === 200) return result.data;
    else throw result;
  },
};
export default ColorsServices;
