import React, { useState, useEffect } from "react";
import Layout from "app/layout/Layout";
import {
  Grid,
  Box,
  TextField,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Paper,
  Tooltip,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import colors from "app/style/Colors";
import AppBar from "app/components/AppBar/AppBar";
import { useForm } from "react-hook-form";
import { ToolManagementServices } from "app/apis/ToolManagement/ToolManagement.Services";
import { ErrorToaster, SuccessToaster } from "app/components/UI/Toaster";
import { DeleteTwoTone } from "@mui/icons-material";
import Swal from "sweetalert2";

function Name() {
  const tableHead = ["S.No", "Name", "Action"];
  const [Loading, setLoading] = useState(false);
  const [nameCategories, setNameCategories] = useState([]);

  // Use form
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
    reset,
    setValue,
  } = useForm();

  //For Submit Name
  const submitName = async (formData) => {
    setLoading(true);
    let obj = {
      name: formData.name,
    };
    try {
      const { message } = await ToolManagementServices.createName(obj);
      SuccessToaster(message);
      getAllName();
      reset();
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoading(false);
    }
  };

  //Get All Name Categories
  const getAllName = async () => {
    try {
      const { data, responseCode } = await ToolManagementServices.getNames();
      if (responseCode === 200) {
        setNameCategories(data);
      }
    } catch (error) {
      console.log("error=>", error);
    }
  };

  //For Delete Category
  const deleteCategoryName = async (id) => {
    try {
      Swal.fire({
        title: `"Are you sure you want to delete"`,
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: `${colors.primary}`,
        cancelButtonColor: `${colors.darkRed}`,
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let { responseCode, message } =
            await ToolManagementServices.deleteName(id);
          if (responseCode === 200) {
            SuccessToaster(message);
            getAllName();
          } else {
            ErrorToaster(message);
          }
        } else {
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllName();
  }, []);

  return (
    <Layout>
      <AppBar subChild="Create Design">Create Customise Category Name</AppBar>
      <Box sx={{ p: 4, pt: 5, boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px" }}>
        <Typography
          variant="h4"
          fullWidth
          fontWeight={"900"}
          display="flex"
          alignItems={"start"}
        >
          Customise Category Name
        </Typography>
      </Box>
      {/* Name Category Form */}
      <Box sx={{ p: 4 }}>
        <Grid
          container
          spacing={2}
          component={"form"}
          onSubmit={handleSubmit(submitName)}
        >
          <Grid item md={12}>
            <Grid container justifyContent={"center"}>
              <Grid item md={6}>
                <Box>
                  <TextField
                    fullWidth
                    type={"text"}
                    label={"Customize category name"}
                    error={
                      isDirty && !isSubmitting && Boolean(errors?.name?.message)
                    }
                    helperText={
                      isDirty && !isSubmitting && errors?.name?.message
                    }
                    {...register("name", {
                      required: "Customized product name is required",
                    })}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12}>
            <Grid container justifyContent={"center"}>
              <Grid item md={6}>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <LoadingButton
                    loading={Loading}
                    type="submit"
                    sx={{
                      width: "300px",
                      background: colors.primary,
                      color: "white",
                      ":hover": {
                        background: "white",
                        color: colors.primary,
                      },
                    }}
                  >
                    Submit
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {/* Table for Name Categories Listings */}
      <Box sx={{ mb: 2, p: 3 }}>
        <TableContainer component={Paper} sx={{ bgcolor: colors.white }}>
          <Table>
            <TableHead>
              <TableRow>
                {tableHead.map((cell, index) => (
                  <TableCell
                    key={index}
                    sx={{ textAlign: index > 1 ? "center" : "left" }}
                  >
                    {cell}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {nameCategories.map((item, index) => (
                // <TableRow key={index}>
                //   <TableCell sx={{ textAlign: "center" }}>
                //     {item.name}
                //   </TableCell>
                //   <TableCell sx={{ textAlign: "center" }}>
                //     <IconButton onClick={() => deleteCategoryName(item.id)}>
                //       <DeleteTwoTone sx={{ color: colors.darkRed }} />
                //     </IconButton>
                //   </TableCell>
                // </TableRow>
                <TableRow
                  key={item.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell sx={{ py: 1.5 }}>{index + 1}</TableCell>
                  <TableCell sx={{ py: 1.5 }}>
                    <Box>
                      <Typography variant="p" fontWeight={"900"}>
                        {item.name}
                      </Typography>
                    </Box>
                  </TableCell>

                  <TableCell sx={{ py: 0, textAlign: "center" }}>
                    <Tooltip title="Delete Category">
                      <IconButton onClick={() => deleteCategoryName(item.id)}>
                        <DeleteTwoTone
                          sx={{ color: colors.darkRed, fontSize: "18px" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Layout>
  );
}

export default Name;
