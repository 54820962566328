import React, { useEffect, useState } from "react";
import AppBar from "app/components/AppBar/AppBar";
import AbcIcon from "@mui/icons-material/Abc";
import {
  Box,
  CardMedia,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import SearchIcon from "@mui/icons-material/Search";
import { Link } from "react-router-dom";
import {
  DeleteTwoTone,
  EditTwoTone,
  ElectricScooterRounded,
  Visibility,
} from "@mui/icons-material";
import colors from "app/style/Colors";
import Layout from "app/layout/Layout";
import Dialog from "app/components/DialogBox/Dialog";
import Swal from "sweetalert2";
import useAuth from "app/hooks/useAuth";
import OrdersServices from "app/apis/Orders/Orders.Services";
import { ErrorToaster, SuccessToaster } from "app/components/UI/Toaster";
import { Loading } from "app/assets/images";
import OrderDetailDialog from "./../../components/DialogBox/OrderDetailDialog";
import { LoadingButton } from "@mui/lab";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import EditIcon from "@mui/icons-material/Edit";
import EditOrderStatus from "app/components/DialogBox/EditOrderStatus";
import { QuotationServices } from "app/apis/Quotations/Quotations.Services";

function Quotations() {
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);

  //* Orders API
  const getQuotationOrders = async () => {
    setLoading(true);
    try {
      const { data, responseCode } =
        await QuotationServices.getAllQuotedOrders();
      if (responseCode === 200) {
        setOrders(data);
      }
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getQuotationOrders();
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <Layout>
      <>
        <AppBar subChild="Quoted Orders">Quoted Orders</AppBar>
        {loading ? (
          <Box sx={{ width: 1, textAlign: "center", pt: 5 }}>
            <Loading />
          </Box>
        ) : (
          <Box sx={{ p: 3 }}>
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              sx={{
                mb: "10px",
                gap: "10px",
                ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
                  padding: "10px",
                },
              }}
            ></Box>
            {orders.length !== 0 ? (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>S.No</TableCell>
                      {/* <TableCell sx={{ fontWeight: "bold" }}>Picture</TableCell> */}
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Customer Name
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Customer Email
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>Date</TableCell>

                      <TableCell
                        sx={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Product Name
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Product Price
                      </TableCell>

                      <TableCell
                        sx={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Color
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Size
                      </TableCell>

                      <TableCell
                        sx={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orders.map((item, index) => (
                      <TableRow
                        key={item.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell sx={{ py: 1.5 }}>{index + 1}</TableCell>

                        <TableCell sx={{ py: 1.5, textAlign: "left" }}>
                          {item.customer_name}
                        </TableCell>
                        <TableCell sx={{ py: 1.5, textAlign: "left" }}>
                          {item.customer_email}
                        </TableCell>
                        <TableCell sx={{ py: 1.5, textAlign: "left" }}>
                          {item.quoteQuote_date}
                        </TableCell>
                        <TableCell sx={{ py: 1.5, textAlign: "center" }}>
                          {item.product_name}
                        </TableCell>
                        <TableCell sx={{ py: 1.5, textAlign: "center" }}>
                          {item.price}
                        </TableCell>
                        <TableCell sx={{ py: 1.5, textAlign: "center" }}>
                          {item.color}
                        </TableCell>
                        <TableCell sx={{ py: 1.5, textAlign: "center" }}>
                          {item.size}
                        </TableCell>

                        <TableCell align="right" sx={{ py: 0 }}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              gap: "5px",
                            }}
                          >
                            <Tooltip title="View Quoatation Design">
                              <IconButton
                                component={Link}
                                to={`/viewquotationorder/${item.product_id}`}
                                state={{ data: item }}
                              >
                                <Visibility />
                              </IconButton>
                            </Tooltip>
                            {/* <Tooltip title="Edit Status">
                              <IconButton
                                onClick={() => {
                                  // HandleEditDialog(item);
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip> */}
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Box width="100%">
                <Typography
                  variant="h4"
                  fullWidth
                  textAlign={"center"}
                  sx={{
                    color: "gray",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    justifyContent: "center",
                  }}
                >
                  No user found{" "}
                  <SearchOffIcon sx={{ width: "50px", height: "50px" }} />{" "}
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </>
    </Layout>
  );
}

export default Quotations;
