import { get, post, deleted } from "../index";
import { ToolManagementRoutes } from "./ToolManagement.Routes";
export const ToolManagementServices = {
  getMainCategory: async () => {
    const result = await get(ToolManagementRoutes.getMainCategory);
    if (result.status === 200) return result.data;
    else throw result;
  },
  getSubCategory: async (id) => {
    const result = await get(ToolManagementRoutes.getSubCategory + id);
    if (result.status === 200) return result.data;
    else throw result;
  },
  createToolProduct: async (data) => {
    const result = await post(ToolManagementRoutes.createToolProduct, data);
    if (result.status === 200) return result.data;
    else throw result;
  },
  getAllDesignSizes: async () => {
    const result = await get(ToolManagementRoutes.getAllDesignSizes);
    if (result.status === 200) return result.data;
    else throw result;
  },
  getAllCustomiseProducts: async () => {
    const result = await get(ToolManagementRoutes.getAllTools);
    if (result.status === 200) return result.data;
    else throw result;
  },
  deleteCustomiseDesignById: async (id) => {
    const result = await deleted(
      ToolManagementRoutes.deleteCustomiseDesignById + id
    );
    if (result.status === 200) return result.data;
    else throw result;
  },
  updateDesign: async (id, data) => {
    const result = await post(ToolManagementRoutes.updateDesign + id, data);
    if (result.status === 200) return result.data;
    else throw result;
  },
  createName: async (data) => {
    const result = await post(ToolManagementRoutes.createName, data);
    if (result.status === 200) return result.data;
    else throw result;
  },
  getNames: async (data) => {
    const result = await get(ToolManagementRoutes.getNames);
    if (result.status === 200) return result.data;
    else throw result;
  },
  deleteName: async (id) => {
    const result = await deleted(ToolManagementRoutes.deleteName + id);
    if (result.status === 200) return result.data;
    else throw result;
  },
};
