import { get, post, deleted } from "../index";
import { QuotationsOrdersRoutes } from "./QuotationsRoutes";

export const QuotationServices = {
  getAllQuotedOrders: async () => {
    const result = await get(QuotationsOrdersRoutes.getQuotedOrders);
    if (result.status === 200) return result.data;
    else throw result;
  },
};
