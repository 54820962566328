import { LoadingButton } from '@mui/lab'
import { Button } from '@mui/material'
import React from 'react'

export function PrimaryButton(props) {
  return (
    <Button
      {...props}
    >
      {props.children}
    </Button>
  )
}

export function LoadingBtn(props) {

  return (
    <LoadingButton
      sx={{
        mt: 1.5,
      }}
      {...props}
    >
      {props.children}
    </LoadingButton>
  )
}

