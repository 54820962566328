import React, { Fragment, useState } from "react";
import { Grid, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import colors from "app/style/Colors";
import { LoadingButton } from "@mui/lab";
// *import components
import { LoadingBtn } from "app/components/UI/Buttons";
import AppBar from "app/components/AppBar/AppBar";

import Layout from "app/layout/Layout.js";
import { findAllByTestId } from "@testing-library/react";
import { ErrorToaster, SuccessToaster } from "app/components/UI/Toaster";
import ColorsServices from "app/apis/Colors/ColorsServices";
import { useNavigate } from "react-router-dom";

function AddColors() {
  const navigate = useNavigate();

  // *For Form Validation
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [loading, setLoading] = useState(false);

  const createColor = async (Data) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("name", Data.name);
      formData.append("code", Data.code);
      const { responseCode, message } = await ColorsServices.createColor(
        formData
      );
      if (responseCode === 200) {
        SuccessToaster(message);
        navigate("/view-color");
      } else {
        ErrorToaster(message);
      }
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <Fragment>
        <AppBar subChild="Colors">Add Colors</AppBar>

        <Grid
          container
          spacing={2}
          sx={{
            mb: 2,
            p: 3,
            m: 3,
            width: "95%",
            display: "flex",
            borderRadius: "10px",
            bgcolor: colors.white,
            boxShadow:
              "rgb(159 162 191 / 18%) 0px 9px 16px, rgb(159 162 191 / 32%) 0px 2px 2px",
          }}
        >
          <Grid item xs={12}>
            <Typography
              component="form"
              onSubmit={handleSubmit(createColor)}
              sx={{
                width: "100%",
                p: 1,
                display: "flex",
                alignItems: "center",
                gap: "30px",
              }}
            >
              <Grid
                container
                gap="20px"
                sx={{ width: "100%" }}
                alignItems="center"
              >
                <Grid item xs="12">
                  <Typography variant="h6" mb="20px" sx={{ color: "black" }}>
                    <span style={{ color: "red" }}>Note: </span> Please enter
                    the color name and select its value by clicking colored box!
                  </Typography>
                </Grid>
                <Grid item xs="3">
                  <TextField
                    variant="outlined"
                    fullWidth
                    type="text"
                    label="Color Name"
                    error={errors?.name?.message}
                    helperText={errors?.name?.message}
                    {...register("name", {
                      required: "Color Name is required",
                    })}
                  />
                </Grid>
                <Grid item xs={3}>
                  <input
                    style={{
                      height: "50px",
                      border: "none",
                      cursor: "pointer",
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    }}
                    {...register("code", {
                      required: "Please select the Color",
                    })}
                    type="color"
                    variant="outlined"
                    label="Color Name"
                    error={errors?.categoryName?.message}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Grid xs="3" sx={{ textAlign: "left" }}>
                    <LoadingBtn
                      loading={loading}
                      type="submit"
                      variant="contained"
                      sx={{ ".MuiLoadingButton-root": { m: "0px" } }}
                    >
                      Submit
                    </LoadingBtn>
                  </Grid>
                </Grid>
              </Grid>
            </Typography>
          </Grid>
        </Grid>
      </Fragment>
    </Layout>
  );
}

export default AddColors;
